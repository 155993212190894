import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton, SvgIcon
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { withStyles } from "@material-ui/core/styles";

import { fileActions } from "../../redux/actions/file-actions";
import styles from "./styles";
import UserList from "../UserList";
import {ReactComponent as ShareSVG} from "../../assets/icons/icon_share_small.svg";

const ShareFileDialog = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, file, users, classes } = props;

  const [fileName, setFileName] = useState("");
  const [comment, setComment] = useState("");
  const [open, setOpen] = useState(isOpen);
  const [emails, setEmails] = useState([]);

  const handleShare = () => {
    const emailsToShare = emails.join(";");
    dispatch(fileActions.shareFile(file.id, emailsToShare, comment));
    onClose();
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const onUsersChange = (mails) => {
    setEmails(mails);
  };

  useEffect(() => {
    if (isOpen !== open) {
      setFileName("");
      setComment("");
      setOpen(isOpen);
      setEmails([]);
    }
    if (!!file && fileName !== file.name) {
      setFileName(file.name);
    }
  }, [isOpen, open, file, fileName]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title"disableTypography >
        <div className="dialog-title">
          Share <span className="highlight__name">{fileName}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="title__container">
          <label className="title__textfield">SHARE WITH</label>
        </div>
        <UserList users={users} onChange={onUsersChange} />
        <div className="title__container">
          <label className="title__textfield">COMMENTS</label>
        </div>
        <TextField
          label="Comment"
          multiline
          rowsMax={4}
          variant="outlined"
          value={comment}
          onChange={handleCommentChange}
          InputLabelProps={{shrink: false}}
        />
      </DialogContent>
      <DialogActions>
        <Button className="button__cancel" onClick={onClose}>Cancel</Button>
        <Button className="default__accept" onClick={handleShare} disabled={!emails.length}>
          SHARE
          <SvgIcon
            className="share"
            component={ShareSVG}
            viewBox={"0 0 24 24"}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ShareFileDialog.propTypes = {
  file: PropTypes.object,
  users: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

ShareFileDialog.defaultProps = {
  isOpen: false,
  users: [],
};

export default withStyles(styles)(ShareFileDialog);
