import React, { useState, useEffect, Fragment } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  CssBaseline,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Grid
} from "@material-ui/core";

import ShareIcon from '@material-ui/icons/Share';

import aquilaSideImage from '../../assets/aquila-sidebar.png';

import Calendar from "../../components/dialogs/Calendar";
import NewFolderDialog from "../../components/dialogs/NewFolder";
import NewFileDialog from "../../components/dialogs/NewFile";
import ShareFolderDialog from "../../components/dialogs/ShareFolder";
import Explorer from "./Explorer";
import UserProfileCard from "../../components/UserProfileCard";
import Navigation from "./Navigation";
import {
  AUploadIcon,
  AShareIcon,
  ANewFolderIcon,
  ACalendarIcon,
  ASideLeftIcon,
  ASideRightIcon
} from "../../components/Icons";

import UsersIcon from "@material-ui/icons/People";
import UserListDialog from "../../components/dialogs/UserList";
import NewUserForm from "../../components/dialogs/NewUserForm";
import EditUserForm from "../../components/dialogs/EditUserForm";
import ToastMessage from "../../components/toastMessage";
import { folderActions } from "../../redux/actions/folder-actions";
import UserForm from "../../components/dialogs/UserForm";
import {authActions} from "../../redux/actions";
import {useHistory} from "react-router-dom";

const drawerWidth = 270;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: "transparent",
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#1A1A1A",
    color: "#FFF",
    borderRight: "4px solid #31D055",
    "& .MuiSvgIcon-root": {
      fill: "#FFF",
    },
  },
  dragger: {
    width: '10px',
    padding: '4px 0 0',
    borderLeft: '1px solid #000',
    position: 'relative',
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: '100',
    backgroundColor: "transparent",
    height: '100vh'
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: "hidden",
    width: 96,
    [theme.breakpoints.up("sm")]: {
      width: 96
    }
  },
  drawerOpenCursor: {
    cursor: "w-resize"
  },
  drawerCloseCursor: {
    cursor: "e-resize"
  },
  drawerPaperMenu: {
    width: drawerWidth,
    backgroundColor: "#1A1A1A",
    borderLeft: "4px solid #31D055",
    color: "#FFF",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: 0,
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
    "& .MuiSvgIcon-root": {
      fill: "#FFF",
    },
  },
  aquilaHeadImg: {
    height: 85,
    background: `url(${aquilaSideImage}) no-repeat center`,
    marginBottom: 10,
    backgroundColor: '#31D055',
  },
  drawerMenuDivider: {
    position: "absolute",
    left: "5%",
    width: "90%",
    border: "solid 1px #FFF",
  },
  content: {
    flexGrow: 1,
    width: "100%",
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    })
  },
  contentLeftShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  contentRightShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  mainContainerDiv: {
    overflow: "hidden",
    height: "100vh",
    marginLeft: -9,
    width: '100%'
  },
  secondLevelFolder: {
    backgroundColor: '#EEE',
    overflow: 'scroll',
    height: '100%'
  },
  icon: {
    color: '#F0F0F0'
  },
  sideBarControls: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: '48%',
    background: 'blue',
    '& .icon-container': {
      position: 'absolute',
      height: 49,
      width: 39,
      cursor: 'pointer'
    },
    '& svg': {
      height: '2em',
      width: '2em',
    },
    '& image': {
      //width: 26,
      //height: 28
    }
  }
}));

export default function PersistentDrawerRight() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const currentFolder = useSelector((state) => state.folder.currentFolder);
  const currentUser = useSelector((state) => state.authentication.user);
  const [leftSideOpen, setLeftSideOpen] = React.useState(true);
  const [rightSideOpen, setRightSideOpen] = React.useState(true);

  const [isNewFolderOpen, setIsNewFolderOpen] = React.useState(false);
  const [isNewFileOpen, setIsNewFileOpen] = React.useState(false);
  const [isShareOpen, setIsShareOpen] = React.useState(false);
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const [isUserListOpen, setIsUserListOpen] = React.useState(false);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);
  const [editUser, setEditUser] = useState({isOpen: false, user: {}});
  const [inTrash, setInTrash] = useState(false);
  const [inShared, setInShared] = useState(false);
  const [isEditCurrentUserOpen, setIsEditCurrentUserOpen] = useState(false);
  const [disabledMenuItems, setDisabledMenuItems] = useState([]);
  const [inSecondLevelFolder, setInSecondLevelFolder] = useState(false);
  let history = useHistory();


  const handleOpenNewUser = () => {
    setIsUserListOpen(false);
    setIsUserFormOpen(true);
  };

  const handleOpenUserList = () => {
    setIsUserFormOpen(false);
    setIsUserListOpen(true);
  };

  const handleEditUserClose = () => {
    setEditUser({isOpen: false, user: {}})
    setIsUserListOpen(true);
  }

  const handleEditUser = (user) => {
    setEditUser({isOpen: true, user: user})
    setIsUserListOpen(false);
  }

  const handleRequestSharedItems = () => {
    dispatch(folderActions.requestSharedItems());
  }

  const handleOpen = (e, id) => {
    if (e) {
      e.preventDefault();
    }
    history.push(`/${id}`);
    const { href } = window.location;
    window.location.href = href;
  };

  useEffect(() => {
    // Trash validation
    if (!!currentFolder && !!currentFolder.path && !!currentFolder.path[0]) {
      setInTrash(currentFolder.path[0].folder_type === 'trash');
    }
    // Shared validation
    if (!!currentFolder && !!currentFolder.path && !!currentFolder.path[0]) {
      setInShared(currentFolder.path[0].folder_type === 'shared');
    }

    if (!!currentFolder && currentFolder.id === "search") {
      setInShared(false);
    }
    if (!!currentFolder) {
      setInSecondLevelFolder(currentFolder.parent_id || currentFolder.id === "search" ||
        currentFolder.id === "shared_items");
    }
  }, [ currentFolder ]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <Drawer
        variant="permanent"
        anchor="left"
        open={leftSideOpen}
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: leftSideOpen,
          [classes.drawerClose]: !leftSideOpen
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: leftSideOpen,
            [classes.drawerClose]: !leftSideOpen
          })
        }}
      >
        <Navigation
          isOpen={leftSideOpen}
          clickUpload={() => setIsNewFileOpen(true)}
          clickCreateFolder={() => setIsNewFolderOpen(true)}
          clickCalendar={() => setIsCalendarOpen(true)}
          clickEditProfile={() => setIsEditCurrentUserOpen(true)}
          clickManageUsers={() => setIsUserListOpen(true)}
          clickLogout={() => dispatch(authActions.logout())}
          clickShare={() => {//setInShared(true);
            handleOpen(null,'shared');
            }}
          disabledMenuItems={disabledMenuItems}
        />
      </Drawer>
      <div
        onClick={() => setLeftSideOpen(!leftSideOpen)}
        className={ clsx(classes.dragger, {
          [classes.drawerOpenCursor]: leftSideOpen,
          [classes.drawerCloseCursor]: !leftSideOpen
        })
        }
      />
      <div className={classes.mainContainerDiv}>
        <main
          className={clsx(classes.content, {
            [classes.contentRightShift]: leftSideOpen,
            [classes.secondLevelFolder]: inSecondLevelFolder || inTrash || inShared
          })}
        >
          <Explorer
            inTrash={inTrash}
            inSecondLevelFolder={inSecondLevelFolder}
            inShared={inShared}
            setLeftSideOpen={setLeftSideOpen}
            setDisabledMenuItems={setDisabledMenuItems}
            clickShare={() => setIsShareOpen(true)}
          />
        </main>
      </div>
      <NewFolderDialog
        isOpen={isNewFolderOpen}
        onClose={() => setIsNewFolderOpen(false)}
      />
      <NewFileDialog
        isOpen={isNewFileOpen}
        onClose={() => setIsNewFileOpen(false)}
      />
      <ShareFolderDialog
        isOpen={isShareOpen}
        onClose={() => setIsShareOpen(false)}
        currentFolder={ currentFolder }
      />
      <Calendar
        isOpen={isCalendarOpen}
        onClose={() => setIsCalendarOpen(false)}
      />

      <UserListDialog
        isOpen={isUserListOpen}
        onClose={() => setIsUserListOpen(false)}
        callbackOpenNewUser={handleOpenNewUser}
        callbackOpenEditUser={handleEditUser}
      />

      <EditUserForm 
        isOpen={editUser.isOpen}
        onClose={handleEditUserClose}
        userData={editUser.user}
      />

      <NewUserForm
        isOpen={isUserFormOpen}
        onClose={() => setIsUserFormOpen(false)}
        callbackOpenUserList={handleOpenUserList}
      />

      <UserForm
        isOpen={isEditCurrentUserOpen}
        onClose={() => setIsEditCurrentUserOpen(false)}
        userData={currentUser}
      />

      <ToastMessage />
    </div>

  );
}
