import API from "./api.service";

export class FolderService {
  static async getHome({userId}) {
    try {
      const response = await API(false, false).get(
        `users/${userId}/folders/home`,
        { data: {} }
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async getFolder({userId, id}) {
    try {
      if(!!userId && !!id && id !== "search") {
        const response = await API(false, false).get(
          `users/${userId}/folders/${id}`,
          { data: {} }
        );
        if (response.status < 300) {
          return response.data;
        } else {
          console.error("Folder service getFolder error:", response);
        }
      }
      return null;
    } catch (e) {
      console.error("Folder service getFolder error:", e);
      return null; // prevent infinitive loop
    }
  }
  static async addFolder({userId, id, folderName}) {
    try {
      const response = await API(false, false).post(
        `users/${userId}/folders/`,
        { folder: {parent_id: id, name: folderName } },
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async editFolder({userId, id, folderName}) {
    try {
      const response = await API(false, false).put(
        `users/${userId}/folders/${id}`,
        { folder: { name: folderName } },
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async deleteFolder({userId, id}) {
    try {
      const response = await API(false, false).delete(
        `users/${userId}/folders/${id}`, {data: {}}
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async shareFolder({ id, emails, comment, userId}) {
    try {
      const params = { emails, comment, can_edit: false };
      const response = await API(false, false).post(
        `users/${userId}/folders/${id}/share`,
        params,
        ''
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async unshareFolder({ id, user_id, userId}) {
    try {
      const params = { with_user_id: user_id };
      const response = await API(false, false).post(
        `users/${userId}/folders/${id}/unshare`,
        params,
        ''
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async getTree({userId}) {
    try {
      const response = await API(false, false).get(
        `users/${userId}/folders/folders_tree`,
        { data: {} }
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async getSharedItems({userId}) {
    try {
      const response = await API(false, false).get(
        `users/${userId}/folders/shared_items`,
        { data: {} }
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async moveFolder({userId, id, moveToFolder}) {
    try {
      const response = await API(false, false).post(
        `users/${userId}/folders/${id}/move`,
        { move_folder_id: moveToFolder },
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
}
export default FolderService;
