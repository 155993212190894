import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import ShareUsersSelector from "./dialogs/ShareUsers";
import Chip from '@material-ui/core/Chip';
import ShareUsersSelector1 from "./dialogs/ShareUsersAutocomplete";

const NewFileSelector = (props) => {
  const { fileList, currentFiles, onFileListChange } = props;
  const [ files, setFiles ] = useState(fileList || []);

  const onNewSelect = (files) => {
    setFiles(files);
  };

  const deleteEmail = (file) => {
    const index = files.indexOf(file);
    files.splice(index, 1);
    setFiles([].concat(files));
  };

  useEffect(() => {
    onFileListChange(files);
  }, [files, onFileListChange]);

  const renderEmails = () => {
    return files.map((file, index) => {
      return (
        <ListItem key={`email-${file}-${index}`}>
          <ListItemText primary={file} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              size="small"
              aria-label="delete"
              onClick={() => deleteEmail(file)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  return (
    <div>
      <ShareUsersSelector1
        selectedEmails={currentFiles}
        users={fileList}
        emails={fileList}
        onSelect={onNewSelect}
        isSelectUsers={false}
        placeholderLabel={'Search by name'}
      />
    </div>
  );
};

NewFileSelector.propTypes = {
  currentFiles: PropTypes.array,
  fileList: PropTypes.array,
  onFileListChange: PropTypes.func,
};

NewFileSelector.defaultProps = {
  currentFiles: PropTypes.array,
  fileList: [],
  onFileListChange: () => {},
};

export default NewFileSelector;
