import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  TextField,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  IconButton,
  Typography,
  Select,
  FormControl,
  InputLabel,
  Input, SvgIcon
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import styles from "./styles";
import { useForm } from "react-hook-form";
import {userActions} from "../../redux/actions/user-actions";
import {userConstants} from "../../redux/constants/user-constants";
import { notify } from "../toastMessage";
import {ReactComponent as ProfileSVG} from "../../assets/icons/icon_profile.svg";
import clsx from "clsx";

const insideStyles = makeStyles((theme) => ({
  input: {
    color: 'black !important'
  },
  error: {
    color: 'red',
    marginTop: -13,
    marginBottom: 10
  },
}));

const EditUserForm = (props) => {
  const { isOpen, onClose, classes, callbackOpenUserList, userData } = props;
  const { handleSubmit, errors, register } = useForm({defaultValues: userData});
  const dispatch = useDispatch();
  const customClasses = insideStyles();
  const status = useSelector((state) => state.user.status);
  const currentUser = useSelector((state) => state.authentication.user);

  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    if (isOpen){
      switch (status) {
        case userConstants.USER_EDIT_SUCCESS:
          notify('Edit user completed.', 'success');
          onClose();
          break;
        case userConstants.USER_EDIT_FAILED:
          notify('Edit user error', 'error');
          onClose();
          break;
        default:
          break;
      }
    }
  }, [status, isOpen]);

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen, open]);

  const onSubmit = (data) => {
    dispatch(userActions.editUser({
      id: userData.id,
      ...data
    }));
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={clsx(classes.root, 'userDialog')}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title" disableTypography >
        <span className="dialog-title">Edit User</span>
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)} id='my-form' noValidate>
          <Box>
            <div className="title__container">
              <label className="title__textfield">FIRST NAME</label>
            </div>
            <TextField
              id="user-firstname"
              variant="outlined"
              name="first_name"
              defaultValue={userData.first_name}
              className={customClasses.input}
              inputRef={register({
                required: {
                  value: true,
                  message: 'Firstname is required'
                }
              })}
            />
            {errors.first_name && <div className={customClasses.error}>{errors.first_name.message}</div>}
          </Box>
          <Box>
            <div className="title__container">
              <label className="title__textfield">LAST NAME</label>
            </div>
            <TextField
              id="user-lastname"
              variant="outlined"
              name="last_name"
              defaultValue={userData.last_name}
              inputRef={register({
                required: {
                  value: true,
                  message: 'Lastname is required'
                }
              })}
            />
            {errors.last_name && <div className={customClasses.error}>{errors.last_name.message}</div>}
          </Box>
          <Box>
            <div className="title__container">
              <label className="title__textfield">EMAIL</label>
            </div>
            <TextField
              id="user-email"
              variant="outlined"
              name="email"
              defaultValue={userData.email}
              disabled
              inputRef={register({
                required: {
                  value: true,
                  message: 'Email is required'
                },
                pattern: {
                  value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  message: 'Email is invalid'
                }
              })}
            />
            {errors.email && <div className={customClasses.error}>{errors.email.message}</div>}
          </Box>
          <Box>
            <div className="title__container">
              <label className="title__textfield">ROLE</label>
            </div>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel htmlFor="outlined-age-native-simple"></InputLabel>
              <Select
                native
                id="user-role"
                inputProps={{
                  name: 'role',
                  id: 'outlined-age-native-simple',
                }}
                name="role"
                defaultValue={userData.role}
                inputRef={register({
                  required: {
                    value: true,
                    message: 'The user role is required'
                  }
                })}
                disabled={!currentUser || (!!currentUser && currentUser.id === userData.id) }
              >
                <option aria-label="None" value="" />
                <option value={'admin'}>admin</option>
                <option value={'user'}>user</option>
              </Select>
            </FormControl>
            {errors.role && <div className={customClasses.error}>{errors.role.message}</div>}
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}  className="button__cancel">
          Cancel
        </Button>
        <Button className="default__accept" type={"submit"} form='my-form' content='Submit' value='Submit'>
          Save
          <SvgIcon
            className="share"
            component={ProfileSVG}
            viewBox={"0 0 48 49"}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditUserForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userData: PropTypes.shape({
    id: PropTypes.number,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    role: PropTypes.string,
    email: PropTypes.string,
  })
};

EditUserForm.defaultProps = {
  isOpen: false,
  userData: {},
};

export default withStyles(styles)(EditUserForm);
