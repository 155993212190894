import { calendarConstants } from "../constants/calendar-constants";

export const calendarActions = {
  requestMonthEvents,
  deleteReminder,
  addReminder,
  updateReminder,
};

function requestMonthEvents(month) {
  return {
    type: calendarConstants.REMINDERS_REQUEST,
    payload: { month },
  };
}

function deleteReminder(id) {
  return {
    type: calendarConstants.REMINDER_DELETE,
    payload: { id },
  };
}

function addReminder(reminder) {
  return {
    type: calendarConstants.REMINDER_ADD,
    payload: { reminder },
  };
}

function updateReminder(reminder) {
  return {
    type: calendarConstants.REMINDER_UPDATE,
    payload: { reminder },
  };
}
