import { navigationConstants } from '../constants/navigation-constants';

export const navigationActions = {
  addBreadcrums,
  getTree
};

function addBreadcrums(path) {
  return {
    type: navigationConstants.BREADCRUMB_ADD_REQUEST,
    payload: { path }
  };
}

function getTree() {
	return {
		type: navigationConstants.TREE_REQUEST
	}
}
