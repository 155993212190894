import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	SvgIcon
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from '@material-ui/core/styles';

import { fileActions } from '../../redux/actions/file-actions';
import styles from './styles';
import {ReactComponent as EditSVG} from "../../assets/icons/icon_edit.svg";

const EditFileDialog = (props) => {
	const dispatch = useDispatch();
	const { isOpen, onClose, classes, currentFile } = props;

	const [fileName, setFileName] = useState('');
	const [open, setOpen] = useState(isOpen);

	const handleNameChange = (event) => {
    setFileName(event.target.value);
  };

	const handleCreate = () => {
		dispatch(fileActions.editFile(currentFile.id, fileName));
		onClose();
	}

	useEffect(() => {
		if(isOpen !== open) {
			setOpen(isOpen);
		}
		if(!!currentFile) {
			setFileName(currentFile.name);
		}
	}, [isOpen, open, currentFile])

  return (
		<Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.root}>
	    <DialogTitle id="form-dialog-title" disableTypography >
				<div className="dialog-title">
					Rename <span className="highlight__name">{!!currentFile && currentFile.name}</span>
				</div>
	    </DialogTitle>
	    <DialogContent>
	      <TextField
	        autoFocus
	        margin="dense"
	        id="name"
	        fullWidth
					variant="outlined"
	        value={fileName}
	        onChange={handleNameChange}
	      />
	    </DialogContent>
	    <DialogActions>
	      <Button onClick={onClose} className="button__cancel">
	        Cancel
	      </Button>
	      <Button onClick={handleCreate}  className="default__accept" disabled={!currentFile || fileName.trim() === '' || fileName.trim() === currentFile.name}>
	        RENAME
					<SvgIcon
						className="share"
						component={EditSVG}
						viewBox={"0 0 24 24"}
					/>
	      </Button>
	    </DialogActions>
	  </Dialog>
	)
}

EditFileDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

EditFileDialog.defaultProps = {
  isOpen: false,
};

export default withStyles(styles)(EditFileDialog);
