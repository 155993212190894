import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { notify } from "../toastMessage";
import { withStyles } from "@material-ui/core/styles";
import FileSelector from "../FileSelector";
import { fileActions } from "../../redux/actions/file-actions";
import styles from "./styles";
import NewFileSelector from "../NewFileSelector";

const FileLinkingDialog = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, file, classes } = props;

  const [open, setOpen] = useState(isOpen);
  const [files, setFiles] = useState(!!file ? file.related_aquila_files : []);

  const handleFileListChange = (newFiles) => {
    const itself = newFiles.findIndex(
      (f) => !!file.id && !!f.id && f.id === file.id
    );
    if (itself > -1) {
      notify("Cannot link one file to itself.", "Error");
      setFiles(newFiles.splice(itself, 1));
    } else {
      setFiles(newFiles);
    }
  };

  const handleLinkFiles = () => {
    dispatch(fileActions.linkFiles(file, files));
    onClose();
  };

  useEffect(() => {
    if (isOpen !== open) {
      setFiles([]);
      setOpen(isOpen);
    }
  }, [isOpen, open]);

  return !file ? null : (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title" disableTypography >
        <div className="dialog-title">
          Link <span className="highlight__name">{!!file && file.name}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        {/*<DialogContentText>
          Link <span className="highlight__name">{!!file && file.name}</span>{" "}
          with:
        </DialogContentText>*/}
        <NewFileSelector
          fileList={files}
          currentFiles={!!file ? file.related_aquila_files : []}
          onFileListChange={handleFileListChange}
        />
        {/*<FileSelector
          title="Look for the files to link"
          subtitle="Linked files:"
          fileList={!!file ? file.related_aquila_files : []}
          onFileListChange={handleFileListChange}
        />*/}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className="button__cancel" >Cancel</Button>
        <Button onClick={handleLinkFiles} className="default__accept">Link</Button>
      </DialogActions>
    </Dialog>
  );
};

FileLinkingDialog.propTypes = {
  file: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    folder_id: PropTypes.number,
    owner_id: PropTypes.number,
    related_aquila_files: PropTypes.array,
  }),
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

FileLinkingDialog.defaultProps = {
  isOpen: false,
};

export default withStyles(styles)(FileLinkingDialog);
