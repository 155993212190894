import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
} from "@material-ui/core";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { withStyles } from "@material-ui/core/styles";

import Search from "./Search";
import styles from "./dialogs/styles";

const FileSelector = (props) => {
  const { fileList, onFileListChange, classes } = props;
  const [files, setFiles] = useState(fileList);

  const handleFileSelected = (file) => {
    if (!!file.id && !files.some((f) => f.id === file.id)) {
      setFiles([...files, file]);
    }
    //setFiles(files);
  };

  const removeFile = (file) => {
    const fIndex = files.findIndex((f) => f.id === file.id);
    if (fIndex > -1) {
      files.splice(fIndex, 1);
      setFiles([...files]);
    }
  };

  useEffect(() => {
    onFileListChange(files);
  }, [files, onFileListChange]);

  return (
    <Box className={classes.root}>
      <Typography>{props.title}</Typography>
      <Search displayResults onSelect={handleFileSelected} />
      <Typography>{props.subtitle}</Typography>
      <List dense={true}>
        {files.map((file, index) => {
          return (
            <ListItem button key={`attached-${file.id}-${index}`}>
              <ListItemText primary={file.name} />
              <ListItemText secondary={file.path} />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="remove"
                  onClick={() => removeFile(file)}
                >
                  <DeleteForeverIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

FileSelector.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  fileList: PropTypes.array,
  onFileListChange: PropTypes.func,
};

FileSelector.defaultProps = {
  title: "",
  subtitle: "",
  fileList: [],
  onFileListChange: () => {},
};

export default withStyles(styles)(FileSelector);
