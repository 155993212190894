/**
 * Index file for reducers
 * Add the reducers files here
 */
import { combineReducers } from "redux";

import authentication from "./auth-reducer";
import navigation from "./navigation-reducer";
import folder from "./folder-reducer";
import file from "./file-reducer";
import user from "./user-reducer";
import calendar from "./calendar-reducer";

const rootReducer = combineReducers({
  authentication,
  navigation,
  folder,
  file,
  user,
  calendar,
});

export default rootReducer;
