const styles = (theme) => ({
  folder: {
    backgroundColor: "#FFF",
    marginTop: theme.spacing(3),
    borderTop: '1px solid #000000',
    borderRight: '1px solid #000000',
    borderLeft: '1px solid #000000',
    fontSize: '18px',
    lineHeight: '21px',
    letterSpacing: '-0.02em',
    color: '#000000',
    "&_header": {
      textAlign: "left",
      fontWeight: "bold",
      fontSize: "1.2em",
    },
    "&_row": {
      borderBottom: "1px solid #000000",
      height: 48,
      paddingLeft: 16,
      paddingRight: 16
    },
    "&_icon": {
      alignItems: "center",
      '& a': {
        marginLeft: theme.spacing(1)
      }
    },
    "&_name": {
      color: "#000",
      textAlign: "left",
      alignItems: "center",
      '& p': {
        marginLeft: theme.spacing(1),
        fontSize: '18px !important'
      }
    },
    "&_owner": {
      color: "#1A1A1A",
      textAlign: "left",
      alignItems: "center",
    },
    "&_date": {
      color: "#1A1A1A",
      textAlign: "left",
      alignItems: "center",
      '& p': {
        fontSize: 12,
        textAlign: 'right',
        letterSpacing: '0.05em',
        color: '#000000',
        marginRight: theme.spacing(1)
      }
    },
    "&_actions": {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
      '& button': {
        marginRight: '8px !important',
        '&:last-child': {
          marginRight: '0 !important'
        }
      }
    },
    '& .folder': {
      '& a': {
        color: "#1A1A1A",
        textDecoration: 'none'
      }
    },
    fileIcon: {
      width: '24px !important',
      height: '24px !important',
      marginBottom: 10
    }
  },
  folderGrid:  {
    width: "100%",
    display: 'flex',
    flexFlow: 'row wrap',
    "& .MuiGridListTile-root": {
      width: "auto !important",
      height: "auto !important",
      padding: "1em !important",
    },
    "& .MuiSvgIcon-root": {
      width: "2.4em",
      height: "2.4em",
      fill: "#31D055",
    },
    "& .MuiIconButton-label .MuiSvgIcon-root": {
      width: "1em",
      height: "1em",
    },
    '& .folder': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: '24px',
      width: 144,
      marginBottom: 15,
      position: "relative",
      wordBreak: 'break-word',
      '& a': {
        color: "#1A1A1A",
        textDecoration: 'none',
        fontSize: 13
      },
      '& .MuiGridListTile-tile': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    },

    '& .files': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginRight: '24px',
      width: 144,
      position: "relative",
      '& a': {
        color: "#1A1A1A",
        textDecoration: 'none',
        fontSize: 13
      },
      '& .MuiGridListTile-tile': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }
    }
  },
  filesGrid: {
    marginTop: '10px'
  },
  handCursor: {
    cursor: 'pointer',
    width: 144
  }
});

export default styles;
