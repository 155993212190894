import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { TreeView, TreeItem } from "@material-ui/lab";
import { navigationActions } from "../../redux/actions/navigation-actions";
import { ATreeCloseIcon, ATreeOpenIcon } from "../../components/Icons";
import {Drawer, List, ListItem, ListItemIcon, ListItemText, StandardProps, SvgIcon, Tooltip} from "@material-ui/core";
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import PropTypes from "prop-types";
import clsx from "clsx";
import {
  HomeIcons
} from "../../components/Icons";
import { ReactComponent as IsoLogoSVG } from "../../assets/isologo.svg";
import { ReactComponent as CalendarSVG } from "../../assets/icons/icon_calendar.svg";
import { ReactComponent as CreateSVG } from "../../assets/icons/icon_create.svg";
import { ReactComponent as ProfileSVG } from "../../assets/icons/icon_profile.svg";
import { ReactComponent as UploadSVG } from "../../assets/icons/icon_upload.svg";
import { ReactComponent as UsersSVG } from "../../assets/icons/icon_users.svg";
import { ReactComponent as ShareSVG } from "../../assets/icons/icon_share.svg";
import { ReactComponent as TrashSVG } from "../../assets/icons/icon_trash.svg";
import { ReactComponent as LogoutSVG } from "../../assets/icons/icon_logout.svg";
import {ReactComponent as SideBarSVG} from "../../assets/aquila-icon-g-b-left.svg";
import Switch, { SwitchClassKey, SwitchProps } from '@material-ui/core/Switch';
import NewFolderDialog from "../../components/dialogs/NewFolder";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      "& .MuiTreeItem-iconContainer .MuiSvgIcon-root": {
        fill: "#000000",
      },
    },
    disabled: {
      opacity: '1 !important',
      "& path": {
        fill: '#CCC'
      },
     "& span" : {
        color: '#CCC'
     }
    },
    mainAquilaIcon: {
      width: 56,
      height: 56
    },
    mainAquilaTextIcon: {
      fontWeight: 'bold',
      fontSize: 24,
      lineHeight: '28px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '-0.03em',
      color: '#000000',
      whiteSpace: 'nowrap'
    },
    defaultTextIcon: {
      fontSize: 24,
      lineHeight: '28px',
      display: 'flex',
      alignItems: 'center',
      letterSpacing: '-0.03em',
      color: '#000000',
      whiteSpace: 'nowrap'
    },
    listItem: {
      "&:first-child": {
        "&:hover": {
          backgroundColor: 'transparent'
        }
      },
      "&:not(:first-child):hover": {
        backgroundColor: 'transparent',
        "& span": {
          color: '#808080',
        },
        "& path": {
          fill: '#808080'
        }
      }
    },
    marginGroup: {
      paddingTop: '4px',
      paddingBottom: '4px'
    },
    firstMarginGroup: {
      paddingTop: '4px',
      paddingBottom: '4px',
      marginTop: theme.spacing(4),
      '&:first-child': {
        marginTop: theme.spacing(2),
      }
    },
    firstMarginGroupSwitch: {
      paddingTop: '4px',
      paddingBottom: '4px',
      marginTop: theme.spacing(4)+'px !important',
      display: 'flex',
      marginBottom: theme.spacing(2)
    },
    firstMarginGroupSwitchClosed: {
      marginLeft: '34px !important',
    },
    firstMarginGroupSwitchOpen: {
      marginLeft: '42px !important',
    },
    treeItem: {
      textAlign: "left",
      color: theme.palette.secondary.contrastText,
      fontSize: 12,
      "& .MuiTreeItem-label": {
        lineHeight: 2,
        whiteSpace: 'nowrap'
      },
    },
    treeItemSeleted: {
      background: "none",
      color: theme.palette.primary.main,
    },
    openDrawer: {
      width: 80,
      height: 48
    },
    closedDrawer: {
      width: 65,
      height: 48
    },
    showText: {
      display: 'block'
    },
    hideText: {
      display: 'none'
    },
    drawerOpenCursor: {
      cursor: "w-resize"
    },
    drawerCloseCursor: {
      cursor: "e-resize"
    },
  })
);

const IOSSwitchStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 28,
      height: 16,
      padding: 0,
      display: 'flex',
    },
    switchBase: {
      padding: 2,
      color: theme.palette.common.white,
      '&$checked': {
        transform: 'translateX(12px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: '#000'
        },
      },
    },
    thumb: {
      width: 12,
      height: 11,
      boxShadow: 'none',
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor: '#000',
    },
    checked: {},
  }),
);

const IOSSwitch = (props) => {
  const classes = IOSSwitchStyles();
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
}



const Navigation = (props) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const classes = useStyles();
  const { disabledMenuItems } = props;
  const { tree } = useSelector((state) => state.navigation);
  const { user } = useSelector((state) => state.authentication);
  const { breadcrumbs } = useSelector((state) => state.navigation);
  const [loadNavigation, setLoadNavigation] = useState(false);
  const [checkedLightMode, setCheckedLightMode] = useState(false);
  const [adminUser, setAdminUser] = useState(false);

  const [myAquilaId, setMyAquilaId] = useState(-1);
  const [myTrashId, setMyTrashId] = useState(-1);


  const handleOpen = (e, id) => {
    e.preventDefault();
    history.push(`/${id}`);
    const { href } = window.location;
    window.location.href = href;
  };

  const handleOpenDefault = (e) => {
    handleOpen(e,myAquilaId);
  };

  const handleSharedFolder = (e) => {
    handleOpen(e,'shared');
  };

  const handleTrashFolder = (e) => {
    handleOpen(e,myTrashId);
  };

  const renderBranch = (branch, subLevel, index) => {
    const { id, name, subfolders } = branch;
    const itemsGroup1 = [
      [{label:'My Aquila', icon: IsoLogoSVG, iconClass: classes.mainAquilaIcon, textClass: classes.mainAquilaTextIcon}],
      [
        {label:'Upload Files', icon: UploadSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon},
        {label:'Create Folder', icon: CreateSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon},
        {label:'See Calendar', icon: CalendarSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon},
        {label:'Update Profile', icon: ProfileSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon},
        {label:'Manage Users', icon: UsersSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon}
      ],
      [{label:'Shared', icon: ShareSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon},
        {label:'Trash', icon: TrashSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon}],
      [
        {label:'', icon: null, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon},
        {label:'Logout1', icon: LogoutSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon}]
    ];
    return (
      <>
        <List>
          {itemsGroup1.map(element =>
            element.map((item, index) => (

            <ListItem
              button
              key={item.label}
              className={clsx({
                [classes.firstMarginGroup]: index === 0,
                [classes.marginGroup]: index !== 0
              })}
              disableRipple={true}
            >
              <div>{item.label}</div>
              { item.icon ?
                <>
                <Tooltip title={item.label} disableHoverListener={props.isOpen}>
                  <ListItemIcon>
                    <HomeIcons
                      component={item.icon}
                      classes={{
                        root: item.iconClass
                      }}
                      className={clsx({
                        [classes.openDrawer]: props.isOpen,
                        [classes.closedDrawer]: !props.isOpen
                      })}
                    />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={item.label}
                  classes={{
                    primary: item.textClass,
                  }}
                  className={clsx({
                    [classes.showText]: props.isOpen,
                    [classes.hideText]: !props.isOpen
                  })}
                /> </> :
                <>
                  <ListItemText primary={'DDDD'}/>
                </>
              }
            </ListItem>
          )))}
        </List>
      </>
    );
  };

  const getPreselected = () => {
    return !!breadcrumbs ? breadcrumbs.map((b) => `${b.id}`) : [];
  };

  useEffect(() => {
    if (!loadNavigation && !!tree && !!user) {
      dispatch(navigationActions.getTree());
      setLoadNavigation(true);
    }
  }, [loadNavigation, tree, user, dispatch]);

  useEffect(() => {
    if (tree && tree.length > 0) {
      setMyAquilaId(tree[0].id);
      setMyTrashId(tree[2].id);
    }
  }, [tree]);

  useEffect(() => {
    if (!!user) {
      setAdminUser(user.role.toLowerCase() === 'admin');
    }
  }, [user]);

  const itemsGroup = [
    [{id: 1, label:'My Aquila', icon: IsoLogoSVG, iconClass: classes.mainAquilaIcon, textClass: classes.mainAquilaTextIcon, clickEvent: handleOpenDefault,
      isManagerItem: false}],
    [
      {id: 2, label:'Upload Files', icon: UploadSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon, clickEvent: props.clickUpload,
        isManagerItem: false},
      {id: 3, label:'Create Folder', icon: CreateSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon, clickEvent: props.clickCreateFolder,
        isManagerItem: false},
      {id: 4, label:'See Calendar', icon: CalendarSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon, clickEvent: props.clickCalendar,
        isManagerItem: false},
      {id: 5, label:'Update Profile', icon: ProfileSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon, clickEvent: props.clickEditProfile,
        isManagerItem: false},
      {id: 6, label:'Manage Users', icon: UsersSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon, clickEvent: props.clickManageUsers,
        isManagerItem: true}
    ],
    [{id: 7, label:'Shared', icon: ShareSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon, clickEvent: props.clickShare,
      isManagerItem: false},
      {id: 8, label:'Trash', icon: TrashSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon, isManagerItem: false, clickEvent: handleTrashFolder
        }],
    [
      {id: 9, label:'Logout', icon: LogoutSVG, iconClass: classes.mainAquilaIcon, textClass: classes.defaultTextIcon, clickEvent: props.clickLogout,
        isManagerItem: false}]
  ];

  return (
    <>
    <List key={'list-navigation'}>
      {itemsGroup.map(element =>
        element.map((item, index) => (
          (!item.isManagerItem || (item.isManagerItem && adminUser)) ?
          <React.Fragment key={index}>{item.icon ?
          <ListItem
            button
            key={index}
            disabled={disabledMenuItems.includes(item.id)}
            className={clsx(classes.listItem, {
              [classes.firstMarginGroup]: index === 0,
              [classes.marginGroup]: index !== 0
            })}
            onClick={(e) => {
              item.clickEvent(e);
            }}
            classes={{
              disabled: classes.disabled
            }}
            disableRipple={true}
          >
            {item.icon ?
              <>
                <Tooltip title={item.label} disableHoverListener={props.isOpen}>
                  <ListItemIcon>
                    <HomeIcons
                      component={item.icon}
                      classes={{
                        root: item.iconClass
                      }}
                      className={clsx({
                        [classes.openDrawer]: props.isOpen,
                        [classes.closedDrawer]: !props.isOpen
                      })}
                    />
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={item.label}
                  classes={{
                    primary: item.textClass,
                  }}
                  className={clsx({
                    [classes.showText]: props.isOpen,
                    [classes.hideText]: !props.isOpen
                  })}
                />
              </> :
              <FormControlLabel
                control={
                  <IOSSwitch
                    checked={checkedLightMode}
                    onChange={(e) =>
                    {
                      e.stopPropagation();
                      setCheckedLightMode(!checkedLightMode);
                    }}
                    name="checkedB"
                  />
                }
                label="iOS style"
              />
            }
          </ListItem> :
            <IOSSwitch
              className={clsx(classes.firstMarginGroupSwitch, {
                [classes.firstMarginGroupSwitchOpen]: props.isOpen,
                [classes.firstMarginGroupSwitchClosed]: !props.isOpen
              })}
              checked={checkedLightMode}
              onChange={(e) =>
              {
                e.stopPropagation();
                setCheckedLightMode(!checkedLightMode);
              }}
              name="checkedB"
            />}</React.Fragment>
            : null
        ))
      )}
    </List>
    </>
  );
};

Navigation.propTypes = {
  isOpen: PropTypes.bool,
  clickUpload: PropTypes.func,
  clickCreateFolder: PropTypes.func,
  clickCalendar: PropTypes.func,
  clickEditProfile: PropTypes.func,
  clickManageUsers: PropTypes.func,
  clickLogout: PropTypes.func,
  clickShare: PropTypes.func,
  disabledMenuItems: PropTypes.array
};

Navigation.defaultProps = {
  isOpen: false
};

export default Navigation;
