/**
 *
 * Reducer for Calendar
 *
 */
import { calendarConstants } from "../constants/calendar-constants";
import { commonConstants } from '../constants/common-constants';

// The initial state for calendar
const calendarInitialState = {
  reminders: [],
  failure: false,
  status: "",
  loading: false,
};

export default function calendar(state = calendarInitialState, action) {
  let newState;
  switch (action.type) {
    case calendarConstants.REMINDERS_REQUEST:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case calendarConstants.REMINDERS_REQUEST_SUCCESS:
      const { reminders } = action.payload;
      newState = {
        ...state,
        reminders,
        loading: false,
        status: calendarConstants.REMINDERS_REQUEST_SUCCESS,
        failure: false,
      };
      return newState;
    case calendarConstants.REMINDERS_REQUEST_FAILURE:
      newState = {
        ...state,
        reminders: [],
        loading: false,
        status: calendarConstants.REMINDERS_REQUEST_FAILURE,
        failure: true,
      };
      return newState;
    case calendarConstants.REMINDER_DELETE:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case calendarConstants.REMINDER_DELETE_SUCCESS:
      newState = {
        ...state,
        loading: false,
        status: calendarConstants.REMINDER_DELETE_SUCCESS,
        failure: false,
      };
      return newState;
    case calendarConstants.REMINDER_DELETE_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: calendarConstants.REMINDER_DELETE_FAILURE,
        failure: true,
      };
      return newState;
    case calendarConstants.REMINDER_ADD:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case calendarConstants.REMINDER_ADD_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: calendarConstants.REMINDER_ADD_FAILURE,
        failure: true,
      };
      return newState;
    case calendarConstants.REMINDER_UPDATE:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case calendarConstants.REMINDER_UPDATE_SUCCESS:
      newState = {
        ...state,
        loading: false,
        status: calendarConstants.REMINDER_UPDATE_SUCCESS,
        failure: false,
      };
      return newState;
    case calendarConstants.REMINDER_UPDATE_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: calendarConstants.REMINDER_UPDATE_FAILURE,
        failure: true,
      };
      return newState;
    case commonConstants.RESET:
      return calendarInitialState;
    default:
      return state;
  }
}
