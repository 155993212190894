import {useDispatch} from "react-redux";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import React, {Fragment} from "react";
import clsx from "clsx";
import {HomeIcons} from "../Icons";
import {Box, IconButton, InputBase, Paper} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

export const useStyles = makeStyles((theme) =>
  createStyles({
    containerTextField: {
      display: 'flex',
      flexDirection: 'column'
    },
    label: {
      width: '100%',
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 'bold',
      fontSize: 9,
      lineHeight: '11px',
      letterSpacing: '0.08em',
      margin: '4px 0px'
    },
    textField: {
      width: '100%',
      border: '1px solid #000000',
      boxSizing: 'border-box',
      borderRadius: 16,
      height: 32,
      paddingLeft: '15px',
      paddingRight: '15px',
      '&:focus': {
        outline: 'none',
        border: '1px solid #CCC',
      }
    },
    showTextError: {
      color: "red"
    },
    showError: {
      borderColor: "red"
    }
  })
);

const CustomInput = ({
                       label, name, value, type,
                       margin, variant, placeholder, inputId,
                       className, onChange, showError, errorMessage
                     }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const handleSubmit = (event) => {
    event.preventDefault();
  };
  return (
    <div className={[className, classes.containerTextField].join(" ")}>
      <label
        className= {clsx(classes.label, {
          [classes.showTextError]: showError
        })}>
        {label}
      </label>
      <input
        id={inputId}
        type={type}
        margin={margin}
        variant={variant}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={clsx(classes.textField, {
          [classes.showError]: showError
        })}
      />
      {/*<Paper component="form" onSubmit={handleSubmit}
             className={clsx(classes.textField, {
               [classes.showError]: showError
             })}>
        <InputBase
          className={classes.input}
          placeholder="Search My Aquila"
          inputProps={{ "aria-label": "search" }}
          value={value}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          endAdornment={
            showError && <div>{errorMessage}</div>
          }
        />
      </Paper>*/}
    </div>
  );
}

export default CustomInput;
