import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authActions } from "../../redux/actions/auth-actions";
import { setResetHeaders } from "../../lib/auth.helper";
import { useStyles } from "./Login";
import { Container, Button, Box } from "@material-ui/core";

import loginLogo from "../../assets/login_logo1.svg";
import ToastMessage from "../../components/toastMessage";


const ResetPassword = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const params = `{"${decodeURI(useLocation().search.slice(1)).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"')}"}`;
  const useQuery = () => {
    const headers = params !== '{""}' ? JSON.parse(params) : {};
    if (!!headers['uid']) {
      headers['uid'] = decodeURIComponent(headers['uid']);
      setResetHeaders(headers);
    }
  }
  useQuery()
  const [resultMessage, setResultMessage] = useState({
    isVisible: false,
    tittle: "",
    message: "",
  });
  const [password, setPassword] = useState("");
  const [confirmation, setConfirmation] = useState("");

  const MIN_LENGTH = 8;
  const passwordIsValid = (pwd1, pwd2) => {
    let validationResult = false;
    let validationMessage = "";
    if (!pwd1) { 
      validationMessage = "Password not provided";
    } else
    if (!pwd2) { 
      validationMessage = "Password confirmation not provided";
    } else
    if (pwd1 !== pwd2) { 
      validationMessage = "Password confirmation do not match";
    } else
    if (pwd1.length < MIN_LENGTH) { 
      validationMessage = "Password must be "+MIN_LENGTH+" characters or longer"; 
    } else {
      validationResult = true;
    }
    setResultMessage({
      isVisible: !validationResult,
      tittle: validationResult? "" : "Invalid password",
      message: validationMessage,
    });
    return validationResult;
  };

  const submitChangePassword = () => {
    if (passwordIsValid(password, confirmation)) {
      dispatch(authActions.setNewPassword(password, confirmation));
      history.replace('/login');
    }
  };

  return (
    <Container className={classes.container}>
      <Box className={classes.box}>
        <center>
          <img alt="Logo" className={classes.logo} src={loginLogo} />
        </center>
        <br />
        <p className={classes.labelCaption}>Change your password</p>
        <br />
        <input
          id="password"
          className={classes.textField}
          type="password"
          margin="normal"
          variant="outlined"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <br /><br />
        <input
          id="confirmation"
          className={classes.textField}
          type="password"
          margin="normal"
          variant="outlined"
          placeholder="Confirmation"
          value={confirmation}
          onChange={(e) => setConfirmation(e.target.value)}
        />
        <br /><br />
        <Button className={classes.button} onClick={submitChangePassword}>
          Change Password
        </Button>
        {resultMessage.isVisible ? (
          <span className={classes.validationLabel}>
            {resultMessage.message}
          </span>
        ) : null}
      </Box>
      <ToastMessage />
    </Container>
  );
};

export default ResetPassword;
