import React, { Fragment } from "react";
import "./App.scss";

import { Provider } from 'react-redux';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import configureStore from './redux/store/configureStore';
import Router from './router';
import { getTheme } from './settings/themes';

function App() {
  const store = configureStore;

  return (
    <Fragment>
      <CssBaseline />
      <MuiThemeProvider theme={getTheme()}>
        <Provider store={store}>
          <div className="App">
            <Router />
          </div>
        </Provider>
      </MuiThemeProvider>
    </Fragment>
  );
}

export default App;
