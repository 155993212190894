export const folderConstants = {
  FOLDER_REQUEST: 'FOLDER_REQUEST',
  FOLDER_REQUEST_HOME: 'FOLDER_REQUEST_HOME',
  FOLDER_SUCCESS: 'FOLDER_SUCCESS',
  FOLDER_FAILURE: 'FOLDER_FAILURE',
  FOLDER_ADD: 'FOLDER_ADD',
  FOLDER_ADD_FAILURE: 'FOLDER_ADD_FAILURE',
  FOLDER_EDIT: 'FOLDER_EDIT',
  FOLDER_EDIT_FAILURE: 'FOLDER_EDIT_FAILURE',
  FOLDER_SHARE: 'FOLDER_SHARE',
  FOLDER_SHARE_SUCCESS: 'FOLDER_SHARE_SUCCESS',
  FOLDER_SHARE_FAILURE: 'FOLDER_SHARE_FAILURE',
  FOLDER_UNSHARE: 'FOLDER_UNSHARE',
  FOLDER_UNSHARE_SUCCESS: 'FOLDER_UNSHARE_SUCCESS',
  FOLDER_UNSHARE_FAILURE: 'FOLDER_UNSHARE_FAILURE',
  FOLDER_DELETE: 'FOLDER_DELETE',
  FOLDER_DELETE_FAILURE: 'FOLDER_DELETE_FAILURE',
  FOLDER_REQUEST_SHARED_ITEMS: 'FOLDER_REQUEST_SHARED_ITEMS',
  FOLDER_MOVE_REQUEST: "FOLDER_MOVE_REQUEST",
  FOLDER_MOVE_SUCCESS: "FOLDER_MOVE_SUCCESS",
  FOLDER_MOVE_FAILURE: "FOLDER_MOVE_FAILURE",
};
