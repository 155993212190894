import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import ShareUsersSelector from "./dialogs/ShareUsers";
import Chip from '@material-ui/core/Chip';
import ShareUsersSelector1 from "./dialogs/ShareUsersAutocomplete";

const UserList = (props) => {
  const { users, currentEmails, onChange } = props;
  const [emails, setEmails] = useState(currentEmails || []);

  const emailIsValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);

  const onSelect = (email) => {
    if (!emails.find((e) => email === e) && emailIsValid(email)) {
      let newEmails = [];
      newEmails = newEmails.concat(emails);
      newEmails.push(email);
      setEmails(newEmails);
    }
  };

  const onNewSelect = (newEmails) => {
    /*if (!emails.find((e) => email === e) && emailIsValid(email)) {
      let newEmails = [];
      newEmails = newEmails.concat(emails);
      newEmails.push(email);
      setEmails(newEmails);
    }*/
    setEmails(newEmails);
  };

  const deleteEmail = (email) => {
    const index = emails.indexOf(email);
    emails.splice(index, 1);
    setEmails([].concat(emails));
  };

  useEffect(() => {
    onChange(emails);
  }, [emails, onChange]);

  const renderEmails = () => {
    return emails.map((email, index) => {
      return (
        <ListItem key={`email-${email}-${index}`}>
          <ListItemText primary={email} />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              size="small"
              aria-label="delete"
              onClick={() => deleteEmail(email)}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  };

  return (
    <div>
      <ShareUsersSelector1
        selectedEmails={currentEmails}
        users={users} emails={emails} onSelect={onNewSelect} />
      {/*<ShareUsersSelector users={users} emails={emails} onSelect={onSelect} />
      <List dense={true}>{renderEmails()}</List>
      <div>{JSON.stringify(emails)}</div>*/}
    </div>
  );
};

UserList.propTypes = {
  currentEmails: PropTypes.array,
  users: PropTypes.array,
  onChange: PropTypes.func,
};

UserList.defaultProps = {
  currentEmails: [],
  users: [],
  onChange: () => {},
};

export default UserList;
