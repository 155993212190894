import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector, useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction, Tooltip
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from '@material-ui/icons/Delete';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import ConfirmationPopUp from './ConfirmationPopUp'

import { withStyles } from "@material-ui/core/styles";

import { fileActions } from "../../redux/actions/file-actions";
import { folderActions } from "../../redux/actions/folder-actions";
import { fileConstants } from "../../redux/constants/file-constants";
import { folderConstants } from "../../redux/constants/folder-constants"; 
import styles from "./styles";

const MySharedItem = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, shared, isFile, classes } = props;

  const [sharedName, setSharedName] = useState("");
  const [open, setOpen] = useState(isOpen);
  const [selectedUser, setSelectedUser] = useState(null);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deletedUsers, setDeletedUsers] = useState([]);
  const { loading: fileLoading, failure: fileFailure, status: fileStatus } = useSelector((state) => state.file);
  const { loading: folderLoading, failure: folderFailure, status: folderStatus } = useSelector((state) => state.folder);

  const hanldeCloseConfirmation = (confirm) => {
    if (confirm) {
      if (isFile) {
        dispatch(fileActions.unshareFile(shared.id, selectedUser.id));
      } else {
        dispatch(folderActions.unshareFolder(shared.id, selectedUser.id)); 
      }
    }
    setConfirmationOpen(false);
  };

  const handleOpenConfirmation = (user) => {
    setSelectedUser(user);
    setConfirmationOpen(true);
  }

  const getDisplayUsers = () => shared.users.filter(u => !deletedUsers.includes(u.id));

  useEffect(() => {
    if (isOpen !== open) {
      setSharedName("");
      setOpen(isOpen);
    }
    if (!!shared && sharedName !== shared.name) {
      setSharedName(shared.name);
    }
    if (isOpen && !!selectedUser) {
      const newLoading = fileLoading || folderLoading;
      setLoading(newLoading);
      if (!newLoading) {
        if ((fileFailure && fileStatus === fileConstants.FILE_UNSHARE_FAILURE) || (folderStatus === folderConstants.FOLDER_UNSHARE_FAILURE && folderFailure)) {
          // send error
        } else if (fileStatus === fileConstants.FILE_UNSHARE_SUCCESS || folderStatus === folderConstants.FOLDER_UNSHARE_SUCCESS) {
          const newDeletedUsers = [...deletedUsers, selectedUser.id];
          setDeletedUsers(newDeletedUsers);
          setSelectedUser(null);
          if (newDeletedUsers.length === shared.users.length) {
            onClose();
          }
        }
      }
    } else {
      setLoading(false);
    }
  }, [isOpen, open, shared, sharedName, fileLoading, fileFailure, fileStatus, folderLoading, folderFailure, folderStatus, selectedUser, loading, deletedUsers, fileConstants, folderConstants]);

  return isOpen && (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title"disableTypography >
        <span className="dialog-title">My Shared {isFile ? 'File' : 'Folder' }</span>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span className="highlight__name">{sharedName}</span> is shared with:
        </DialogContentText>
        <List className="shared-list">
          {isOpen && !!shared && getDisplayUsers().map(user => (
            <ListItem key={`user-${user.id}-${shared.id}`}>
              <ListItemText
                primary={user.full_name}
                secondary={user.email}
              />
              <ListItemSecondaryAction>
                <Tooltip title="UNSHARE">
                  <IconButton edge="end" aria-label="delete" onClick={() => handleOpenConfirmation(user)} disabled={loading}>
                    <RemoveCircleOutlineIcon/>
                  </IconButton>
                </Tooltip>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className="button__cancel">Cancel</Button>
      </DialogActions>
      { !!selectedUser && (
        <ConfirmationPopUp 
          title="Unshare Confirmation"
          message={`Are you sure? You can shared this again if you need it.`}
          isOpen={confirmationOpen}
          onClose={hanldeCloseConfirmation}
        />
      )}
    </Dialog>
  );
};

MySharedItem.propTypes = {
	isFile: PropTypes.bool,
  shared: PropTypes.object,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

MySharedItem.defaultProps = {
  isOpen: false,
  isFile: true
};

export default withStyles(styles)(MySharedItem);
