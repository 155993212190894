/**
 *
 * Folder saga file
 * Any action made for a folder should go here
 *
 */
import { call, put, takeEvery, all, select } from "redux-saga/effects";
import { folderConstants } from "../constants/folder-constants";
import { navigationConstants } from "../constants/navigation-constants";
import { FolderService } from "../../lib/folder.service";

/**
 * Workers
 */
export function* getHome(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const result = yield call(FolderService.getHome, { userId });
    yield put({
      type: folderConstants.FOLDER_SUCCESS,
      payload: { currentFolder: result },
    });
    yield put({
      type: navigationConstants.BREADCRUMB_ADD_REQUEST,
      payload: { path: result.path },
    });
  } catch (error) {
    yield put({ type: folderConstants.FOLDER_FAILURE });
  }
}

export function* getFolder(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const result = yield call(FolderService.getFolder, {
      ...action.payload,
      userId,
    });
    if(!!result){
      yield put({
        type: folderConstants.FOLDER_SUCCESS,
        payload: { currentFolder: result },
      });
      yield put({
        type: navigationConstants.BREADCRUMB_ADD_REQUEST,
        payload: { path: result.path },
      });
    } else {
      yield put({ type: folderConstants.FOLDER_FAILURE });
    }
  } catch (error) {
    yield put({ type: folderConstants.FOLDER_FAILURE });
  }
}

export function* addFolder(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    yield call(FolderService.addFolder, { ...action.payload, userId });
    yield put({
      type: folderConstants.FOLDER_REQUEST,
      payload: action.payload,
    });
    yield put({ type: navigationConstants.TREE_REQUEST });
  } catch (error) {
    yield put({ type: folderConstants.FOLDER_ADD_FAILURE });
  }
}

export function* editFolder(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const currentFolderId = yield select((state) => state.folder.currentFolder.id);
    yield call(FolderService.editFolder, { ...action.payload, userId });
    yield put({
      type: folderConstants.FOLDER_REQUEST,
      payload: {id: currentFolderId},
    });
    yield put({ type: navigationConstants.TREE_REQUEST });
  } catch (error) {
    yield put({ type: folderConstants.FOLDER_EDIT_FAILURE });
  }
}

export function* deleteFolder(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const currentFolderId = yield select((state) => state.folder.currentFolder.id);
    yield call(FolderService.deleteFolder, { id: action.payload.id, userId });
    yield put({
      type: folderConstants.FOLDER_REQUEST,
      payload: {id: currentFolderId},
    });
    yield put({ type: navigationConstants.TREE_REQUEST });
  } catch (error) {
    yield put({ type: folderConstants.FOLDER_DELETE_FAILURE });
  }
}

export function* shareFolder(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const { id, emails, comment } = action.payload;

    yield call(FolderService.shareFolder, { id, emails, comment, userId });
    yield put({ type: folderConstants.FOLDER_SHARE_SUCCESS });
  } catch (error) {
    yield put({ type: folderConstants.FOLDER_SHARE_FAILURE });
  }
}

export function* unshareFolder(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const { id, user_id } = action.payload;

    yield call(FolderService.unshareFolder, { id, user_id, userId });
    yield put({ type: folderConstants.FOLDER_UNSHARE_SUCCESS });
    yield put({ type: folderConstants.FOLDER_REQUEST_SHARED_ITEMS });
  } catch (error) {
    yield put({ type: folderConstants.FOLDER_UNSHARE_FAILURE });
  }
}

export function* getSharedItems(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const result = yield call(FolderService.getSharedItems, { userId });
    if (!result) { // no status when success
      yield put({ type: folderConstants.FOLDER_FAILURE });
    } else {
      const resultsFolder = {
        ...result,
        id: "shared_items",
        path: [],
        isLeave: true,
        owner_id: userId,
        parent_id: 0,
      };
      yield put({
        type: folderConstants.FOLDER_SUCCESS,
        payload: { currentFolder: resultsFolder },
      });
      
    }
  } catch (error) {
    yield put({ type: folderConstants.FOLDER_FAILURE });
  }
}

export function* moveFolder(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const currentFolderId = yield select((state) => state.folder.currentFolder.id);
    yield call(FolderService.moveFolder, { ...action.payload, userId });
    yield put({
      type: folderConstants.FOLDER_MOVE_SUCCESS,
    });
    yield put({
      type: folderConstants.FOLDER_REQUEST,
      payload: {id: currentFolderId},
    });
    yield put({ type: navigationConstants.TREE_REQUEST });
  } catch (error) {
    yield put({ type: folderConstants.FOLDER_MOVE_FAILURE });
  }
}

/**
 * Watchers
 */
export function* homeRequest() {
  yield takeEvery(folderConstants.FOLDER_REQUEST_HOME, getHome);
}

export function* folderRequest() {
  yield takeEvery(folderConstants.FOLDER_REQUEST, getFolder);
}

export function* newFolderRequest() {
  yield takeEvery(folderConstants.FOLDER_ADD, addFolder);
}

export function* editFolderRequest() {
  yield takeEvery(folderConstants.FOLDER_EDIT, editFolder);
}

export function* deleteFolderRequest() {
  yield takeEvery(folderConstants.FOLDER_DELETE, deleteFolder);
}

export function* shareFolderRequest() {
  yield takeEvery(folderConstants.FOLDER_SHARE, shareFolder);
}

export function* unshareFolderRequest() {
  yield takeEvery(folderConstants.FOLDER_UNSHARE, unshareFolder);
}

export function* sharedItemsRequest() {
  yield takeEvery(folderConstants.FOLDER_REQUEST_SHARED_ITEMS, getSharedItems);
}

export function* moveFolderRequest() {
  yield takeEvery(folderConstants.FOLDER_MOVE_REQUEST, moveFolder);
}

/**
 * Export function
 */
function* folderSaga() {
  // Watchers request
  yield all([
    homeRequest(),
    folderRequest(),
    newFolderRequest(),
    editFolderRequest(),
    deleteFolderRequest(),
    shareFolderRequest(),
    unshareFolderRequest(),
    sharedItemsRequest(),
    moveFolderRequest(),
  ]);
}
export default folderSaga;
