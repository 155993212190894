import API from "./api.service";

export class UserService {
  static async getUsers() {
    try {
      const response = await API(false, false).get(
        '/users',
        { data: {} },
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }

  static async createNewUser(data) {
    const response = await API(false, false).post(
      '/users',
      { user: {...data} },
      ""
    );
    return response.data;
  }

  static async updateUser(data) {
    const response = await API(false, false).put(
      `/users/${data.id}`,
      { user: {...data} },
      ""
    );
    return response.data;
  }

  static async deleteUser(data) {
    const response = await API(false, false).delete(
      `/users/${data.id}`,
      { data: {} },
      ""
    );
    return response.data;
  }
}
export default UserService;
