import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton, SvgIcon
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

import { folderActions } from "../../redux/actions/folder-actions";
import { userActions } from "../../redux/actions/user-actions";
import styles from "./styles";
import UserList from "../UserList";
import {ReactComponent as ShareSVG} from "../../assets/icons/icon_share_small.svg";

const ShareFolderDialog = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, classes, currentFolder } = props;

  //const { currentFolder } = useSelector((state) => (folder || state.folder));
  const { users } = useSelector((state) => state.user);
  const [tryGetUsers, setTryGetUsers] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [open, setOpen] = useState(isOpen);
  const [emails, setEmails] = useState([]);
  const [comment, setComment] = useState("");

  const handleShare = () => {
    const emailsToShare = emails.join(";");
    dispatch(
      folderActions.shareFolder(currentFolder.id, emailsToShare, comment)
    );
    onClose();
  };

  const handleCommentChange = (event) => {
    setComment(event.target.value);
  };

  const onUsersChange = (emails) => {
    setEmails(emails);
  };

  useEffect(() => {
    if (isOpen !== open) {
      setFolderName("");
      setComment("");
      setOpen(isOpen);
      setEmails([]);
    }
    if (!!currentFolder && folderName !== currentFolder.name) {
      setFolderName(currentFolder.name);
    }
    if (!tryGetUsers) {
      setTryGetUsers(true);
      if (!!users && !users.length) {
        dispatch(userActions.usersRequest());
      }
    }
  }, [isOpen, open, currentFolder, folderName, users, tryGetUsers]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title" disableTypography >
        <div className="dialog-title">
          Share <span className="highlight__name">{folderName}</span>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="title__container">
          <label className="title__textfield">SHARE WITH</label>
        </div>
        <UserList users={users} onChange={onUsersChange} />
        <div className="title__container">
          <label className="title__textfield">COMMENTS</label>
        </div>
        <TextField
          multiline
          rowsMax={4}
          variant="outlined"
          value={comment}
          onChange={handleCommentChange}
        />
      </DialogContent>
      <DialogActions>
        <Button className="button__cancel" onClick={onClose}>Cancel</Button>
        <Button className="default__accept" onClick={handleShare} disabled={!emails.length}>
          SHARE
          <SvgIcon
            className="share"
            component={ShareSVG}
            viewBox={"0 0 24 24"}
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ShareFolderDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  currentFolder: PropTypes.object
};

ShareFolderDialog.defaultProps = {
  isOpen: false
};

export default withStyles(styles)(ShareFolderDialog);
