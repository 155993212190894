/**
 *
 * User saga file
 * Any action made for a user should go here
 *
 */
import { call, put, takeEvery, all } from 'redux-saga/effects';
import { userConstants } from '../constants/user-constants';
import { UserService } from '../../lib/user.service';
/**
 * Workers
 */
export function* getUsers(action) {
  try {
    const result = yield call(UserService.getUsers);
    yield put({type: userConstants.USERS_SUCCESS, payload: {users: result} });
  } catch(error) {
    yield put({type: userConstants.USERS_FAILURE})
  }
}

export function* createNewUser(action) {
  try {
    const result = yield call(UserService.createNewUser, action.payload);
    yield put({type: userConstants.USER_CREATE_SUCCESS, payload: {user: result} });
  } catch(error) {
    yield put({type: userConstants.USER_CREATE_FAILED})
  }
}

export function* editUser(action) {
  try {
    const result = yield call(UserService.updateUser, action.payload);
    yield put({type: userConstants.USER_EDIT_SUCCESS, payload: {user: result} });
  } catch(error) {
    yield put({type: userConstants.USER_EDIT_FAILED})
  }
}

export function* deleteUser(action) {
  try {
    const result = yield call(UserService.deleteUser, action.payload);
    yield put({type: userConstants.USER_DELETE_SUCCESS, payload: {} });
  } catch(error) {
    yield put({type: userConstants.USER_DELETE_FAILED})
  }
}

/**
 * Watchers
 */
export function* usersRequest() {
  yield takeEvery(userConstants.USERS_REQUEST, getUsers);
}

export function* userCreateRequest() {
  yield takeEvery(userConstants.USER_CREATE_REQUEST, createNewUser);
}

export function* userEditRequest() {
  yield takeEvery(userConstants.USER_EDIT_REQUEST, editUser);
}

export function* userDeleteRequest() {
  yield takeEvery(userConstants.USER_DELETE_REQUEST, deleteUser);
}

/**
 * Export function
 */
function* userSaga() {
  // Watchers request
  yield all([
    usersRequest(),
    userCreateRequest(),
    userEditRequest(),
    userDeleteRequest()
  ]);
}
export default userSaga;
