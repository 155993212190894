import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {createStyles, makeStyles, withStyles} from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import {IconButton, Grid, Typography, Tooltip, SvgIcon} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Delete";
import GetAppIcon from "@material-ui/icons/GetApp";
import ShareIcon from "@material-ui/icons/Share";
import EditIcon from '@material-ui/icons/Edit';
import LinkIcon from "@material-ui/icons/Link";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import { AFileIcon, AFolderIcon } from "../../components/Icons";

import styles from "./styles";
import { folderActions } from "../../redux/actions/folder-actions";
import { fileActions } from "../../redux/actions/file-actions";
import { userActions } from "../../redux/actions/user-actions";
import ShareFileDialog from "../../components/dialogs/ShareFile";
import EditFolderDialog from "../../components/dialogs/EditFolder";
import EditFileDialog from "../../components/dialogs/EditFile";
import FileLinkingDialog from "../../components/dialogs/FileLinking";
import ConfirmationPopUp from "../../components/dialogs/ConfirmationPopUp";
import MoveFileDialog from "../../components/dialogs/MoveFile";
import MoveFolderDialog from "../../components/dialogs/MoveFolder";
import MySharedItem from "../../components/dialogs/MySharedItem";
import { ReactComponent as FolderSVG } from "../../assets/icons/folder_icon_small.svg";
import {ReactComponent as EditSVG} from "../../assets/icons/icon_edit.svg";
import {ReactComponent as TrashSVG, ReactComponent as DeleteSVG} from "../../assets/icons/icon_trash.svg";
import {ReactComponent as MoveSVG} from "../../assets/icons/icon_move.svg";
import {ReactComponent as ShareSVG} from "../../assets/icons/icon_share_small.svg";
import {ReactComponent as FileSVG} from "../../assets/icons/file_icon_small.svg";
import {ReactComponent as LinkSVG} from "../../assets/icons/icon_link.svg";
import {ReactComponent as DownloadSVG} from "../../assets/icons/icon_download.svg";
import clsx from "clsx";

const useStyles = makeStyles((theme) =>
  createStyles({
    blackIcon: {
      fill: '#000 !important'
    }
  })
);

function Folder(props) {
  const dispatch = useDispatch();
  const { classes } = props;
  let { id } = useParams();
  const { currentFolder } = useSelector((state) => state.folder);
  const currentUser = useSelector((state) => state.authentication.user);
  const users = useSelector((state) => state.user.users);
  const [currentFile, setCurrentFile] = useState(null);
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const [tryGetUsers, setTryGetUsers] = useState(false);
  const [seletedFolder, setSeletedFolder] = useState(null);
  const [isEditFolderOpen, setIsEditFolderOpen] = useState(false);
  const [isEditFileOpen, setIsEditFileOpen] = useState(false);
  const [fileLinkingIsOpen, setFileLinkingIsOpen] = useState(false);
  const [isDeleteFileConfirmationOpen, setIsDeleteFileConfirmationOpen] = useState(false);
  const [isDeleteFolderConfirmationOpen, setIsDeleteFolderConfirmationOpen] = useState(false);
  const [isMoveFileOpen, setIsMoveFileOpen] = useState(false);
  const [isMoveFolderOpen, setIsMoveFolderOpen] = useState(false);
  const [inTrash, setInTrash] = useState(false);
  const [inSharedItems, setSharedItems] = useState(false);
  const [isSharedItemOpen, setIsSharedItemOpen] = useState(false);
  const componentClasses = useStyles();

  /**
   * Memoize this method with useCallback, so react will keep the same instance
   * of 'requestLoadFolder' across every render (avoid infinitive loops in useEffect),
   * it only re-creates the instance when the 'dispatch' reference dep changes
   * more info at https://dmitripavlutin.com/dont-overuse-react-usecallback/
   * */
  const requestLoadFolder = useCallback(
    (id) => {
      if (id.toString().toLowerCase() === "home") {
        dispatch(folderActions.requestHome());
      } else {
        dispatch(folderActions.requestFolder(id));
      }
    },
    [dispatch]
  );

  const requestDownload = (file) => {
    dispatch(fileActions.requestDownload(file));
  };

  const requestFileShare = (file) => {
    setCurrentFile(file);
    setShareIsOpen(true);
  };

  const onCloseFileShare = () => {
    setShareIsOpen(false);
    setCurrentFile(null);
  };

  const getUpdateAtFormat = (date) => {
    let options = { year: 'numeric', month: 'long', day: 'numeric' };
    date = new Date(date);
    return date.toLocaleDateString("en-US", options)
    /*date = new Date(date);
    date.slice(0, 10);*/
  }

  const handleEditFolder = (folder) => {
    setSeletedFolder(folder);
    setIsEditFolderOpen(true);
  }

  const handleCloseEditFolder = () => {
    setIsEditFolderOpen(false);
    setSeletedFolder(null);
  }

  const handleEditFile = (file) => {
    setCurrentFile(file);
    setIsEditFileOpen(true);
  }

  const handleCloseEditFile = () => {
    setIsEditFileOpen(false);
    setCurrentFile(null);
  }

  const handleDeleteFile = (file) => {
    setCurrentFile(file);
    setIsDeleteFileConfirmationOpen(true);
  }

  const handleDeleteFileConfirmation = (answer = false) => {
    if (answer) {
      dispatch(fileActions.deleteFile(currentFile));
    }
    setIsDeleteFileConfirmationOpen(false);
    setCurrentFile(null);
  };

  const handleDeleteFolder = (folder) => {
    setSeletedFolder(folder);
    setIsDeleteFolderConfirmationOpen(true);
  }

  const handleDeleteFolderConfirmation = (answer = false) => {
    if (answer) {
      dispatch(folderActions.deleteFolder(seletedFolder.id));
    }
    setIsDeleteFolderConfirmationOpen(false);
    setSeletedFolder(null);
  };

  const requestFileLinking = (file) => {
    setCurrentFile(file);
    setFileLinkingIsOpen(true);
  };

  const onCloseFileLinking = () => {
    setFileLinkingIsOpen(false);
    setCurrentFile(null);
  };

  const requestFileMove = (file) => {
    setCurrentFile(file);
    setIsMoveFileOpen(true);
  };

  const onCloseFileMove = () => {
    setIsMoveFileOpen(false);
    setCurrentFile(null);
  };

  const handleMoveFile = (file, moveToFolderId) => {
    dispatch(fileActions.moveFile(file, moveToFolderId));
  }

  const requestFolderMove = (folder) => {
    setSeletedFolder(folder);
    setIsMoveFolderOpen(true);
  };

  const onCloseFolderMove = () => {
    setIsMoveFolderOpen(false);
    setSeletedFolder(null);
  };

  const handleMoveFolder = (folder, moveToFolderId) => {
    dispatch(folderActions.moveFolder(folder, moveToFolderId));
  }

  const onCloseSharedItem = () => {
    setSeletedFolder(null);
    setCurrentFile(null);
    setIsSharedItemOpen(false);
  }

  const handleManageSharedFolder = (folder) => {
    setSeletedFolder(folder);
    setIsSharedItemOpen(true);
  }

  const handleManageSharedFile = (file) => {
    setCurrentFile(file);
    setIsSharedItemOpen(true);
  }

  const getFolderPath = () => {
    return currentFolder.path.map(p => p.name).join('/');
  }

  const getFilePath = (file) => {
    if (currentFolder.id === 'search') {
      return file.folder.path.map(p => p.name).join('/');
    } else {
      return getFolderPath();
    }
  }

  const renderSubfolders = (subfolders) => {
    return subfolders.map((subfolder, index) => {
      return (
        <Grid
          container
          item
          xs={12}
          key={`file-${subfolder.id}-${index}`}
          className={`${classes.folder}_row folder`}
        >
          <Grid container item xs={9} className={`${classes.folder}_icon`}>
            <SvgIcon
              component={FolderSVG}
              viewBox={"0 0 24 24"}
            />
            <Link to={`${subfolder.id}`} disabled={!!inSharedItems}>{subfolder.name}</Link>
          </Grid>
          {/*
          <Grid container item xs={1} className={`${classes.folder}_name`}>

          </Grid>
          <Grid container item xs={4} className={`${classes.folder}_owner`}>
            <Typography>{getFolderPath()}</Typography>
          </Grid>
          <Grid container item xs={2} className={`${classes.folder}_date`}>
            <Typography>{getUpdateAtFormat(subfolder.updated_at)}</Typography>
          </Grid>
          */}
          <Grid item xs={3} className={`${classes.folder}_actions`}>
            {!inSharedItems && !inTrash && subfolder.owner.id === currentUser.id && (
              <Tooltip title="Move">
                <IconButton
                  edge="end"
                  aria-label="Move"
                  size="small"
                  onClick={() => requestFolderMove(subfolder)}
                >
                  <SvgIcon
                    component={MoveSVG}
                  />
                </IconButton>
              </Tooltip>
            )}
            {!inSharedItems && !inTrash && (
              <Tooltip title="Edit">
                <IconButton
                  edge="end"
                  aria-label="edit"
                  size="small"
                  onClick={() => handleEditFolder(subfolder)}
                >
                  <SvgIcon
                    component={EditSVG}
                  />
                </IconButton>
              </Tooltip>
            )}
            {!inSharedItems && subfolder.owner.id === currentUser.id &&
            (
              <Tooltip title="Delete">
                <IconButton 
                  edge="end" 
                  aria-label="delete" 
                  size="small" 
                  onClick={() => handleDeleteFolder(subfolder)} 
                >
                  <SvgIcon
                    component={TrashSVG}
                    viewBox={"0 0 50 50"}
                  />
                </IconButton>
              </Tooltip>
            )}
            {!inSharedItems && !!inTrash && subfolder.owner.id === currentUser.id && (
              <Tooltip title="Restore">
                <IconButton
                  edge="end"
                  aria-label="Restore"
                  size="small"
                  onClick={() => requestFolderMove(subfolder)}
                >
                  <SvgIcon
                    component={SettingsBackupRestoreIcon}
                    className={clsx(componentClasses.blackIcon)}
                  />
                </IconButton>
              </Tooltip>
            )}
            {!!inSharedItems && (
              <Tooltip title="Manage Share">
                <IconButton
                  edge="end"
                  aria-label="edit"
                  size="small"
                  onClick={() => handleManageSharedFolder(subfolder)}
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>
        </Grid>
      );
    });
  };

  const renderFiles = (aquilaFiles) => {
    return aquilaFiles.map((file, index) => {
      return (
        <Grid
          container
          item
          xs={12}
          key={`file-${file.id}-${index}`}
          className={`${classes.folder}_row`}
        >
          <Grid container item xs={6} className={`${classes.folder}_icon`}>
            <SvgIcon
              component={FileSVG}
              viewBox={"0 0 24 24"}
            />
            <div className={`${classes.folder}_name`}>
              <Typography>{file.name}</Typography>
            </div>
          </Grid>
          {/*
          <Grid container item xs={3} className={`${classes.folder}_name`}>
            <Typography>{file.name}</Typography>
          </Grid>
          <Grid container item xs={4} className={`${classes.folder}_owner`}>
            <Typography>{getFilePath(file)}</Typography>
          </Grid>
          <Grid container item xs={2} className={`${classes.folder}_date`}>
            <Typography>{getUpdateAtFormat(file.updated_at)}</Typography>
          </Grid>*/}
          {!inSharedItems ?
            (<Grid item xs={6} className={`${classes.folder}_actions`}>
              <div className={`${classes.folder}_date`}>
                <Typography>{getUpdateAtFormat(file.updated_at)}</Typography>
              </div>
              {!inTrash && (
                <Tooltip title="Share">
                  <IconButton
                    edge="end"
                    aria-label="share"
                    size="small"
                    onClick={() => requestFileShare(file)}
                  >
                    <SvgIcon
                      component={ShareSVG}
                    />
                  </IconButton>
                </Tooltip>
              )}
              <Tooltip title="Download">
                <IconButton
                  edge="end"
                  aria-label="download"
                  size="small"
                  onClick={() => requestDownload(file)}
                >
                  <SvgIcon
                    component={DownloadSVG}
                  />
                </IconButton>
              </Tooltip>
              {!inTrash && file.owner.id === currentUser.id && (
                <Tooltip title="Move">
                  <IconButton
                    edge="end"
                    aria-label="Move"
                    size="small"
                    onClick={() => requestFileMove(file)}
                  >
                    <SvgIcon
                      component={MoveSVG}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inTrash && (
                <Tooltip title="Link">
                  <IconButton
                    edge="end"
                    aria-label="link"
                    size="small"
                    onClick={() => requestFileLinking(file)}
                  >
                    <SvgIcon
                      component={LinkSVG}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inTrash && (
                <Tooltip title="Edit">
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    size="small"
                    onClick={() => handleEditFile(file)}
                  >
                    <SvgIcon
                      component={EditSVG}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!!inTrash && file.owner.id === currentUser.id && (
                <Tooltip title="Restore">
                  <IconButton
                    edge="end"
                    aria-label="Restore"
                    size="small"
                    onClick={() => requestFileMove(file)}
                  >
                    <SettingsBackupRestoreIcon />
                  </IconButton>
                </Tooltip>
              )}
              { file.owner.id === currentUser.id &&
              (
                <Tooltip title="Delete">
                  <IconButton edge="end" aria-label="delete" size="small" onClick={() => handleDeleteFile(file)}>
                    <SvgIcon
                      component={TrashSVG}
                      viewBox={"0 0 50 50"}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>)
            :
            (
              <Grid item xs={6} className={`${classes.folder}_actions`}>
                  <div className={`${classes.folder}_date`}>
                    <Typography>{getUpdateAtFormat(file.updated_at)}</Typography>
                  </div>
                <Tooltip title="Manage Share">
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    size="small"
                    onClick={() => handleManageSharedFile(file)}
                  >
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
            </Grid>)
          }
        </Grid>
      );
    });
  };

  useEffect(() => {
    if (!!currentUser) {
      setSharedItems(false);
      // Trash validation
      if (!!currentFolder && !!currentFolder.path && !!currentFolder.path[0]) {
        setInTrash(currentFolder.path[0].folder_type === 'trash');
      }
      // Routing
      if (!currentFolder && !!id) {
        requestLoadFolder(id);
      } else if (currentFolder.id === "search"  || currentFolder.id === "shared_items") {
        // do nothing when is under search results
        if (currentFolder.id === "shared_items") {
          setSharedItems(true);
        }
      } else if (
        id === "home" &&
        !!currentFolder.folder_type &&
        currentFolder.folder_type.toLowerCase() !== "home"
        ) {
        // Request home if the path id is home and the current folder is another
        requestLoadFolder(id);
      } else if (id === "shared" && !!currentFolder.folder_type &&
        currentFolder.folder_type.toLowerCase() !== "shared") {
        // Request folder if the path is shared and the current folder is another
        requestLoadFolder(id);
      } else if (!parseInt(id)) {
        // It avoid the recurrence when the id isn't a number
      } else if (parseInt(id) !== currentFolder.id) {
        // Request the new folder when change the url
        requestLoadFolder(id);
      }
    }
  }, [
    id,
    currentFolder,
    currentUser,
    requestLoadFolder,
  ]);


  useEffect(() => {
    if (!!currentUser) {
      if (!tryGetUsers) {
        setTryGetUsers(true);
        if (!users.length) {
          dispatch(userActions.usersRequest());
        }
      }
    }
  }, [
    currentUser,
    tryGetUsers,
    users,
    dispatch,
  ]);

  return (
    <div>
      {!!currentFolder &&
      !!currentFolder.subfolders && currentFolder.subfolders.length > 0 &&
        <Grid container className={classes.folder}>
          {/*<Grid container item xs={12} className={`${classes.folder}_row`}>
            <Grid item xs={1} />
            <Grid item xs={3} className={`${classes.folder}_header left`}>
              Name
            </Grid>
            <Grid item xs={4} className={`${classes.folder}_header left`}>
              Origin
            </Grid>
            <Grid item xs={2} className={`${classes.folder}_header left`}>
              Date
            </Grid>
            <Grid item xs={2} className={`${classes.folder}_header`}>
              Actions
            </Grid>
          </Grid>*/}
          {renderSubfolders(currentFolder.subfolders)}
        </Grid>
      }
      {
        !!currentFolder &&
        !!currentFolder.aquila_files &&
        currentFolder.aquila_files.length > 0 &&
        <Grid container className={classes.folder}>
          {renderFiles(currentFolder.aquila_files)}
        </Grid>
      }
      <ShareFileDialog
        isOpen={shareIsOpen}
        onClose={onCloseFileShare}
        users={users}
        file={currentFile}
      />
      <EditFolderDialog 
        isOpen={isEditFolderOpen}
        onClose={handleCloseEditFolder}
        currentFolder={seletedFolder}
      />
      <EditFileDialog 
        isOpen={isEditFileOpen}
        onClose={handleCloseEditFile}
        currentFile={currentFile}
      />
      <FileLinkingDialog
        isOpen={fileLinkingIsOpen}
        onClose={onCloseFileLinking}
        file={currentFile}
      />
      <ConfirmationPopUp 
        isOpen={isDeleteFileConfirmationOpen}
        icon={<DeleteIcon />}
        title="Delete confirmation"
        message={`Are you sure you want to delete this file: ${currentFile && currentFile.name}`}
        onClose={handleDeleteFileConfirmation}
      />
      <ConfirmationPopUp 
        isOpen={isDeleteFolderConfirmationOpen}
        icon={<DeleteIcon />}
        title="Delete confirmation"
        message={`Are you sure you want to delete this folder: ${seletedFolder && seletedFolder.name}`}
        onClose={handleDeleteFolderConfirmation}
      />

      <MoveFileDialog 
        isOpen={isMoveFileOpen}
        onClose={onCloseFileMove}
        folderSelection={handleMoveFile}
        isMoveAction={!inTrash}
        file={currentFile}
      />

      <MoveFolderDialog 
        isOpen={isMoveFolderOpen}
        onClose={onCloseFolderMove}
        folderSelection={handleMoveFolder}
        isMoveAction={!inTrash}
        folder={seletedFolder}
      />

      <MySharedItem
        isOpen={isSharedItemOpen}
        onClose={onCloseSharedItem}
        isFile={!!currentFile}
        shared={currentFile || seletedFolder}
      />
    </div>
  );
}

Folder.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  path: PropTypes.string,
  items: PropTypes.array,
  isLeave: PropTypes.bool,
  onSubFolderClicked: PropTypes.func,
};

Folder.defaultProps = {
  items: [],
  isLeave: true,
  onSubFolderClicked: () => {},
};

export default withStyles(styles)(Folder);
