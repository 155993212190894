import { fileConstants } from "../constants/file-constants";

export const fileActions = {
  requestDownload,
  addFile,
  editFile,
  deleteFile,
  shareFile,
  unshareFile,
  linkFiles,
  search,
  requestPreview,
  requestRelatedPreview,
  moveFile,
};

function requestDownload(file) {
  return {
    type: fileConstants.FILE_DOWNLOAD_REQUEST,
    payload: { file },
  };
}

function addFile(file) {
  return {
    type: fileConstants.FILE_ADD,
    payload: { file },
  };
}
function editFile(id, fileName) {
  return {
    type: fileConstants.FILE_EDIT,
    payload: { id, fileName }
  }
}
function shareFile(id, emails, comment) {
  return {
    type: fileConstants.FILE_SHARE,
    payload: { id, emails, comment },
  };
}

function unshareFile(id, user_id) {
  return {
    type: fileConstants.FILE_UNSHARE,
    payload: { id, user_id },
  };
}

function linkFiles(file, files) {
  return {
    type: fileConstants.FILE_LINKING,
    payload: { file, files },
  };
}

function search(criteria, showFolder) {
  return {
    type: fileConstants.FILE_SEARCH,
    payload: { criteria, showFolder },
  };
}

function requestPreview(file) {
  return {
    type: fileConstants.FILE_PREVIEW_REQUEST,
    payload: { file },
  };
}

function requestRelatedPreview(file) {
  return {
    type: fileConstants.FILE_RELATED_PREVIEW_REQUEST,
    payload: { file },
  };
}

function deleteFile(file) {
  return {
    type: fileConstants.FILE_DELETE,
    payload: { file },
  };
}

function moveFile(file, forlderId) {
  return {
    type: fileConstants.FILE_MOVE_REQUEST,
    payload: { file, moveToFolder: forlderId },
  };
}
