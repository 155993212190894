import { userConstants } from '../constants/user-constants';
export const userActions = {
  usersRequest,
  createNewUser,
  editUser,
  deleteUser
};
function usersRequest() {
  return {
    type: userConstants.USERS_REQUEST,
    payload: { }
  };
}

function createNewUser(userData) {
  return {
    type: userConstants.USER_CREATE_REQUEST,
    payload: {...userData}
  };
}

function editUser(userData) {
  return {
    type: userConstants.USER_EDIT_REQUEST,
    payload: {...userData}
  };
}

function deleteUser(id) {
  return {
    type: userConstants.USER_DELETE_REQUEST,
    payload: {id}
  };
}
