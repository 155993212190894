import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton, Grid
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import { format } from "date-fns";
import { calendarActions } from "../../redux/actions/calendar-actions";

import UserList from "../UserList";
import FileSelector from "../FileSelector";
import ConfirmationPopUp from "./ConfirmationPopUp";
import NewFileSelector from "../NewFileSelector";

const ReminderForm = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, classes, eventData } = props;
  const { users } = useSelector((state) => state.user);

  const [open, setOpen] = useState(isOpen);
  // TODO load file objects from the fileRefs ids
  const [files, setFiles] = useState(eventData.fileRefs);
  const [title, setTitle] = useState(eventData.title);
  const [notes, setNotes] = useState(eventData.notes);
  const [startDate, setStartDate] = useState(
    eventData.startDate instanceof Date
      ? format(eventData.startDate, "yyyy-MM-dd")
      : eventData.startDate
  );
  const [repeatInterval, setRepeatInterval] = useState(
    eventData.repeatInterval
  );
  const [emails, setEmails] = useState(eventData.emails);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleRepeatIntervalChange = (event) => {
    setRepeatInterval(event.target.value);
  };

  const handleFileListChange = (files) => {
    setFiles(files);
  };

  const onUsersChange = (emails) => {
    setEmails(emails);
  };

  const cleanupForm = () => {
    setTitle("");
    setNotes("");
    setFiles([]);
    setEmails([]);
    setStartDate(format(new Date(), "yyyy-MM-dd"));
    setRepeatInterval("");
  };

  const onSubmitEvent = () => {
    const parsedDate = new Date(`${startDate} 00:00:00`);
    debugger
    let reminder = {
      title,
      notes,
      startDate: parsedDate,
      fileRefs: files.map((file) => file.id),
      emails,
      repeatInterval,
    };
    if (!!eventData.id && eventData.id !== -1) {
      reminder.id = eventData.id;
      // update reminder
      dispatch(calendarActions.updateReminder(reminder));
    } else {
      // create reminder
      dispatch(calendarActions.addReminder(reminder));
    }
    onClose();
    cleanupForm();
  };

  const onDeleteEvent = () => {
    setIsConfirmationOpen(true);
  };

  const handleDeleteConfirmation = (answer = false) => {
    if (answer) {
      deleteCurrentItem();
      onClose();
    }
    setIsConfirmationOpen(false);
  };

  const deleteCurrentItem = () => {
    if (!!eventData.id && eventData.id !== -1) {
      // delete reminder
      dispatch(calendarActions.deleteReminder(eventData.id));
      onClose();
      cleanupForm();
    }
  };

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
    if (eventData.id !== -1) {
      setTitle(eventData.title);
      setNotes(eventData.notes);
      setFiles(eventData.fileRefs);
      setEmails(eventData.emails);
      setStartDate(
        eventData.startDate instanceof Date
          ? format(eventData.startDate, "yyyy-MM-dd")
          : eventData.startDate
      );
      setRepeatInterval(eventData.repeatInterval);
    }
  }, [isOpen, open, eventData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={[classes.root, "eventDialog"]}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title" disableTypography >
        <span className="dialog-title">
          {!!eventData.id && eventData.id !== -1 ? "Update" : "Add"} Reminder
        </span>
      </DialogTitle>
      <DialogContent>
        <form noValidate>
          <div className="title__container">
            <label className="title__textfield">TITLE</label>
          </div>
          <Box>
            <TextField
              id="event-title"
              variant="outlined"
              value={title}
              onChange={handleTitleChange}
            />
          </Box>
          <Box>
            <div className="title__container">
              <label className="title__textfield">NOTES</label>
            </div>
            <TextField
              id="event-notes"
              multiline
              rows={2}
              variant="outlined"
              value={notes}
              onChange={handleNotesChange}
            />
          </Box>
          <Grid xs={12} className="two__columns" item={true}>
            <Grid xs={6} className="first__column" item={true}>
              <Box>
                <div className="title__container">
                  <label className="title__textfield">SCHEDULE DATE</label>
                </div>
                <TextField
                  id="date"
                  type="date"
                  variant="outlined"
                  value={startDate}
                  onChange={handleStartDateChange}
                  className={classes.textField}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Box>
            </Grid>
            <Grid xs={6} className="second__column" item={true}>
              <div className="title__container">
                <label className="title__textfield">REPEAT</label>
              </div>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={repeatInterval}
                  onChange={handleRepeatIntervalChange}
                >
                  <MenuItem value="once">
                    <em>Once</em>
                  </MenuItem>
                  <MenuItem value="weekly">Weekly</MenuItem>
                  <MenuItem value="monthly">Monthly</MenuItem>
                  <MenuItem value="annually">Annually</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Box>
            <div className="title__container">
              <label className="title__textfield">ADD PEOPLE</label>
            </div>
            <UserList
              users={users}
              currentEmails={emails}
              onChange={onUsersChange}
            />
          </Box>
        </form>
        <Box>
          <div className="title__container">
            <label className="title__textfield">ASSIGN FILES</label>
          </div>
          <NewFileSelector
            fileList={files}
            currentFiles={files}
            onFileListChange={handleFileListChange}
            />
          {/*<FileSelector
            title="Assign a file to the reminder"
            subtitle="Assigned files:"
            fileList={files}
            onFileListChange={handleFileListChange}
          />*/}
        </Box>
      </DialogContent>
      <DialogActions>
        {/*<Button className="danger" onClick={onDeleteEvent}>
          Delete
        </Button>*/}
        <Button onClick={onClose}  className="button__cancel">
          Cancel
        </Button>
        <Button className="default__accept" onClick={onSubmitEvent}>
          {!!eventData.id && eventData.id !== -1
            ? "Update"
            : "Add"}
        </Button>
      </DialogActions>
      <ConfirmationPopUp
        isOpen={isConfirmationOpen}
        icon={<DeleteForeverIcon />}
        title="Delete confirmation"
        message={`Are you sure you want to delete this reminder: ${eventData.title}`}
        onClose={handleDeleteConfirmation}
      />
    </Dialog>
  );
};

ReminderForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  eventData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    notes: PropTypes.string,
    startDate: PropTypes.object,
    repeatInterval: PropTypes.string,
    fileRefs: PropTypes.array,
    emails: PropTypes.array,
  }),
};

ReminderForm.defaultProps = {
  isOpen: false,
  eventData: {
    id: -1,
    title: "",
    notes: "",
    startDate: format(new Date(), "yyyy-MM-dd"),
    repeatInterval: "",
    fileRefs: [],
    emails: [],
  },
};

export default ReminderForm;
