import React, { useState } from "react";
//import { ReactComponent as FrameSVG } from "../assets/Profile_FotoMenu.svg";

import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import {
  Container,
  Grid,
  MenuList,
  MenuItem,
  Typography,
} from "@material-ui/core";
import { authActions } from "../redux/actions/auth-actions";
import UserForm from "./dialogs/UserForm";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    svg: {
      maxWidth: 100,
    },
    menu: {
      marginTop: 5,
      borderTop: "solid 1px #FFF",
      padding: 0,
    },
    item: {
      borderBottom: "solid 1px #FFF",
      fontSize: "1.147em",
      "&:hover": {
        color: "#31D055",
      },
    },
  })
);

const UserProfileCard = (props) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  // TODO allow only user role admin to edit profile
  const currentUser = useSelector((state) => state.authentication.user);
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);

  return (
    <React.Fragment>
      <Grid container>
        <Container>
          { //<FrameSVG className={classes.svg} /> 
          }
          <Typography>
            {!!currentUser &&
              `${currentUser.first_name} ${currentUser.last_name}`}
          </Typography>
        </Container>
        <Grid item xs={12}>
          <MenuList className={classes.menu}>
            {!!currentUser ? (
              <MenuItem
                className={classes.item}
                onClick={() => setIsUserFormOpen(true)}
              >
                Edit Profile
              </MenuItem>
            ) : null}
            <MenuItem
              className={classes.item}
              onClick={(e) => dispatch(authActions.logout())}
            >
              Logout
            </MenuItem>
          </MenuList>
        </Grid>
      </Grid>
      <UserForm
        isOpen={isUserFormOpen}
        onClose={() => setIsUserFormOpen(false)}
        classes={classes}
        userData={currentUser}
      />
    </React.Fragment>
  );
};

export default UserProfileCard;
