import React, { useState, useEffect, forwardRef} from "react";
import PropTypes from "prop-types";
import {useDispatch, useSelector} from "react-redux";
import {
  Button,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Container,
  Typography,
  IconButton, TablePagination, Toolbar, SvgIcon
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import MaterialTable from "material-table";
import { userActions } from "../../redux/actions/user-actions";
import { userConstants } from "../../redux/constants/user-constants";
import { notify } from "../toastMessage";

// Table necessary icons
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import Create from '@material-ui/icons/Create';
import Delete from '@material-ui/icons/Delete';
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import button from "../../assets/dialog_btn1.svg";
import ConfirmationPopUp from "./ConfirmationPopUp";
import styles from './styles';
import clsx from "clsx";
import {ReactComponent as TrashSVG} from "../../assets/icons/icon_trash.svg";
import {ReactComponent as EditSVG} from "../../assets/icons/icon_edit.svg";

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />)
};


const ownStyles = makeStyles((theme) => ({
  mainBG: {
    backgroundColor: '#FFF',
    margin: 10,
  },
  btnAddUser: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 10
  },
  root: {
    "& .MuiDialogActions-root": {
      padding: '8px 32px !important'
    },
    "& .MuiDialog-paper": {
      width: 768,
      "& .MuiToolbar-root": {
        paddingLeft: 0,
        paddingRight: 0,
        "& .MuiFormControl-root": {
          paddingLeft: 0
        }
      },
      "& .MuiTable-root ": {
        "& .MuiTableBody-root": {
          "& :not(:last-child)": {
            "& td": {
              borderBottom: '1px solid #000'
            }
          }
        }
      },
      "& .MuiPaper-root": {
        boxShadow: 'none',
        "& > div:nth-child(2)": {
          border: '1px solid',
          borderRadius: '4px',
          "& > div:nth-child(1)": {
            display: 'none'
          },
        },
      },
      "& .MuiTablePagination-toolbar": {
        "& .MuiTypography-caption": {
          fontSize: 14
        }
      },
      "& .MuiTablePagination-select": {
        border: '1px solid',
        borderRadius: '16px'
      },
      "& .MuiTablePagination-selectIcon": {
        marginRight: 5
      }
    }
  }
  /*root: {
    fontFamily: "Montserrat-Regular",
    "& .MuiDialog-paper": {
      minWidth: 390,
    },
    "& .MuiDialogTitle-root": {
      color: "#FFF",
      backgroundColor: "#545354",
      textAlign: "center",
      borderBottom: "solid 3px #31D055",
      "& .dialog-title": {
        fontSize: 17
      },
      "& .MuiIconButton-root": {
        position: 'absolute',
        right: 0,
        top: 4,
        color: '#31D055'
      }
    },
    "& .MuiDialogContent-root": {
      backgroundColor: "#1A1A1A",
      overflow: "unset",
      "& .MuiFormControl-root": {
        minWidth: "100%",
      },
      "& .MuiTypography-root": {
        color: "rgba(0, 0, 0, 0.87)",
        "& .highlight__name": {
          color: "#31D055",
          fontWeight: "bold",
        },
      },
      "& .MuiFormLabel-root": {
        color: "#31D055",
      },
      "& .MuiInputBase-root": {
        color: "rgba(0, 0, 0, 0.87)",
        backgroundColor: "#FFF",
        "&:focus": {
          border: "none",
        },
      },
      "& .MuiInputBase-formControl": {
        margin: "1em 0",
      },
      "& .MuiInputBase-multiline": {
        height: 90,
        width: 343,
        paddingRight: 1,
      },
      "& .MuiButton-root": {
        color: "#1A1A1A",
        backgroundColor: "#31D055",
        borderRadius: 20,
      },
      "& .searchBar": {
        display: "flex",
        margin: "1em 0",
        "& .MuiInputBase-root": {
          width: "100%",
          paddingLeft: 1,
        },
      },
      "& .MuiIconButton-root": {
        color: "#FFFFFF",
        backgroundColor: "#31D055",
        borderRadius: 0,
        padding: "4px",
        marginLeft: "3px",
      },
      "& .MuiList-root.searchResults": {
        width: "100%",
        borderRadius: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#31D055",
      },
      "& .MuiList-root": {
        borderRadius: 0,
        backgroundColor: "#DDDDDD",
        border: "1px solid #31D055",
        "&.MuiList-padding": {
          padding: 0,
        },
      },
    },
    "& .MuiDialogActions-root": {
      color: "#FFF",
      backgroundColor: "#1A1A1A",
      "& .MuiButton-root": {
        display: "flex",
        color: "white",
        float: "right",
        padding: "18px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "16px",
        background: `transparent url(${button}) center center no-repeat`,
        border: "none",
        "&:hover": {
          backgroundColor: "transparent",
          border: "none",
        },
        "&.danger": {
          borderBottom: "1px dashed red",
          marginRight: "1em",
        },
      },
    },
    "& .MuiAutocomplete-root": {
      width: "100%",
    },
    "& .MuiList-root": {
      marginTop: 10,
      marginBottom: 10,
      borderRadius: 4,
      maxHeight: 100,
      minHeight: 30,
      overflow: "auto",
      "& .MuiTypography-root": {
        color: "#31D055 !important",
      },
    },
    "& .container": {
      padding: "1em",
    },
    "&.calendarDialog .MuiDialog-paper": {
      width: "96%",
    },
    "&.eventDialog .MuiDialog-paper": {
      minWidth: "60%",
      width: 360,
      "& .MuiInputBase-multiline": {
        width: "100%",
      },
    },
    "& .event": {
      position: "relative",
      width: "100%",
      borderRadius: "5px",
      backgroundColor: "#31D055",
      color: "#FFFFFF",
      fontWeight: "bold",
      display: "block",
      textAlign: "center",
    },
    "&.userDialog .MuiDialog-paper": {
      minWidth: "60%",
      width: 360,
      "& .pic-frame": {
        clipPath: "url(#clipping)",
        width: "120px",
      },
    },
  },*/
}));

const UserListDialog = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, userData, callbackOpenNewUser, callbackOpenEditUser, classes} = props;
  const currentUser = useSelector((state) => state.authentication.user);
  const users = useSelector((state) => state.user.users);
  const status = useSelector((state) => state.user.status);
  const listClasses = ownStyles();
  const [open, setOpen] = useState(isOpen);

  const [usersList, setUsersList] = useState([]);
  const [deleteConfirmation, setDeleteConfirmation] = useState({isOpen: false, user: null, message: ''});

  const [state, setState] = React.useState({
    columns: [
      { title: 'Name', field: 'full_name', cellStyle: {fontSize: 14}, width: '50%'},
      { title: 'Email', field: 'email', cellStyle: {fontSize: 14, textAlign: 'right'}},
      { title: 'Role', field: 'role',
        cellStyle: {fontSize: 14},
        render: rowData => (
          <div
            style={{ border: '1px solid #000000',
              boxSizing: 'border-box',
              borderRadius: '4px',
              textAlign: 'center',
              padding: '0px 10px'
            }}
          >
            {rowData.role}
          </div>
        ),
      }
    ],
    data: [],
    actions: [
      {
        icon: () =>     <SvgIcon
          className="share"
          component={TrashSVG}
        />,
        tooltip: 'Edit User',
        onClick: (event, rowData) => {
          callbackOpenEditUser(rowData);
        }
      },
      rowData => ({
              icon: () =>  <SvgIcon
                className="share"
                component={TrashSVG}
              />,
              tooltip: 'Delete User, a deleted user cannot be recovered!',
              onClick: (event, rowData) => {
                handleShowHideConfirmation(rowData)
              },
              hidden: rowData.id === (currentUser && currentUser.id)
            })
    ]
  });

  const onClickAddUser = () => {
    callbackOpenNewUser();
  };

  const handleShowHideConfirmation = (user) => {
    setDeleteConfirmation({isOpen: true, user, message: `Are you sure you want to delete the user ${user.full_name}?`});
  }

  const handleDeleteConfirmation = (answer = false) => {
    if (answer) {
      dispatch(userActions.deleteUser(deleteConfirmation.user.id));
    }
    setDeleteConfirmation({isOpen: false, user: null, message: ''});
  }

  useEffect(() => {
    setUsersList(users);
  }, [users]);

  useEffect(() => {
    setState({...state, actions: [
      {
        icon: () =>
          <SvgIcon
            component={EditSVG}
            viewBox={"0 0 24 24"}
          />,
        tooltip: 'Edit User',
        onClick: (event, rowData) => {
          callbackOpenEditUser(rowData);
        }
      },
      rowData => ({
              icon: () =>
                <SvgIcon
                  className="share"
                  component={TrashSVG}
                  viewBox={"0 0 48 49"}
                />,
              tooltip: 'Delete User, a deleted user cannot be recovered!',
              onClick: (event, rowData) => {
                handleShowHideConfirmation(rowData)
              },
              hidden: rowData.id === (currentUser && currentUser.id)
            })
    ]})
  }, [currentUser]);

  useEffect(() => {
    if (isOpen !== open) {
      if (isOpen) {
        dispatch(userActions.usersRequest());
      }
      setOpen(isOpen);
    }
  }, [isOpen, open, userData, dispatch]);

  useEffect(() => {
    switch (status) {
      case userConstants.USER_DELETE_SUCCESS:
        notify('User deleted.', 'success');
        dispatch(userActions.usersRequest());
        break;
      case userConstants.USER_DELETE_FAILED:
        notify('Delete user error', 'error');
        break;
      default:
        break;
    }
  }, [status]);

  return (<>
    <ConfirmationPopUp
      isOpen={deleteConfirmation.isOpen}
      icon={<DeleteForeverIcon />}
      title="Delete confirmation"
      message={deleteConfirmation.message}
      onClose={handleDeleteConfirmation}
    />
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={clsx(listClasses.root, classes.root)}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title" disableTypography >
        <span className="dialog-title">Manage Users</span>
      </DialogTitle>
      <DialogContent>
        <div className={listClasses.mainBG}>
          <MaterialTable
            icons={tableIcons}
            title="Usuarios"
            columns={state.columns}
            data={usersList}
            actions={state.actions}
            className={listClasses.btnAddUser}
            options={{
              doubleHorizontalScroll: true,
              actionsColumnIndex: -1,
              headerStyle:{
                backgroundColor: 'red',
                fontSize: 14,
                display: 'none'
              },
              searchFieldVariant: "outlined",
              maxBodyHeight: 300,
              rowStyle: {
              }
            }}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className="button__cancel" >
          Cancel
        </Button>
        <Button onClick={() => onClickAddUser()}  className="button__cancel">
          ADD USER
        </Button>
      </DialogActions>
    </Dialog>
  </>
  );
};

UserListDialog.propTypes = {
  isOpen: PropTypes.bool,
  callbackOpenNewUser: PropTypes.func,
  callbackOpenEditUser: PropTypes.func,
};

UserListDialog.defaultProps = {
  isOpen: false
};

export default withStyles(styles)(UserListDialog);
