import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import {createStyles, makeStyles, withStyles} from "@material-ui/core/styles";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import {
  IconButton,
  Typography,
  GridList,
  GridListTile,
  Box,
  Tooltip,
  ListItemIcon,
  MenuItem,
  Menu, SvgIcon
} from "@material-ui/core";

import GetAppIcon from "@material-ui/icons/GetApp";
import ShareIcon from "@material-ui/icons/Share";
import LinkIcon from "@material-ui/icons/Link";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from "@material-ui/icons/Delete";
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AFileIcon, AFolderIcon } from "../../components/Icons";

import styles from "./styles";
import { folderActions } from "../../redux/actions/folder-actions";
import { fileActions } from "../../redux/actions/file-actions";
import { userActions } from "../../redux/actions/user-actions";
import ShareFileDialog from "../../components/dialogs/ShareFile";
import EditFolderDialog from "../../components/dialogs/EditFolder";
import EditFileDialog from "../../components/dialogs/EditFile";
import FileLinkingDialog from "../../components/dialogs/FileLinking";
import PdfDialog from "../../components/dialogs/PdfDialog";
import ConfirmationPopUp from "../../components/dialogs/ConfirmationPopUp";
import MoveFileDialog from "../../components/dialogs/MoveFile";
import MoveFolderDialog from "../../components/dialogs/MoveFolder";
import MySharedItem from "../../components/dialogs/MySharedItem";
import { fileConstants } from "../../redux/constants/file-constants";
import { ReactComponent as FolderSVG } from "../../assets/icons/folder_icon.svg";
import { ReactComponent as TrashSVG } from "../../assets/icons/icon_trash.svg";
import { ReactComponent as ShareSVG } from "../../assets/icons/icon_share_small.svg";
import { ReactComponent as EditSVG } from "../../assets/icons/icon_edit.svg";
import { ReactComponent as MoveSVG } from "../../assets/icons/icon_move.svg";
import { ReactComponent as LinkSVG } from "../../assets/icons/icon_link.svg";
import { ReactComponent as FileSVG } from "../../assets/icons/file_icon.svg";
import { ReactComponent as OptionsSVG } from "../../assets/icons/icon_options.svg";
import { ReactComponent as DownloadSVG } from "../../assets/icons/icon_download.svg";
import clsx from "clsx";
import FirstLevelFolder from "./FirstLevelFolder";
import FilePreview from "./FilePreview";
import ShareFolderDialog from "../../components/dialogs/ShareFolder";

const useStyles = makeStyles((theme) =>
  createStyles({
    folderGridMain: {
      marginTop: theme.spacing(3)
    },
    rootMainContainer: {
      marginTop: 28,
      maxHeight: '100vh',
      overflow: 'scroll'
    },
    firstLevelRoot: {
      display: 'flex',
      justifyContent: 'center'
    },
    gridMainContainer: {
      display: 'flex',
      flexFlow: 'row wrap',
      maxWidth: '1075px',
      overflow: 'auto',
      paddingBottom: '120px',
      "@media (max-width: 1390px)": {
        maxWidth: '700px'
      },
      "@media (max-width: 1050px)": {
        maxWidth: '350px'
      }
    },
    oneColumn: {
      gridTemplateColumns: '320px'
    },
    twoColumns: {
      gridTemplateColumns: '320px 320px'
    },
    threeColumns: {
      gridTemplateColumns: '320px 320px 320px'
    },
    rootFolder: {
      display: "flex",
      marginTop: '0px',
      marginRight: 'auto',
      marginBottom: '16px',
      marginLeft: 'auto',
      background: "#FFFFFF",
      border: "4px solid #C9C9C9",
      boxSizing: "border-box",
      borderRadius: "4px",
      maxWidth: "300px"
    },
    rootFolderLink: {
      padding: "12px 16px 16px",
      display: "flex",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: '24px',
      lineHeight: "28px",
      textDecoration: "none",
      color: "#000",
      width: "100%",
      alignItems: 'center'
    },
    rootFolderLinkText: {
      width: "100%",
      textAlign: "center",
      margin: 0
    },
    showActionsFile: {
      position: 'absolute',
      top: '3px',
      left: '6px',
      cursor: 'pointer'
    },
    showActionsFolder: {
      position: 'absolute',
      top: '7px',
      left: '-3px',
      cursor: 'pointer'
    },
    showCursorPointer: {
      cursor: 'pointer'
    },
    folderIcon: {
      width: '144px !important',
      height: '144px !important'
    },
    fileContainer: {
      marginTop: '15px',
      wordBreak: 'break-word',
    },
    fileIcon: {
      width: '144px !important',
      height: '144px !important',
      fill: '#000 !important',
      marginBottom: 10
    },
    folderSubIcon: {
      width: '24px !important',
      height: '24px !important'
    },
    gridActions: {
      display: 'grid',
      gridTemplateColumns: '25px 25px',
      gridRow: 'auto auto',
      gridColumnGap: '8px',
      gridRowGap: '0px',
      position: 'absolute',
      marginTop: 45
    },
    gridActionsFile: {
      gridTemplateColumns: "repeat(3, 1fr)"
    },
    blackIcon: {
      fill: '#000 !important'
    },
    filename: {
      position: 'absolute',
      height: '100%',
      top: '75px',
      left: '50%',
      width: '100%',
      textAlign: 'center',
      '-webkit-transform': 'translate(-50%,-50%)',
      transform: 'translate(-50%,-50%)',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineHeight: '16px',
      maxHeight: '32px',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      paddingLeft: '14px',
      paddingRight: '14px',
      fontSize: '16px',
      fontWeight: 'bold'
    },
    folderName: {
      position: 'absolute',
      height: '100%',
      top: '80px',
      left: '50%',
      width: '100%',
      textAlign: 'center',
      '-webkit-transform': 'translate(-50%,-50%)',
      transform: 'translate(-50%,-50%)',
      wordBreak: 'break-word',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: '-webkit-box',
      lineHeight: '16px',
      maxHeight: '32px',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      paddingLeft: '14px',
      paddingRight: '14px',
      fontSize: '16px',
      fontWeight: 'bold'
    }
  })
);

const FolderItem = ({ inSharedItems, inTrash, inShared,
                      componentClasses, subFolder, index, currentUser,
                      handleEditFolder, handleManageSharedFolder, requestFolderMove, requestFolderShared,
                      handleDeleteFolder, openOptions }) => {

  const [showAction, setShowAction] = useState(false);

  return (
    <div>
      <div key={`file-${subFolder.id}-${index}`} className="folder">
        <Link disabled={!!inSharedItems} to={`${subFolder.id}`}>
          <SvgIcon
            className={componentClasses.folderIcon}
            component={FolderSVG}
            viewBox={"0 0 144 144"}
          />
          {!showAction && <div className={componentClasses.folderName}>{!!subFolder && subFolder.name}</div>}
        </Link>
        <Box>
          <Link disabled={!!inSharedItems} to={`${subFolder.id}`}>{subFolder.name}</Link>
        </Box>
        { showAction &&
          <Box className={componentClasses.gridActions}>
          {!inSharedItems && !inTrash && (
            <Tooltip title="Edit">
              <IconButton
                edge="end"
                aria-label="share"
                size="small"
                onClick={() => handleEditFolder(subFolder)}
              >
                <SvgIcon
                  component={EditSVG}
                  viewBox={"0 0 24 24"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inShared && !inTrash && (
            <Tooltip title="Share">
              <IconButton
                edge="end"
                aria-label="edit"
                size="small"
                onClick={() => requestFolderShared(subFolder)}
              >
                <SvgIcon
                  component={ShareSVG}
                  viewBox={"0 0 24 24"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inSharedItems && !inTrash && subFolder.owner.id === currentUser.id && (
            <Tooltip title="Move">
              <IconButton
                edge="end"
                aria-label="Move"
                size="small"
                onClick={() => requestFolderMove(subFolder)}
              >
                <SvgIcon
                  component={MoveSVG}
                  viewBox={"0 0 24 24"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inSharedItems && subFolder.owner.id === currentUser.id &&
          (
            <Tooltip title="Delete">
              <IconButton
                edge="end"
                aria-label="delete"
                size="small"
                onClick={() => handleDeleteFolder(subFolder)}
              >
                <SvgIcon
                  component={TrashSVG}
                  viewBox={"0 0 50 50"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inSharedItems && !!inTrash && subFolder.owner.id === currentUser.id && (
            <Tooltip title="Restore">
              <IconButton
                edge="end"
                aria-label="Restore"
                size="small"
                onClick={() => requestFolderMove(subFolder)}
              >
                <SvgIcon
                  component={SettingsBackupRestoreIcon}
                  className={clsx(componentClasses.folderSubIcon, componentClasses.blackIcon)}
                />
              </IconButton>
            </Tooltip>
          )}
          {!!inSharedItems && (
            <Tooltip title="Manage Share">
              <IconButton
                edge="end"
                aria-label="edit"
                size="small"
                onClick={() => handleManageSharedFolder(subFolder)}
              >
                <ShareIcon
                  className={clsx(componentClasses.blackIcon)}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>
        }
        <div className={componentClasses.showActionsFolder}>
          <Tooltip title="Show Actions">
            <IconButton
              edge="end"
              aria-label="Show Actions"
              size="small"
              onClick={(event) => {
                setShowAction(!showAction)
              }}
            >
              <SvgIcon
                className={componentClasses.showCursorPointer}
                component={OptionsSVG}
                viewBox={"0 0 24 24"}
              />
            </IconButton>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

const FileItem = ({ componentClasses, currentUser, inSharedItems, inShared, inTrash,
                    file, index, onRequestPreview, fileClassName,
                    openOptions,
                    handleEditFile, requestFileShare, requestDownload, requestFileMove, requestFileLinking,
                    handleDeleteFile, handleManageSharedFile
                  }) => {
  const [showAction, setShowAction] = useState(false);

  const renderFile = (file) => {
    return (
      <div>
        <SvgIcon
          className={componentClasses.fileIcon}
          component={FileSVG}
          viewBox={"0 0 144 144"}
        />
        {!showAction && <div className={componentClasses.filename}>{!!file && file.name}</div>}
      </div>
    )
  }

  return (
    <div className={componentClasses.fileContainer}>
      <div key={`file-${file.id}-${index}`} className="files">
        <div onClick={(e) => onRequestPreview(file)} className={fileClassName(file)}>
          {!!fileClassName(file) ?
            (
              <Tooltip title="Click to open" placement="top">
                {renderFile(file)}
              </Tooltip>
            ) : (renderFile(file))
          }
        </div>
        <Box>
          <Link disabled={!!inSharedItems} to={`${file.id}`}>{file.name}</Link>
        </Box>
        <div className={componentClasses.showActionsFile}>
          <Tooltip title="Show Actions">
            <IconButton
              edge="end"
              aria-label="Show Actions"
              size="small"
              onClick={(event) => {
                setShowAction(!showAction);
              }}
            >
              <SvgIcon
                className={componentClasses.showCursorPointer}
                component={OptionsSVG}
                viewBox={"0 0 24 24"}
              />
            </IconButton>
          </Tooltip>
        </div>
        {showAction && <Box className={
          clsx(componentClasses.gridActions, componentClasses.gridActionsFile)
        }>
          { !inSharedItems && inShared && (
            <Tooltip title="Edit">
              <IconButton
                edge="end"
                aria-label="edit"
                size="small"
                onClick={() => handleEditFile(file)}
              >
                <SvgIcon
                  component={EditSVG}
                  viewBox={"0 0 24 24"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inSharedItems && !inTrash && (
            <Tooltip title="Share">
              <IconButton
                edge="end"
                aria-label="share"
                size="small"
                onClick={() => requestFileShare(file)}
              >
                <SvgIcon
                  component={ShareSVG}
                  viewBox={"0 0 24 24"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inSharedItems && (
            <Tooltip title="Download">
              <IconButton
                edge="end"
                aria-label="Download"
                size="small"
                onClick={() => requestDownload(file)}
              >
                <SvgIcon
                  component={DownloadSVG}
                  viewBox={"0 0 24 24"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inSharedItems && !inTrash && !inShared &&  (
            <Tooltip title="Move">
              <IconButton
                edge="end"
                aria-label="Move"
                size="small"
                onClick={() => requestFileMove(file)}
              >
                <SvgIcon
                  component={MoveSVG}
                  viewBox={"0 0 24 24"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inSharedItems && !inTrash && (
            <Tooltip title="Link">
              <IconButton
                edge="end"
                aria-label="link"
                size="small"
                onClick={() => requestFileLinking(file)}
              >
                <SvgIcon
                  component={LinkSVG}
                  viewBox={"0 0 24 24"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inSharedItems && !inTrash && !inShared && (
            <Tooltip title="Edit">
              <IconButton
                edge="end"
                aria-label="Edit"
                size="small"
                onClick={() => handleEditFile(file)}
              >
                <SvgIcon
                  component={EditSVG}
                  viewBox={"0 0 24 24"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inSharedItems && !inShared && (
            <Tooltip title="Delete">
              <IconButton
                edge="end"
                aria-label="Delete"
                size="small"
                onClick={() => handleDeleteFile(file)}
              >
                <SvgIcon
                  component={TrashSVG}
                  viewBox={"0 0 50 50"}
                  className={componentClasses.folderSubIcon}
                />
              </IconButton>
            </Tooltip>
          )}
          {!inSharedItems && !!inTrash && file.owner.id === currentUser.id && (
            <Tooltip title="Restore">
              <IconButton
                edge="end"
                aria-label="Restore"
                size="small"
                onClick={() => requestFileMove(file)}
              >
                <SvgIcon
                  component={SettingsBackupRestoreIcon}
                  className={clsx(componentClasses.folderSubIcon, componentClasses.blackIcon)}
                />
              </IconButton>
            </Tooltip>
          )}
          {!!inSharedItems && (
            <Tooltip title="Manage Share">
              <IconButton
                edge="end"
                aria-label="share"
                size="small"
                onClick={() => handleManageSharedFile(file)}
              >
                <ShareIcon
                  className={clsx(componentClasses.blackIcon)}
                />
              </IconButton>
            </Tooltip>
          )}
        </Box>}
      </div>
    </div>
  )
}

function Folder(props) {
  const dispatch = useDispatch();
  const { classes, onRequestPreview } = props;
  let { id } = useParams();
  const currentFolder = useSelector((state) => state.folder.currentFolder);
  const currentUser = useSelector((state) => state.authentication.user);
  const users = useSelector((state) => state.user.users);
  const [currentFile, setCurrentFile] = useState(null);
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const [fileLinkingIsOpen, setFileLinkingIsOpen] = useState(false);
  const [tryGetUsers, setTryGetUsers] = useState(false);
  const [isPDFOpen, setIsPDFOpen] = React.useState(false);
  const { previewUrl, relatedPreviewUrl, status } = useSelector((state) => state.file);
  const [seletedFolder, setSeletedFolder] = useState(null);
  const [isEditFolderOpen, setIsEditFolderOpen] = useState(false);
  const [isEditFileOpen, setIsEditFileOpen] = useState(false);
  const [pdfRelatedFiles, setPdfRelatedFiles] = useState([]);
  const [isDeleteFileConfirmationOpen, setIsDeleteFileConfirmationOpen] = useState(false);
  const [isDeleteFolderConfirmationOpen, setIsDeleteFolderConfirmationOpen] = useState(false);
  const [inTrash, setInTrash] = useState(false);
  const [inShared, setInShared] = useState(false);
  const [isMoveFileOpen, setIsMoveFileOpen] = useState(false);
  const [isMoveFolderOpen, setIsMoveFolderOpen] = useState(false);
  const [isSharedFolderOpen, setIsSharedFolderOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [inSharedItems, setSharedItems] = useState(false);
  const [isSharedItemOpen, setIsSharedItemOpen] = useState(false);
  const [showActions, setShowActions] = useState(false);
  const componentClasses = useStyles();

  /**
   * Memoize this method with useCallback, so react will keep the same instance
   * of 'requestLoadFolder' across every render (avoid infinitive loops in useEffect),
   * it only re-creates the instance when the 'dispatch' reference dep changes
   * more info at https://dmitripavlutin.com/dont-overuse-react-usecallback/
   * */
  const requestLoadFolder = useCallback(
    (id) => {
      if (id.toString().toLowerCase() === "home") {
        dispatch(folderActions.requestHome());
      } else {
        dispatch(folderActions.requestFolder(id));
      }
    },
    [dispatch]
  );

  const requestDownload = (file) => {
    dispatch(fileActions.requestDownload(file));
  };

  const handelSelectPreviewRelated = (file) => {
    dispatch(fileActions.requestRelatedPreview(file));
  };

  const fileClassName = (file) => {
    return file.content_type.toLowerCase() === "application/pdf" ? classes.handCursor : '';
  }

  const requestPreview = (file) => {
    if (file.content_type.toLowerCase() === "application/pdf") {
      dispatch(fileActions.requestPreview(file));
    }
    setCurrentFile(file);
    setPdfRelatedFiles(file.related_aquila_files.filter(f => f.content_type.toLowerCase() === "application/pdf"));
  };

  const onCloseFilePreview = () => {
    setIsPDFOpen(false);
    setCurrentFile(null);
    setPdfRelatedFiles([]);
  };

  const requestFileShare = (file) => {
    setCurrentFile(file);
    setShareIsOpen(true);
  };

  const requestFileLinking = (file) => {
    setCurrentFile(file);
    setFileLinkingIsOpen(true);
  };

  const onCloseFileShare = () => {
    setShareIsOpen(false);
    setCurrentFile(null);
  };

  const onCloseFileLinking = () => {
    setFileLinkingIsOpen(false);
    setCurrentFile(null);
  };

  const handleEditFolder = (folder) => {
    setSeletedFolder(folder);
    setIsEditFolderOpen(true);
  }

  const handleCloseEditFolder = () => {
    setIsEditFolderOpen(false);
    setSeletedFolder(null);
  }

  const handleEditFile = (file) => {
    setCurrentFile(file);
    setIsEditFileOpen(true);
    setAnchorEl(null);
  }

  const handleCloseEditFile = () => {
    setIsEditFileOpen(false);
    setCurrentFile(null);
  }

  const handleDeleteFile = (file) => {
    setCurrentFile(file);
    setIsDeleteFileConfirmationOpen(true);
    setAnchorEl(null);
  }

  const handleDeleteFileConfirmation = (answer = false) => {
    if (answer) {
      dispatch(fileActions.deleteFile(currentFile));
    }
    setIsDeleteFileConfirmationOpen(false);
    setCurrentFile(null);
  };

  const handleDeleteFolder = (folder) => {
    setSeletedFolder(folder);
    setIsDeleteFolderConfirmationOpen(true);
  }

  const handleDeleteFolderConfirmation = (answer = false) => {
    if (answer) {
      dispatch(folderActions.deleteFolder(seletedFolder.id));
    }
    setIsDeleteFolderConfirmationOpen(false);
    setSeletedFolder(null);
  };

  const requestFileMove = (file) => {
    setCurrentFile(file);
    setIsMoveFileOpen(true);
    setAnchorEl(null);
  };

  const onCloseFileMove = () => {
    setIsMoveFileOpen(false);
    setCurrentFile(null);
  };

  const handleMoveFile = (file, moveToFolderId) => {
    dispatch(fileActions.moveFile(file, moveToFolderId));
  }

  const handleCloseContextualMenu = () => {
    setAnchorEl(null);
    setCurrentFile(null);
  }

  const handleOpenContextualMenu = (event, file) => {
    setAnchorEl(event.currentTarget);
    setCurrentFile(file);
  }

  const requestFolderMove = (folder) => {
    setSeletedFolder(folder);
    setIsMoveFolderOpen(true);
  };

  const requestFolderShare = (folder) => {
    setSeletedFolder(folder);
    setIsSharedFolderOpen(true);
  };

  const onCloseFolderShare = () => {
    setIsSharedFolderOpen(false);
    setSeletedFolder(null);
  };

  const onCloseFolderMove = () => {
    setIsMoveFolderOpen(false);
    setSeletedFolder(null);
  };

  const handleMoveFolder = (folder, moveToFolderId) => {
    dispatch(folderActions.moveFolder(folder, moveToFolderId));
  }

  const onCloseSharedItem = () => {
    setSeletedFolder(null);
    setCurrentFile(null);
    setIsSharedItemOpen(false);
  }

  const handleManageSharedFolder = (folder) => {
    setSeletedFolder(folder);
    setIsSharedItemOpen(true);
  }

  const handleManageSharedFile = (file) => {
    setCurrentFile(file);
    setIsSharedItemOpen(true);
  }

  const renderSubfolders2 = (subfolders) => {
    return subfolders.map((subfolder, index) => {
      return (
        <div>
          <div key={`file-${subfolder.id}-${index}`} className="folder">
            <Link disabled={!!inSharedItems} to={`${subfolder.id}`}>
              <SvgIcon
                className={componentClasses.folderIcon}
                component={FolderSVG}
                viewBox={"0 0 144 144"}
              />
              {!showActions && <div className={componentClasses.filename}>{!!subfolder && subfolder.name}</div>}
            </Link>
            <Box>
              <Link disabled={!!inSharedItems} to={`${subfolder.id}`}>{subfolder.name}</Link>
            </Box>
            <Box className={componentClasses.gridActions}>
              {!inSharedItems && !inTrash && (
                <Tooltip title="Edit">
                  <IconButton
                    edge="end"
                    aria-label="share"
                    size="small"
                    onClick={() => handleEditFolder(subfolder)}
                  >
                    <SvgIcon
                      component={EditSVG}
                      viewBox={"0 0 24 24"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!!inSharedItems && (
                <Tooltip title="Share">
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    size="small"
                    onClick={() => handleManageSharedFolder(subfolder)}
                  >
                    <SvgIcon
                      component={ShareSVG}
                      viewBox={"0 0 24 24"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && !inTrash && subfolder.owner.id === currentUser.id && (
                <Tooltip title="Move">
                  <IconButton
                    edge="end"
                    aria-label="Move"
                    size="small"
                    onClick={() => requestFolderMove(subfolder)}
                  >
                    <SvgIcon
                      component={MoveSVG}
                      viewBox={"0 0 24 24"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && subfolder.owner.id === currentUser.id &&
              (
                <Tooltip title="Delete">
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDeleteFolder(subfolder)}
                  >
                    <SvgIcon
                      component={TrashSVG}
                      viewBox={"0 0 50 50"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && !!inTrash && subfolder.owner.id === currentUser.id && (
                <Tooltip title="Restore">
                  <IconButton
                    edge="end"
                    aria-label="Restore"
                    size="small"
                    onClick={() => requestFolderMove(subfolder)}
                  >
                    <SvgIcon
                      component={SettingsBackupRestoreIcon}
                      className={clsx(componentClasses.folderSubIcon, componentClasses.blackIcon)}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!!inSharedItems && (
                <Tooltip title="Manage Share">
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    size="small"
                    onClick={() => handleManageSharedFolder(subfolder)}
                  >
                    <SvgIcon
                      component={ShareSVG}
                      viewBox={"0 0 50 50"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
            <div className={componentClasses.showActionsFile}>
              <Tooltip title="Show Actions">
                <IconButton
                  edge="end"
                  aria-label="Show Actions"
                  size="small"
                  onClick={(event) => {
                    setShowActions(!showActions)
                  }}
                >
                  <SvgIcon
                    className={componentClasses.showCursorPointer}
                    component={OptionsSVG}
                    viewBox={"0 0 24 24"}
                    onClick={openOptions}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          {/*<GridListTile key={`file-${subfolder.id}-${index}`} className="folder">
            <Link disabled={!!inSharedItems} to={`${subfolder.id}`}><AFolderIcon /></Link>
            <Box>
              <Link disabled={!!inSharedItems} to={`${subfolder.id}`}>{subfolder.name}</Link>
            </Box>
            <Box>
              {!inSharedItems && !inTrash && (
                <Tooltip title="Edit">
                  <IconButton
                    edge="end"
                    aria-label="share"
                    size="small"
                    onClick={() => handleEditFolder(subfolder)}
                  >
                    <EditIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && subfolder.owner.id === currentUser.id &&
              (
                <Tooltip title="Delete">
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    size="small"
                    onClick={() => handleDeleteFolder(subfolder)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && !inTrash && subfolder.owner.id === currentUser.id && (
                <Tooltip title="Move">
                  <IconButton
                    edge="end"
                    aria-label="Move"
                    size="small"
                    onClick={() => requestFolderMove(subfolder)}
                  >
                    <FolderOpenIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && !!inTrash && subfolder.owner.id === currentUser.id && (
                <Tooltip title="Restore">
                  <IconButton
                    edge="end"
                    aria-label="Restore"
                    size="small"
                    onClick={() => requestFolderMove(subfolder)}
                  >
                    <SettingsBackupRestoreIcon />
                  </IconButton>
                </Tooltip>
              )}
              {!!inSharedItems && (
                <Tooltip title="Manage Share">
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    size="small"
                    onClick={() => handleManageSharedFolder(subfolder)}
                  >
                    <ShareIcon />
                  </IconButton>
                </Tooltip>
              )}
            </Box>
          </GridListTile>*/}
        </div>
      )
    })
  };

  const openOptions = (event) => {
    event.preventDefault();
  };

  const renderFirstLevelFolders = (subfolders) => {
    //const subfolders = folders.length > 0 ? [...Array(55)].map((x, i) => {return folders[0]}) : [];
    //const subfoldersA = folders.length > 0 ? [...Array(25)].map((x, i) => {return folders[0]}) : [];
    const numberSubFolders = subfolders.length;

    let i,j, temporary, chunk = 8;
    let splitSubFolders = [];
    for (i = 0,j = subfolders.length; i < j; i += chunk) {
      splitSubFolders.push(subfolders.slice(i, i + chunk));
    }

    return(
      <div className={
        clsx( componentClasses.firstLevelRoot)
      }>
        <div className={
          clsx( componentClasses.rootMainContainer,
            componentClasses.gridMainContainer, componentClasses.threeColumns)
        }>
          {/*
            subfoldersA.map((subfolders, index) => {
              return <div key={index}>
                {
                  subfolders.map((subfolder, index) => {
                    return (
                      <FirstLevelFolder
                        key={index}
                        inSharedItems={inSharedItems}
                        subFolder={subfolder}
                        handleEditFolder={handleEditFolder}
                        handleDeleteFolder={handleDeleteFolder}
                        handleMoveFolder={requestFolderMove}
                      />
                    );
                  })
                }
              </div>
            })*/
          }

            {
              subfolders.map((subfolder, index) => {
                return (
                  <FirstLevelFolder
                    inSharedItems={inSharedItems}
                    subFolder={subfolder}
                    handleEditFolder={handleEditFolder}
                    handleDeleteFolder={handleDeleteFolder}
                    handleMoveFolder={requestFolderMove}
                  />
                );
              })
            }
        </div>
      </div>
    )
  };

  const renderFile = (file) => {
    return (
      <div>
        <SvgIcon
          className={componentClasses.fileIcon}
          component={FileSVG}
          viewBox={"0 0 144 144"}
        />
        {file && !file.showActions && <div className={componentClasses.filename}>{!!file && file.name}</div>}
      </div>
    )
  }

  const renderSubfolders = (subfolders) => {
    return subfolders.map((subfolder, index) => {
      return (
        <FolderItem
          key={index}
          inSharedItems={inSharedItems} inShared={inShared}
          inTrash={inTrash} componentClasses={componentClasses}
          subFolder={subfolder} index={index} currentUser={currentUser}
          handleEditFolder={handleEditFolder} handleManageSharedFolder={handleManageSharedFolder}
          requestFolderMove={requestFolderMove} handleDeleteFolder={handleDeleteFolder}
          openOptions={openOptions} requestFolderShared={requestFolderShare}
        ></FolderItem>
      )
    });
  };

  const renderFiles = (aquilaFiles) => {
    return aquilaFiles.map((file, index) => {
      return (
        <FileItem
          key={index}
          componentClasses={componentClasses}  currentUser={currentUser}
          inSharedItems={inSharedItems} inShared={inShared} inTrash={inTrash}
          file={file} index={index} onRequestPreview={onRequestPreview} fileClassName={fileClassName}
          openOptions={openOptions}  handleEditFile={handleEditFile}
          requestFileShare={requestFileShare} requestDownload={requestDownload}
          requestFileMove={requestFileMove} requestFileLinking={requestFileLinking}
          handleDeleteFile={handleDeleteFile} handleManageSharedFile={handleManageSharedFile}
        ></FileItem>
      )
    });
  };

  const renderFiles2 = (aquilaFiles) => {
    return aquilaFiles.map((file, index) => {
      return (
        <div>
          <div key={`file-${file.id}-${index}`} className="files">
            <div onDoubleClick={(e) => onRequestPreview(file)} className={fileClassName(file)}>
              {!!fileClassName(file) ?
                (
                  <Tooltip title="Double click to open" placement="top">
                    {renderFile(file)}
                  </Tooltip>
                ) : (renderFile(file))
              }
            </div>
            <Box>
              <Link disabled={!!inSharedItems} to={`${file.id}`}>{file.name}</Link>
            </Box>
            <div className={componentClasses.showActionsFile}>
              <Tooltip title="Show Actions">
                <IconButton
                  edge="end"
                  aria-label="Show Actions"
                  size="small"
                  onClick={(event) => {
                    //debugger;
                    aquilaFiles[index].showActions = true;
                    //setShowActions(!showActions)
                  }}
                >
                  <SvgIcon
                    className={componentClasses.showCursorPointer}
                    component={OptionsSVG}
                    viewBox={"0 0 24 24"}
                    onClick={openOptions}
                  />
                </IconButton>
              </Tooltip>
            </div>
            {file.showActions && <Box className={
              clsx(componentClasses.gridActions, componentClasses.gridActionsFile)
            }>
              { !inSharedItems && inShared && (
                <Tooltip title="Edit">
                  <IconButton
                    edge="end"
                    aria-label="edit"
                    size="small"
                    onClick={() => handleEditFile(file)}
                  >
                    <SvgIcon
                      component={EditSVG}
                      viewBox={"0 0 24 24"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && !inTrash && (
                <Tooltip title="Share">
                  <IconButton
                    edge="end"
                    aria-label="share"
                    size="small"
                    onClick={() => requestFileShare(file)}
                  >
                    <SvgIcon
                      component={ShareSVG}
                      viewBox={"0 0 24 24"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && (
                <Tooltip title="Download">
                  <IconButton
                    edge="end"
                    aria-label="Download"
                    size="small"
                    onClick={() => requestDownload(file)}
                  >
                    <SvgIcon
                      component={DownloadSVG}
                      viewBox={"0 0 24 24"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && !inTrash && !inShared &&  (
                <Tooltip title="Move">
                  <IconButton
                    edge="end"
                    aria-label="Move"
                    size="small"
                    onClick={() => requestFileMove(file)}
                  >
                    <SvgIcon
                      component={MoveSVG}
                      viewBox={"0 0 24 24"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && !inTrash && (
                <Tooltip title="Link">
                  <IconButton
                    edge="end"
                    aria-label="link"
                    size="small"
                    onClick={() => requestFileLinking(file)}
                  >
                    <SvgIcon
                      component={LinkSVG}
                      viewBox={"0 0 24 24"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && !inTrash && !inShared && (
                <Tooltip title="Edit">
                  <IconButton
                    edge="end"
                    aria-label="Edit"
                    size="small"
                    onClick={() => handleEditFile(file)}
                  >
                    <SvgIcon
                      component={EditSVG}
                      viewBox={"0 0 24 24"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && !inShared && (
                <Tooltip title="Delete">
                  <IconButton
                    edge="end"
                    aria-label="Delete"
                    size="small"
                    onClick={() => handleDeleteFile(file)}
                  >
                    <SvgIcon
                      component={TrashSVG}
                      viewBox={"0 0 50 50"}
                      className={componentClasses.folderSubIcon}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!inSharedItems && !!inTrash && file.owner.id === currentUser.id && (
                <Tooltip title="Restore">
                  <IconButton
                    edge="end"
                    aria-label="Restore"
                    size="small"
                    onClick={() => requestFileMove(file)}
                  >
                    <SvgIcon
                      component={SettingsBackupRestoreIcon}
                      className={clsx(componentClasses.folderSubIcon, componentClasses.blackIcon)}
                    />
                  </IconButton>
                </Tooltip>
              )}
              {!!inSharedItems && (
                <Tooltip title="Manage Share">
                  <IconButton
                    edge="end"
                    aria-label="share"
                    size="small"
                    onClick={() => handleManageSharedFile(file)}
                  >
                    <ShareIcon
                      className={clsx(componentClasses.blackIcon)}
                    />
                  </IconButton>
                </Tooltip>
              )}
            </Box>}
          </div>
        </div>
      )
    });
  };

  const renderFiles1 = (aquilaFiles) => {
    return aquilaFiles.map((file, index) => {
      return (
        <div key={`file-${file.id}-${index}`}>
          <div onDoubleClick={(e) => requestPreview(file)} className={fileClassName(file)}>
            {!!fileClassName(file) ?
              (
                <Tooltip title="Double click to open">
                  <span>{renderFile()}</span>
                </Tooltip>
              ) : (renderFile())
            }
          </div>
          <Box className={componentClasses.gridActions}>
            <Typography>{file.name}</Typography>
            
            {!inSharedItems && !inTrash && file.owner.id !== currentUser.id && (
              <Tooltip title="Edit">
                <IconButton
                  edge="end"
                  aria-label="edit"
                  size="small"
                  onClick={() => handleEditFile(file)}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {!inSharedItems && !inTrash && (
              <Tooltip title="Share">
                <IconButton
                  edge="end"
                  aria-label="share"
                  size="small"
                  onClick={() => requestFileShare(file)}
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
            )}
            {!inSharedItems && !inTrash && (
              <Tooltip title="Link">
                <IconButton
                  edge="end"
                  aria-label="link"
                  size="small"
                  onClick={() => requestFileLinking(file)}
                >
                  <LinkIcon />
                </IconButton>
              </Tooltip>
            )}
            {!inSharedItems && (
              <Tooltip title="Download">
                <IconButton
                  edge="end"
                  aria-label="download"
                  size="small"
                  onClick={() => requestDownload(file)}
                >
                  <GetAppIcon />
                </IconButton>
              </Tooltip>
            )}
            {!inSharedItems && !!inTrash && file.owner.id === currentUser.id && (
              <Tooltip title="Restore">
                <IconButton
                  edge="end"
                  aria-label="Restore"
                  size="small"
                  onClick={() => requestFileMove(file)}
                >
                  <SettingsBackupRestoreIcon />
                </IconButton>
              </Tooltip>
            )}
            {!inSharedItems && !!inTrash && file.owner.id === currentUser.id &&
            (
              <Tooltip title="Delete">
                <IconButton 
                  edge="end" 
                  aria-label="delete" 
                  size="small"
                  onClick={() => handleDeleteFile(file)}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            )}
            {!inSharedItems && !inTrash && file.owner.id === currentUser.id && (
              <Tooltip title="More">
                <IconButton
                  edge="end"
                  aria-label="More"
                  size="small"
                  onClick={(event) => handleOpenContextualMenu(event, file)}
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            )}
           {!!inSharedItems && (
              <Tooltip title="Manage Share">
                <IconButton
                  edge="end"
                  aria-label="share"
                  size="small"
                  onClick={() => handleManageSharedFile(file)}
                >
                  <ShareIcon />
                </IconButton>
              </Tooltip>
          )}
          </Box>
        </div>
      );
    });
  };

  const renderMenuOptions = () => {    
    const menuOptions = [
      {name: 'Edit', openFunc: handleEditFile, icon: (<EditIcon />)}, 
      {name: 'Move', openFunc: requestFileMove, icon: (<FolderOpenIcon />)}, 
      {name: 'Delete', openFunc: handleDeleteFile, icon: (<DeleteIcon />)}
    ];
    return menuOptions.map((option) => {
        return (
          <MenuItem key={option.name} onClick={() => option.openFunc(currentFile)}>
            <ListItemIcon>
              {option.icon}
            </ListItemIcon>
            <Typography variant="inherit">{option.name}</Typography>
          </MenuItem>
    )});
  }

  useEffect(() => {
    if (!!currentUser) {
      // Trash validation
      if (!!currentFolder && !!currentFolder.path && !!currentFolder.path[0]) {
        setInTrash(currentFolder.path[0].folder_type === 'trash');
      }
      setSharedItems(false);
      // Routing
      if (!currentFolder && !!id) {
        requestLoadFolder(id);
      } else if (currentFolder.id === "search" || currentFolder.id === "shared_items") {
        // do nothing when is under search results
        if (currentFolder.id === "shared_items") {
          setSharedItems(true);
        }
      } else if (
        id === "home" &&
        !!currentFolder.folder_type &&
        currentFolder.folder_type.toLowerCase() !== "home"
        ) {
        // Request home if the path id is home and the current folder is another
        requestLoadFolder(id);
      } else if (id === "shared" && !!currentFolder.folder_type &&
        currentFolder.folder_type.toLowerCase() !== "shared") {
        // Request folder if the path is shared and the current folder is another
        requestLoadFolder(id);
      } else if (!parseInt(id)) {
        // It avoid the recurrence when the id isn't a number
      } else if (parseInt(id) !== currentFolder.id) {
        // Request the new folder when change the url
        requestLoadFolder(id);
      }


      // Trash validation
      if (!!currentFolder && !!currentFolder.path && !!currentFolder.path[0]) {
        setInShared(currentFolder.path[0].folder_type === 'shared');
      }

    }
  }, [
    id,
    currentFolder,
    currentUser,
    requestLoadFolder,
  ]);


  useEffect(() => {
    if (!!currentUser) {
      if (!tryGetUsers) {
        setTryGetUsers(true);
        if (!users.length) {
          dispatch(userActions.usersRequest());
        }
      }
    }
  }, [
    currentUser,
    tryGetUsers,
    users,
    dispatch,
  ]);

  useEffect(() => {
    if (previewUrl !== "" && status === fileConstants.FILE_PREVIEW_SUCCESS) {
      setIsPDFOpen(true);
    }
  }, [previewUrl, status]);

  return (
    <div className={componentClasses.folderGridMain}>
      {(!!currentFolder && (currentFolder.parent_id || currentFolder.id === "search" ||
        currentFolder.id === "shared_items" || inTrash || inShared)  &&
        <>
        <div className={classes.folderGrid}>
          {!!currentFolder &&
          !!currentFolder.subfolders &&
          renderSubfolders(currentFolder.subfolders)}
        </div>
          {!!currentFolder &&
          !!currentFolder.aquila_files &&
          <div
            className={clsx(classes.folderGrid, classes.filesGrid)}
          >
            {renderFiles(currentFolder.aquila_files)}
          </div>
          }
        </>)

      }
      {
        ((!!currentFolder && !currentFolder.parent_id && !inTrash && !inShared) &&
        renderFirstLevelFolders(currentFolder.subfolders))
      }
      {
        /*isPDFOpen &&
        <FilePreview isOpen={isPDFOpen}
                     onClose={onCloseFilePreview}
                     previewUrl={previewUrl}
                     previewRelatedUrl={relatedPreviewUrl}
                     fileName={!!currentFile && currentFile.name}
                     relatedFiles={pdfRelatedFiles}
                     onSelectRelated={handelSelectPreviewRelated}
        />*/
      }
      <ShareFolderDialog
        isOpen={isSharedFolderOpen}
        onClose={onCloseFolderShare}
        currentFolder={seletedFolder}
      />
      <ShareFileDialog
        isOpen={shareIsOpen}
        onClose={onCloseFileShare}
        users={users}
        file={currentFile}
      />
      <FileLinkingDialog
        isOpen={fileLinkingIsOpen}
        onClose={onCloseFileLinking}
        file={currentFile}
      />
      {/*<PdfDialog
        isOpen={isPDFOpen}
        onClose={onCloseFilePreview}
        previewUrl={previewUrl}
        previewRelatedUrl={relatedPreviewUrl}
        fileName={!!currentFile && currentFile.name}
        relatedFiles={pdfRelatedFiles}
        onSelectRelated={handelSelectPreviewRelated}
      />*/}
      <EditFolderDialog 
        isOpen={isEditFolderOpen}
        onClose={handleCloseEditFolder}
        currentFolder={seletedFolder}
      />
      <EditFileDialog 
        isOpen={isEditFileOpen}
        onClose={handleCloseEditFile}
        currentFile={currentFile}
      />
      <ConfirmationPopUp 
        isOpen={isDeleteFileConfirmationOpen}
        icon={<DeleteIcon />}
        title="Delete confirmation"
        message={`Are you sure you want to delete this file: ${currentFile && currentFile.name}`}
        onClose={handleDeleteFileConfirmation}
      />
      <ConfirmationPopUp 
        isOpen={isDeleteFolderConfirmationOpen}
        icon={<DeleteIcon />}
        title="Delete confirmation"
        message={`Are you sure you want to delete this folder: ${seletedFolder && seletedFolder.name}`}
        onClose={handleDeleteFolderConfirmation}
      />
      <MoveFileDialog 
        isOpen={isMoveFileOpen}
        onClose={onCloseFileMove}
        folderSelection={handleMoveFile}
        isMoveAction={!inTrash}
        file={currentFile}
      />
      <MoveFolderDialog 
        isOpen={isMoveFolderOpen}
        onClose={onCloseFolderMove}
        folderSelection={handleMoveFolder}
        isMoveAction={!inTrash}
        folder={seletedFolder}
      />
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={!!anchorEl}
        onClose={handleCloseContextualMenu}
        
      >
        { !!anchorEl && renderMenuOptions() }
      </Menu>
      <MySharedItem
        isOpen={isSharedItemOpen}
        onClose={onCloseSharedItem}
        isFile={!!currentFile}
        shared={currentFile || seletedFolder}
      />
    </div>
  );
}

Folder.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  path: PropTypes.string,
  items: PropTypes.array,
  isLeave: PropTypes.bool,
  onSubFolderClicked: PropTypes.func,
  onRequestPreview: PropTypes.func
};

Folder.defaultProps = {
  items: [],
  isLeave: true,
  onSubFolderClicked: () => {},
};

export default withStyles(styles)(Folder);
