import axios from "axios";
import { get } from "lodash";
import { clearAuth, getAuthHeaders } from "./auth.helper";
import configureStore from '../redux/store/configureStore';
import { commonActions } from '../redux/actions/common-actions';

/**
 * Returns object with authentication header data.
 *
 * @param {boolean} noContentType Flag to add Content-Type=undefined header
 */
const getApiHeaders = (noContentType) => {
  let headers = getAuthHeaders();
  if (noContentType) {
    headers["Content-Type"] = undefined;
  }
  return headers;
};

/**
 * Set axios configuration.
 * @param {Boolean} useStringify
 */
const setAxiosConfig = (useStringify) => {
  axios.defaults.baseURL = process.env.REACT_APP_API_URL;
  axios.defaults.headers.common = getApiHeaders() || {};
  // axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

  if (useStringify) {
    // Transform data payload with stringify.
    axios.defaults.transformRequest = (obj) => JSON.stringify(obj);
  }
};

const API = (useStringify = false, signIn = false) => {
  setAxiosConfig(useStringify);

  const axiosInstance = axios.create();
  // axiosInstance.interceptors.request.use(() => {});

  axiosInstance.interceptors.response.use(
    (response) => response,
    (error) => {
      if (get(error, "response.status") === 401) {
        console.log("API Session ended");
        configureStore.dispatch(commonActions.reset());
        clearAuth();
        window.location.assign('/login');
      }
      return Promise.reject({
        data: get(error, "response.data"),
        status: get(error, "response.status"),
      });
    }
  );

  return axiosInstance;
};

export default API;
