import API from "./api.service";
import { isDate } from "date-fns";

// ---- Misc methods ----
const mapReminderPayload = (localReminder) => ({
  reminder: {
    id: localReminder.id,
    owner_id: localReminder.userId,
    title: localReminder.title,
    notes: localReminder.notes,
    start_date: localReminder.startDate,
    repeat_interval: localReminder.repeatInterval,
    emails: localReminder.emails,
    aquila_files_ids: localReminder.fileRefs,
  },
});

const mapReminderResponse = (serverReminder) => {
  // i.e. 2020-09-01
  const startDateParts =
    !!serverReminder.start_date && serverReminder.start_date.split("-");
  // adjust month number for Date obj
  const startDate =
    startDateParts.length > 2
      ? new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2])
      : null;
  return {
    id: serverReminder.id,
    userId: serverReminder.owner_id,
    title: serverReminder.title,
    notes: serverReminder.notes,
    startDate: startDate,
    repeatInterval: serverReminder.repeat_interval,
    emails: serverReminder.emails || [],
    fileRefs: serverReminder.aquila_files || [],
  };
};

export class CalendarService {
  static async getMonthReminders({ month, userId }) {
    try {
      const today = new Date();
      let monthParam =
        !!month && isDate(month) ? month.getMonth() : today.getMonth();
      // January is 0 so we make it 1
      monthParam = monthParam + 1;
      const yearParam =
        !!month && isDate(month) ? month.getFullYear() : today.getFullYear();
      const response = await API(false, false).get(
        `users/${userId}/reminders?by_month[year]=${yearParam}&by_month[month]=${monthParam}`,
        {
          data: {},
        }
      );
      if (response.status < 300) {
        return Array.isArray(response.data)
          ? response.data.map(mapReminderResponse)
          : [];
      } else {
        console.error(
          "Calendar service call getMonthReminders error:",
          response
        );
      }
    } catch (e) {
      return { status: e.status };
    }
  }
  static async deleteReminder({ id, userId }) {
    try {
      const response = await API(false, false).delete(
        `users/${userId}/reminders/${id}`,
        {
          data: {},
        }
      );
      return response.request;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async addReminder({ reminder, userId }) {
    try {
      const reminderPayload = mapReminderPayload(reminder);
      const response = await API(false, false).post(
        `users/${userId}/reminders`,
        reminderPayload,
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async updateReminder({ reminder, userId }) {
    try {
      const reminderPayload = mapReminderPayload(reminder);
      const response = await API(false, false).put(
        `users/${userId}/reminders/${reminder.id}`,
        reminderPayload,
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
}
export default CalendarService;
