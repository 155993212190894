import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import {makeStyles, withStyles} from "@material-ui/core/styles";

import styles from "./styles";

const insideStyles = makeStyles((theme) => ({
  title: {
    paddingTop: '6px !important'
  },
  content: {
    padding: '4px 24px !important'
  },
  actions: {
    marginBottom: '12px !important'
  },
}));

const ConfirmationPopUp = (props) => {
  const { isOpen, icon, title, message, onClose, classes } = props;
  const customClasses = insideStyles();
  const [open, setOpen] = useState(isOpen);

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen, open]);

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="form-dialog-title"
      className={classes.root}
    >
      <DialogTitle id="form-dialog-title" className={customClasses.title}>
        <span className="dialog-title">
          {!!title && title}
        </span>
      </DialogTitle>
      <DialogContent className={customClasses.content}>
        <DialogContentText className="dialog-message">{!!message && message}</DialogContentText>
      </DialogContent>
      <DialogActions className={customClasses.actions}>
        <Button onClick={() => onClose(false)} color="secondary" className="button__cancel">
          No
        </Button>
        <Button onClick={() => onClose(true)} className="default__accept">
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmationPopUp.propTypes = {
  isOpen: PropTypes.bool,
  icon: PropTypes.object,
  title: PropTypes.string,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired,
};

ConfirmationPopUp.defaultProps = {
  isOpen: false,
  icon: null,
  title: "Confirmation",
  message: "",
};

export default withStyles(styles)(ConfirmationPopUp);
