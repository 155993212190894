import button from "../../assets/dialog_btn1.svg";
import btnSendShare from "../../assets/icons/icon_share.svg";

const styles = (theme) => ({
  hasTags: {
    "& .MuiInputLabel-outlined": {
      display: 'none',
    },
    "& .MuiAutocomplete-tag": {
      marginRight: 5
    }
  },
  root: {
    fontFamily: "Karla",
    "& .MuiDialog-paper": {
      minWidth: 512,
      borderRadius: '16px'
    },
    "& .title__container": {
      width: "100%",
      display: 'flex',
      justifyContent: 'center',
      "& .title__textfield": {
        fontStyle: "normal",
        fontWeight: 'bold',
        fontSize: '9px',
        letterSpacing: '0.08em',
        color: '#000'
      }
    },
    "& .MuiInputLabel-outlined": {
      marginTop: 7,
      "&.Mui-focused, &.MuiFormLabel-filled": {
        display: 'none',
      }
    },
    "& .MuiDialogTitle-root": {
      color: "#000",
      backgroundColor: "#FFF",
      textAlign: "center",
      padding: "12px 24px 4px 24px",
      "& .dialog-title": {
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '24px',
        color: '#000000',
        '& span': {
          color: '#808080'
        }
      },
      "& .MuiIconButton-root": {
        position: 'absolute',
        right: 0,
        top: 4,
        color: '#000'
      }
    },
    "& .dialog-message": {
      fontSize: '14px',
      textAlign: 'center',
      marginBottom: '0px'
    },
    "& .MuiDialogContent-root": {
      backgroundColor: "#FFF",
      overflow: "unset",
      "& .MuiFormControl-root": {
        minWidth: "100%",
      },
      "& .MuiTypography-root": {
        color: "#000",
        "& .highlight__name": {
          color: "#000",
          fontWeight: "bold",
        },
      },
      "& .MuiFormLabel-root": {
        color: "#000",
      },
      "& .MuiInputBase-root": {
        color: "#000",
        backgroundColor: "#FFF",
        "&:focus": {
          border: "none",
        },
      },
      "& .MuiTreeItem-root.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label": {
        backgroundColor: "rgba(204, 204, 204, 0.08)"
      },
      "& .MuiTextField-root.last__textfield .MuiInputBase-formControl": {
        margin: "5px 0px 5px 0px",

      },
      "& .MuiTextField-root.single__line": {
        "& .MuiInputLabel-outlined": {
          fontStyle: 'italic',
          marginTop: '-3px'
        }
      },
      "& .MuiInputBase-formControl": {
        borderRadius: '16px',
        margin: "5px 0px 18px 0px",
      },
      '& .MuiOutlinedInput-input': {
        padding: '10.5px 14px'
      },
      "& .MuiInputBase-multiline": {
        height: 90,
        width: '100%',
        borderColor: "#000",
        borderRadius: '16px'
      },
      "& .MuiButton-root": {
        color: "#1FFF",
        backgroundColor: "#000",
        borderRadius: 20,
      },
      "& .searchBar": {
        display: "flex",
        margin: "1em 0",
        "& .MuiInputBase-root": {
          width: "100%",
          paddingLeft: 1,
        },
      },
      "& .MuiIconButton-root": {
        color: "#000",
        backgroundColor: "#FFFFFF",
        borderRadius: 0,
        padding: "4px",
        marginLeft: "3px",
        "&.icon__normal": {
          backgroundColor: '#FFF'
        }
      },
      "& .MuiList-root.searchResults": {
        width: "100%",
        borderRadius: 0,
      },
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000",
      },
      "& .MuiList-root": {
        borderRadius: 0,
        backgroundColor: "#FFF",
        border: "1px solid #000",
        "&.MuiList-padding": {
          padding: 0,
        },
      },
      "& .shared-list": {
        minHeight: 300,
        minWidth: 350
      }
    },
    "& .MuiDialogActions-root": {
      color: "#FFF",
      backgroundColor: "#FFF",
      flex: '1 1 auto',
      padding: '8px 24px',
      justifyContent: 'space-between',
      marginBottom: '20px',
      "& .extended__actions": {
        display: 'flex'
      },
      "& .MuiButton-root": {
        display: "flex",
        color: "white",
        float: "right",
        padding: "18px",
        textTransform: "none",
        fontWeight: "bold",
        fontSize: "16px",
        border: "none",
        "&:hover": {
          backgroundColor: "transparent",
          border: "none",
        },
        "&.danger": {
          borderBottom: "1px dashed red",
          marginRight: "1em",
        },
        "&.remove_left__margin": {
          marginLeft: -15
        },
        "&.calendar_right__margin": {
          marginRight: -7
        },
        "&.button__cancel": {
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '12px',
          padding: '0px 11px',
          color: '#000000',
          border: '4px solid #C9C9C9',
          boxSizing: 'border-box',
          borderRadius: '4px',
        },
        "&.button__change": {
          fontStyle: 'normal',
          fontWeight: 'bold',
          fontSize: '12px',
          padding: '0px 11px',
          color: '#000000'
        },
        "&.default__accept": {
          background: '#FFFFFF',
          border: '4px solid #C9C9C9',
          boxSizing: 'border-box',
          borderRadius: '4px',
          flex: 'none',
          order:'3',
          flexGrow: 0,
          color: "#000000",
          width: '95px',
          height: '32px',
          padding: '0px 11px',
          display: "flex",
          float: "right",
          textTransform: "none",
          fontWeight: "bold",
          fontSize: "12px",
          cursor: 'pointer',
          '&:hover': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)'
          },
          "&.Mui-disabled": {
            color: '#808080'
          },
          "& svg.share": {
            marginLeft: 12
          }
        }
      },
    },
    "& .MuiAutocomplete-root": {
      width: "100% !important",
    },
    "& .MuiList-root": {
      backgroundColor: "#FFF",
      marginTop: 10,
      marginBottom: 10,
      borderRadius: 4,
      maxHeight: 100,
      minHeight: 30,
      overflow: "auto",
      "& .MuiTypography-root": {
        color: "#000 !important",
      },
    },
    "& .container": {
      padding: "1em",
    },
    "&.calendarDialog .MuiDialog-paper": {
      width: "96%",
    },
    "&.eventDialog .MuiDialog-paper": {
      width: 512,
      "& .MuiInputBase-multiline": {
        width: "100%",
      },
      "& .two__columns": {
        display: "flex",
        flexDirection: "row",
        "& .first__column": {
          marginRight: 10
        },
        "& .second__column": {
          marginLeft: 10
        }
      }
    },
    "& .event": {
      position: "relative",
      width: "100%",
      borderRadius: "5px",
      backgroundColor: "transparent",
      color: "#000",
      fontWeight: "bold",
      display: "block",
      textAlign: "center",
      border: "1px solid #000",
      marginBottom: '2px',
      marginTop: '2px',
      "&:nth-of-type(1)": {
        marginTop: '4px'
      }
    },
    "&.userDialog .MuiDialog-paper": {
      width: 360,
      "& .pic-frame": {
        clipPath: "url(#clipping)",
        width: "120px",
      },
    },
    "&.pdfRoot .MuiDialogContent-root": {
    }
  },
  filePreview: {
    height: "85vh",
    overflow: "hidden",
    '& .file-title': {
      height: 64,
      color: '#000',
      fontSize: '1.5em',
      position: 'relative',
      alignItems: 'center',
      "& > button": {
        marginTop: '10px',
        zIndex: '100'
      },
      "& > p": {
        width: '100%',
        position: 'absolute',
        height: '100%',
        lineHeight: '50px'
      }
    },
    '& .related-selector': {
      height: 50,
      marginTop: '10px',
      marginBottom: '5px'
    },
    '& .file-viewer': {
      color: "#000",
      overflow: 'auto',
      maxHeight: 600
    }
  },
  pdfRoot: {
    borderRadius: '8px',
    maxWidth: '100%',
    position: 'relative',
    "& .react-pdf__Page": {
      borderRadius: '8px',
      width: '100%',
      position: 'relative',
      "& .react-pdf__Page__canvas": {
        width: '100% !important',
        height: '100%  !important'
      }
    },
  },
  pdfPage: {
    width: '100%'
  },
  pageControlsContainer: {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    width: '100%',
    left: 0,
    "& .pageControls" : {
      fontSize: 15,
      color: 'black',
      position: "relative",
      background: 'white',
      transition: 'opacity ease-in-out 0.2s',
      boxShadow: '0 30px 40px 0 rgba(16, 36, 94, 0.2)',
      borderRadius: '4px',
      "& button": {
        width: 44,
        height: 44,
        background: 'white',
        border: 0,
        font: 'inherit',
        borderRadius: '4px',
        '&:hover:enabled': {
          backgroundColor: '#e6e6e6',
          cursor: 'pointer'
        }
      },
      "& span": {
        marginLeft: 10,
        marginRight: 10
      }
    }
  }
});

export default styles;
