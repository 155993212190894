export const authConstants = {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  LOGIN_USER_UPDATE: 'LOGIN_USER_UPDATE',
  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  SET_NEW_PASSWORD: "SET_NEW_PASSWORD",
  SET_NEW_PASSWORD_SUCCESS: "SET_NEW_PASSWORD_SUCCESS",
  SET_NEW_PASSWORD_FAILURE: "SET_NEW_PASSWORD_FAILURE"
};
