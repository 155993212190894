import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/actions/auth-actions";
import { authConstants } from "../../redux/constants/auth-constants";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Container, Button, Box, Link } from "@material-ui/core";

import loginLogo from "../../assets/aquila_logo.svg";
import wordLogo from "../../assets/wordmark.png";
import btnLoginIcon from "../../assets/icons/login.png";
import btnSendIcon from "../../assets/icons/icon_send.svg";
import loginButton from "../../assets/login_btn1.svg";
import ToastMessage from "../../components/toastMessage";
import CustomInput from "../../components/customInput/CustomInput";

function emailIsValid(email) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export const useStyles = makeStyles((theme) =>
  createStyles({
    containerMain: {
      paddingTop: "28px",
        paddingBottom: "28px",
      width: '100%',
      height: "100%",
        backgroundColor: '#EEE'
    },
    box: {
      width: "380px",
      margin: "auto",
      padding: "30px",
      color: "rgba(0,0,0,.87)",
      position: 'relative'
    },
    textField: {
      width: "100%",
      backgroundColor: "white",
      height: "35px",
      border: "none",
      padding: "4px 6px",
    },
    secondTextField: {
      marginTop: "12px"
    },
    href: {
      cursor: "pointer",
      display: "flex",
      fontWeight: 'bold',
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.06em',
      color: '#000000'
    },
    dense: {
      marginTop: theme.spacing(2),
    },
    menu: {
      width: 200,
    },
    actionsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: '36px',
      marginTop: '12px'
    },
    defaultButton: {
      background: '#FFFFFF',
      border: '4px solid #C9C9C9',
      boxSizing: 'border-box',
      borderRadius: '4px',
      flex: 'none',
      order:'3',
      flexGrow: 0,
      color: "#000000",
      width: '95px',
      height: '32px',
      padding: '0px 11px',

      display: "flex",
      float: "right",
      textTransform: "none",
      fontWeight: "bold",
      fontSize: "12px",
      justifyContent: 'space-between'
    },
    icon: {
      background: `url(${btnSendIcon}) no-repeat`,
      height: '22px',
      width: '22px',
      display: 'block'
    },
    button: {
      display: "flex",
      color: "white",
      float: "right",
      padding: "18px",
      textTransform: "none",
      fontWeight: "bold",
      fontSize: "16px",
      background: `transparent url(${loginButton}) center center no-repeat`,
      border: "none",
      "&:hover": {
        backgroundColor: "transparent",
        border: "none",
      },
    },
    labelCaption: {
      margin: 0,
      paddingLeft: "10px",
      fontWeight: "700",
      fontSize: "14px",
    },
    validationLabel: {
      fontSize: '9px',
      color: "#e00000",
      width: '100%',
      textAlign: 'left',
      paddingLeft: '30px',
      paddingRight: '30px',
      left: '0px',
      textTransform: 'uppercase'
    },
    a: {
      cursor: "pointer",
      paddingTop: "24px",
      display: "flex",
    },
    logo: {
    },
    logoContainer: {
      marginBottom: '-34px'
    },
    wordLogo: {
        marginTop: 17
    },
  })
);

const Login = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  let location = useLocation();
  const currentStatus = useSelector((state) => state.authentication.status);
  const [currentLocationKey, setCurrentLocationKey] = useState(null);
  const [onResetView, setOnResetView] = useState(false);
  const [resultMessage, setResultMessage] = useState({
    isVisible: false,
    tittle: "",
    message: "",
  });
  const [user, setUser] = useState({
    email: "",
    password: "",
    isValidated: false,
    validEmail: false,
  });

  const submitLoginUser = () => {
    if (user.isValidated === false) {
      setUser({ ...user, isValidated: true });
    }

    if (user.email && user.password && emailIsValid(user.email)) {
      dispatch(authActions.login(user.email, user.password));
    }
  };

  const submitResetPassword = () => {
    if (!!user.email && emailIsValid(user.email)) {
      dispatch(authActions.resetPassword(user.email));
      setOnResetView(false);
      setUser({
        email: "",
        password: "",
        isValidated: false,
        validEmail: false,
      });
    } else {
      setUser({ ...user, isValidated: true });
    }
  };

  const handleInputEmail = (e) => {
    if (resultMessage.isVisible) {
      setResultMessage({ isVisible: true });
    }
    setUser({
      ...user,
      email: e.target.value,
      validEmail: emailIsValid(e.target.value),
    });
  };

  const handleEmailShowError = () => {
    return user.isValidated &&
    !user.validEmail &&
    !(
      user.email === null ||
      user.email === "" ||
      user.email === undefined
    );
  };

  const EmailErrorMessage = () => {
    if (user.isValidated &&
      !user.validEmail &&
      !(
        user.email === null ||
        user.email === "" ||
        user.email === undefined
      ))
    {
      return "Invalid Email";
    }
  };

  const logoutUser = useCallback(() => {
    dispatch(authActions.logout());
    setResultMessage({
      isVisible: true,
      tittle: "Unauthorized user",
      message:
        "Unauthorized user, Check your email or password please!",
    });
  }, [dispatch]);

  const gotoHome = useCallback(() => {
    if (
      !currentLocationKey ||
      (!!location.state &&
        !!location.state.from &&
        location.state.from.key !== currentLocationKey)
    ) {
      history.replace('/home');
      //debugger
      /*if (!!location.state){
        let { from } = location.state;
        setCurrentLocationKey(from.key);
        history.replace(from);
      } else {
        history.replace('/home');
      }*/
    }
  }, [currentLocationKey, history, location]);

  const renderLogin = () => (
    <Fragment>
      <CustomInput
        inputId="email"
        label={'Email Address'}
        name={'Name'}
        value={user.email}
        type="text"
        margin="normal"
        variant="outlined"
        onChange={handleInputEmail}
        showError={handleEmailShowError()}
        errorMessage={EmailErrorMessage()}
      />
      {user.isValidated &&
      !user.validEmail &&
      !(
        user.email === null ||
        user.email === "" ||
        user.email === undefined
      ) ? (
        <span className={classes.validationLabel}>
          Please enter a valid email
        </span>
      ) : null}

      {user.isValidated &&
      (user.email === null ||
        user.email === "" ||
        user.email === undefined) ? (
        <span className={classes.validationLabel}>
          Please enter a valid email
        </span>
      ) : null}
      <CustomInput
        inputId="password"
        label={'Password'}
        className={classes.secondTextField}
        type="password"
        margin="normal"
        variant="outlined"
        placeholder=""
        value={user.password}
        showError={user.isValidated &&
        (user.password === null ||
          user.password === "" ||
          user.password === undefined) }
        onChange={(e) => {
          setUser({ ...user, password: e.target.value });
        }}
      />
      {user.isValidated &&
      (user.password === null ||
        user.password === "" ||
        user.password === undefined) ? (
        <span className={classes.validationLabel}>
          Please enter a valid password
        </span>
      ) : null}
      <div className={classes.actionsContainer}>
        <Button className={classes.defaultButton} onClick={submitLoginUser}>
          LOG IN
          <i className={classes.icon}></i>
        </Button>
        <Link className={classes.href} onClick={() => {
          setOnResetView(true);
          setUser({ ...user, isValidated: false });
        }}>Recover password</Link>
        {resultMessage.isVisible ? (
          <span className={classes.validationLabel}>
            {resultMessage.message}
          </span>
        ) : null}
      </div>
    </Fragment>
  );

  const renderReset = () => (
    <Fragment>
      <p className={classes.labelCaption}>To continue with the recovery  <br/> please provide your email</p>
      <CustomInput
        inputId="email"
        type="text"
        margin="normal"
        variant="outlined"
        placeholder="E-mail address"
        value={user.email}
        onChange={handleInputEmail}
      />
      {user.isValidated &&
      !user.validEmail &&
      !(
        user.email === null ||
        user.email === "" ||
        user.email === undefined
      ) ? (
        <span className={classes.validationLabel}>
          El email insertado no es correcto
        </span>
      ) : null}

      {user.isValidated &&
      (user.email === null ||
        user.email === "" ||
        user.email === undefined) ? (
        <span className={classes.validationLabel}>
          Please enter a valid email
        </span>
      ) : null}
      <div className={classes.actionsContainer}>
        <Button className={classes.defaultButton} onClick={submitResetPassword}>
          SEND
          <i className={classes.icon}></i>
        </Button>
        <Link className={classes.href} onClick={() => {
          setOnResetView(false);
          setUser({ ...user, isValidated: false });
        }}>Cancel</Link>
      </div>
    </Fragment>
  );

  useEffect(() => {
    switch (currentStatus) {
      case authConstants.LOGIN_SUCCESS:
        gotoHome();
        break;
      case authConstants.LOGIN_FAILURE:
        // call logout
        logoutUser();
        break;
      case authConstants.LOGOUT:
        break;
      default:
        break;
    }
  }, [currentStatus, logoutUser, gotoHome]);

  return (
    <div className={classes.containerMain}>
      <Box className={classes.box}>
        <center className={classes.logoContainer}>
          <img alt="Logo" className={classes.logo} src={loginLogo} />
        </center>
        {/*<center>
          <img alt="" className={classes.wordLogo} src={wordLogo} />
        </center>*/}
        { onResetView ? 
          renderReset() :
          renderLogin()
        }
      </Box>
      <ToastMessage />
    </div>
  );
};

export default Login;
