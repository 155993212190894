import { folderConstants } from '../constants/folder-constants';

export const folderActions = {
  requestHome,
  requestFolder,
  addFolder,
  editFolder,
  deleteFolder,
  shareFolder,
  unshareFolder,
  requestSharedItems,
  moveFolder
};

function requestHome() {
  return {
    type: folderConstants.FOLDER_REQUEST_HOME
  };
}
function requestFolder(id) {
  return {
    type: folderConstants.FOLDER_REQUEST,
    payload: { id }
  };
}
function addFolder(id, folderName) {
	return {
		type: folderConstants.FOLDER_ADD,
		payload: { id, folderName }
	}
}
function editFolder(id, folderName) {
  return {
    type: folderConstants.FOLDER_EDIT,
    payload: { id, folderName }
  }
}
function deleteFolder(id) {
  return {
    type: folderConstants.FOLDER_DELETE,
    payload: { id }
  }
}
function shareFolder(id, emails, comment) {
  return {
    type: folderConstants.FOLDER_SHARE,
    payload: { id, emails, comment }
  };
}
function unshareFolder(id, user_id) {
  return {
    type: folderConstants.FOLDER_UNSHARE,
    payload: { id, user_id }
  };
}
function requestSharedItems() {
  return {
    type: folderConstants.FOLDER_REQUEST_SHARED_ITEMS
  };
}
function moveFolder(folder, forlderId) {
  return {
    type: folderConstants.FOLDER_MOVE_REQUEST,
    payload: { id: folder.id, moveToFolder: forlderId },
  };
}
