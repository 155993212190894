import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from "react-router-dom";
import { Button } from "@material-ui/core";
import { isAuthenticated, clearAuth, storageUser } from "./lib/auth.helper";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "./redux/actions/auth-actions";
import Login from "./modules/login/Login";
import ResetPassword from "./modules/login/ResetPassword";
import Main from "./modules/docs/Main";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import btnLoginIcon1 from "./assets/icons/login.svg";
import loginButton from "./assets/login_btn1.svg";


export const useStyles = makeStyles((theme) =>
  createStyles({
    mainContainer: {
      height: '100%'
    }
  })
);
// Remove these components from here
function Users() {
  return <h2>Users</h2>;
}

function Settings() {
  return (
    <div>
      <h2>Setting</h2>
      <LogoutButton />
    </div>
  );
}

function LogoutButton() {
  let history = useHistory();
  const logoutUser = () => {
    clearAuth();
    history.replace("/login");
  };
  return <Button onClick={logoutUser}>Logout</Button>;
}

const PrivateRoute = ({ component: Component, isLoggedIn, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      isLoggedIn instanceof Function && isLoggedIn() === true ? (
        <Component />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const AquilaRouter = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory();
  const currentUser = useSelector((state) => state.authentication.user);
  useEffect(() => {
    if (!!isAuthenticated() && !currentUser) {
      const user = storageUser();
      dispatch(authActions.setLoggedUser(user));
    }
  }, [dispatch, currentUser]);

  return (
    <Router history={history}>
      <div className={classes.mainContainer}>
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/reset_password" component={ResetPassword} />
          <PrivateRoute
            path="/"
            component={Main}
            isLoggedIn={isAuthenticated}
          />
          <PrivateRoute
            path="/users"
            component={Users}
            isLoggedIn={isAuthenticated}
          />
          <PrivateRoute
            path="/settings"
            component={Settings}
            isLoggedIn={isAuthenticated}
          />
        </Switch>
      </div>
    </Router>
  );
};

export default AquilaRouter;
