import React from "react";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as FileSVG } from "../assets/File.svg";
import { ReactComponent as FolderSVG } from "../assets/Folder.svg";
import { ReactComponent as CalendarSVG } from "../assets/Menu_Calendar.svg";
import { ReactComponent as DownloadSVG } from "../assets/Menu_Download.svg";
import { ReactComponent as NewFolderSVG } from "../assets/Menu_NewFolder.svg";
import { ReactComponent as RecentSVG } from "../assets/Menu_Recent.svg";
import { ReactComponent as SearchSVG } from "../assets/Menu_Search.svg";
import { ReactComponent as ShareSVG } from "../assets/Menu_Share.svg";
import { ReactComponent as StatisticSVG } from "../assets/Menu_Statistic.svg";
import { ReactComponent as UploadSVG } from "../assets/Menu_Upload.svg";
import { ReactComponent as AquilaIconSVG } from "../assets/Aquila_Icono.svg";
import { ReactComponent as TreeCloseSVG } from "../assets/aquila-icon-g-right.svg";
import { ReactComponent as TreeOpenSVG } from "../assets/aquila-icon-g-down.svg";
import { ReactComponent as SideBarSVG } from "../assets/aquila-icon-g-b-left.svg";
import { ReactComponent as IsoLogoSVG } from "../assets/isologo.svg";

export function AFolderIcon() {
  return <SvgIcon component={FolderSVG} viewBox="0 0 78 78" />;
}

export function AFileIcon() {
  return <SvgIcon component={FileSVG} viewBox="0 0 52 64.55" />;
}

export function ACalendarIcon() {
  return <SvgIcon component={CalendarSVG} viewBox="0 0 25.73 26.07" />;
}

export function ADownloadIcon() {
  return <SvgIcon component={DownloadSVG} viewBox="0 0 38.47 35.65" />;
}

export function ANewFolderIcon() {
  return <SvgIcon component={NewFolderSVG} viewBox="0 0 43.23 35.31" />;
}

export function ARecentIcon() {
  return <SvgIcon component={RecentSVG} viewBox="0 0 32.92 32.92" />;
}

export function ASearchIcon() {
  return <SvgIcon component={SearchSVG} viewBox="0 0 37.37 37.37" />;
}

export function AShareIcon() {
  return <SvgIcon component={ShareSVG} viewBox="0 0 37.37 37.37" />;
}

export function AStatisticIcon() {
  return <SvgIcon component={StatisticSVG} viewBox="0 0 28.8 21.75" />;
}

export function AUploadIcon() {
  return <SvgIcon component={UploadSVG} viewBox="0 0 38.47 35.65" />;
}

export function AMainIcon() {
  return <SvgIcon component={AquilaIconSVG} viewBox="0 0 52.64 58.68" />;
}

export function ATreeCloseIcon() {
  return <SvgIcon component={TreeCloseSVG} viewBox="0 0 52.64 58.68" style={{transform: 'translateY(3px)'}}/>;
}

export function ATreeOpenIcon() {
  return <SvgIcon component={TreeOpenSVG} viewBox="0 0 59 53" style={{transform: 'rotate(90deg) translateX(4px)'}}/>;
}

export function ASideLeftIcon() {
  return <SvgIcon component={SideBarSVG} viewBox="0 0 100 100" style={{transform: 'translateY(2px)'}}/>;
}

export function ASideRightIcon() {
  return <SvgIcon component={SideBarSVG} viewBox="0 0 100 100" style={{transform: 'rotate(180deg) translateY(1px)'}}/>;
}

export function HomeIcons(props) {
  return <SvgIcon {...props} viewBox="0 0 48 51"/>;
}

export function SetIcon(props) {
  return <SvgIcon {...props}/>;
}
