/**
 * 
 * Navigation saga file
 * Any action made for a navigation should go here
 * 
 */
import { put, takeEvery, all, select, call } from 'redux-saga/effects';
import { navigationConstants } from '../constants/navigation-constants';
import { FolderService } from '../../lib/folder.service';

/**
 * Workers
 */
export function* breadcrumbGenerator(action) {
  let breadcrumbs = [];
  const { path } = action.payload;
  let lastParent = null;
  if (!!path) {
    while(path.length !== breadcrumbs.length) {
      const breadcrumb = path.find(f => f.parent_id === lastParent);
      breadcrumbs.push(breadcrumb);
      lastParent = breadcrumb.id;
    }
  }
  yield put({type: navigationConstants.BREADCRUMB_ADD, payload: { breadcrumbs }});
}
export function* getTree(action) {
  try {
    const userId = yield select( state => state.authentication.user.id );
    const result = yield call(FolderService.getTree, { userId });
    yield put({type: navigationConstants.TREE_ADD, payload: { tree: result }});
  } catch(error) {
    yield put({type: navigationConstants.TREE_ADD, payload: { tree: [] }});
  }
}
/**
 * Watchers
 */
export function* breadcrumbRequest() {
  yield takeEvery(navigationConstants.BREADCRUMB_ADD_REQUEST, breadcrumbGenerator);
}
export function* getTreeRequest() {
  yield takeEvery(navigationConstants.TREE_REQUEST, getTree);
}

/**
 * Export function
 */
function* navigationSaga() {
  // Watchers request
  yield all([
    breadcrumbRequest(),
    getTreeRequest(),
  ]);
}
export default navigationSaga;
