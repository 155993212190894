import { get, isNull } from "lodash";

/**
 * Performs CRUD operations in local storage.
 * Fetches data from local storage. If path is defined return its content
 * instead or null if not found.
 */
const STORAGE_PREFIX = "AQUILA";
const storagePrefixKey = (prefix, key = "") => prefix + "_" + key;

/**
 * Fetches data from local storage. If path is defined return its content
 * instead or null if not found.
 *
 * @param  {string}  key
 * @param  {string}  path
 * @param  {*}       default value if not found
 * @return {object}  Data corresponding to the key stored || path data
 */
export const storageGetData = (key, path, myDefault = null) => {
  if (!key) {
    console.log("Error - no key provided");
    return null;
  }
  const data = localStorage.getItem(storagePrefixKey(STORAGE_PREFIX, key));

  if (!isNull(data)) {
    try {
      const parsed = JSON.parse(data);

      return path ? get(parsed, path, myDefault) : parsed;
    } catch (error) {
      // couldn't parse data, it should be regular string
      if (path) {
        console.log("Error - stored data can't be parsed to JSON", data, path);
        return null;
      }
      return data; // just return stored string
    }
  } else {
    return myDefault;
  }
};

/**
 * Sets data to local storage.
 *
 * @param  {string} key
 * @param  {object} value to be stored (number, string or object)
 */
const storageSetData = (key, value) => {
  if (!key) {
    return false;
  }

  localStorage.setItem(
    storagePrefixKey(STORAGE_PREFIX, key),
    JSON.stringify(value)
  );
};

// Clears the local storage.
const storageClearAll = () => {
  console.info("StorageService: all data cleared");
  return localStorage.clear();
};

const Storage = {
  STORAGE_PREFIX,
  getData: storageGetData,
  setData: storageSetData,
  clearAll: storageClearAll,
};

export default Storage;
