import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	SvgIcon
} from '@material-ui/core';
import { TreeView, TreeItem } from '@material-ui/lab';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { ATreeCloseIcon, ATreeOpenIcon } from "../Icons";
import {ReactComponent as IconDownSVG} from "../../assets/icons/icon_down.svg";
import {ReactComponent as IconRightSVG} from "../../assets/icons/icon_right.svg";
import {ReactComponent as MoveSVG} from "../../assets/icons/icon_move.svg";

const MoveFolderDialog = (props) => {
	const { isOpen, onClose, classes, folder, isMoveAction, folderSelection } = props;
	const { tree } = useSelector((state) => state.navigation);

	const [folderName, setFolderName] = useState('');
	const [homeTree, setHomeTree] = useState([]);
	const [selectedFolder, setSelectedFolder] = useState('');
	const [open, setOpen] = useState(isOpen);

	const handleMove = () => {
		folderSelection(folder, selectedFolder)
		onClose();
	}

	const handleFolderSelection = (event: object, value: string) => {
		setSelectedFolder(value);
	}

	const renderBranch = (branch, subLevel, index) => {
    const { id, name, subfolders } = branch;
    return (
      <TreeItem
        nodeId={`${id}`}
        label={name}
        key={`${subLevel}-${id}-${index}`}
        classes={{
          root: classes.treeItem,
          expanded: classes.treeItemSeleted,
          selected: classes.treeItemSeleted,
        }}
      >
        {Array.isArray(subfolders)
          ? subfolders.map((node, index) =>
          		folder.id !== node.id ?
              renderBranch(node, subLevel + 1, index) : null
            )
          : null}
      </TreeItem>
    );
  };

	useEffect(() => {
		if(isOpen !== open) {
			setOpen(isOpen);
		}
		if(!!folder) {
			setFolderName(folder.name);
		}
		if(!!tree) {
			const homeBranch = tree.find(b => b.folder_type === 'home');
			if (!!homeBranch)
				setHomeTree([homeBranch]);
		}
	}, [isOpen, open, folder, tree])

  return (
		<Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.root}>
	    <DialogTitle id="form-dialog-title" disableTypography >
				<div className="dialog-title">
					{isMoveAction ? 'Move' : 'Restore'} <span className="highlight__name">{folderName}</span>
				</div>
	    </DialogTitle>
	    <DialogContent>
	      <TreeView
		      className={classes.root}
					defaultCollapseIcon={
						<SvgIcon
							className="share"
							component={IconDownSVG}
							viewBox={"0 0 12 13"}
						/>
					}
					defaultExpandIcon={<SvgIcon
						className="share"
						component={IconRightSVG}
						viewBox={"0 0 12 13"}
					/>}
		      onNodeSelect={handleFolderSelection}
		      selected={selectedFolder}
		    >
		      {!!folder && Array.isArray(homeTree)
		        ? homeTree.map((node, index) => renderBranch(node, 1, index))
		        : null}
		    </TreeView>
	    </DialogContent>
	    <DialogActions>
	      <Button onClick={onClose} className="button__cancel">
	        Cancel
	      </Button>
	      <Button onClick={handleMove} disabled={!selectedFolder} className="default__accept" >
					{isMoveAction ? 'MOVE' : 'RESTORE'}
					{
						isMoveAction && <SvgIcon
							className="share"
							component={MoveSVG}
							viewBox={"0 0 24 24"}
						/>
					}
	      </Button>
	    </DialogActions>
	  </Dialog>
	)
}

MoveFolderDialog.propTypes = {
  isOpen: PropTypes.bool,
  isMoveAction: PropTypes.bool,
  folder: PropTypes.object,
  folderSelection: PropTypes.func,
  onClose: PropTypes.func,
};

MoveFolderDialog.defaultProps = {
  isOpen: false,
  isMoveAction: true
};

export default withStyles(styles)(MoveFolderDialog);
