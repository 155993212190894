import React from 'react';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function getType(type) {
  switch (type) {
    case 'info':
      return toast.TYPE.INFO;
    case 'success':
      return toast.TYPE.SUCCESS;
    case 'error':
      return toast.TYPE.ERROR;
    default:
      return toast.TYPE.WARNING;
  }
}

export const notify = (message, type) =>
  toast(message, {
    containerId: 'A',
    type: getType(type),
    autoClose: 5000,
  });

function ToastMessage() {
  return (
    <div>
      <ToastContainer enableMultiContainer containerId="A" position={toast.POSITION.BOTTOM_RIGHT} />
    </div>
  );
}

export default ToastMessage;
