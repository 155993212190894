import API from "./api.service";

export class FileService {
  static async downloadFile({ folderId, id }) {
    try {
      const response = await API(
        false,
        false
      ).get(`folders/${folderId}/aquila_files/${id}/download`, { data: {} });
      return response.request;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async addFile({ folderId, file, userId }) {
    try {
      let formData = new FormData();

      formData.append("file", file);
      formData.append("owner_id", userId);
      // Add Metadata etc
      const response = await API(false, false).post(
        `folders/${folderId}/aquila_files/`,
        formData,
        {
          headers: {
            "Access-Control-Expose-Headers": "Content-Disposition",
            "content-type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async editFile({folderId, id, fileName}) {
    try {
      const response = await API(false, false).put(
        `folders/${folderId}/aquila_files/${id}`,
        { name: fileName },
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async deleteFile({folderId, id}) {
    try {
      const response = await API(false, false).delete(
        `folders/${folderId}/aquila_files/${id}`, {},
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async shareFile({ folderId, id, emails, comment }) {
    try {
      const params = { emails, comment, can_edit: false };
      // Add Metadata etc
      const response = await API(false, false).post(
        `folders/${folderId}/aquila_files/${id}/share`,
        params,
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async unshareFile({ folderId, id, user_id }) {
    try {
      const params = { user_id };
      // Add Metadata etc
      const response = await API(false, false).post(
        `folders/${folderId}/aquila_files/${id}/unshare`,
        params,
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async linkFiles({ file, files }) {
    try {
      const params = {
        aquila_file: {
          ...file,
          related_aquila_files_ids: files.map((file) => !!file.id && file.id),
        },
      };
      if (!!file.id && !!file.folder_id) {
        // Add Metadata etc
        const response = await API(false, false).put(
          `folders/${file.folder_id}/aquila_files/${file.id}`,
          params,
          ""
        );
        return response.status < 300 ? response.data : response;
      }
      return { status: "Error: File object incomplete", error: 303 };
    } catch (e) {
      return { status: e.status, error: 303 };
    }
  }
  static async searchFile({ criteria, userId }) {
    try {
      let params = { name: criteria };
      const response = await API(
        false,
        false
      ).get(`users/${userId}/aquila_files?query=${criteria}`, { data: params });
      if (response.status < 300) {
        return Array.isArray(response.data) ? response.data : [];
      } else {
        console.error("File service search error:", response);
      }
      return null;
    } catch (e) {
      return { status: e.status };
    }
  }
  static async moveFile({folderId, id, moveToFolder}) {
    try {
      const response = await API(false, false).post(
        `folders/${folderId}/aquila_files/${id}/move`, {move_folder_id: moveToFolder},
        ""
      );
      return response.data;
    } catch (e) {
      return { status: e.status };
    }
  }
}
export default FileService;
