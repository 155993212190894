/**
 *
 * Reducer for File
 *
 */
import { fileConstants } from "../constants/file-constants";
import { commonConstants } from '../constants/common-constants';

// The initial state for file
const fileInitialState = {
  failure: false,
  status: "",
  loading: false,
  results: [],
  previewUrl: "",
  relatedPreviewUrl: "",
};

export default function file(state = fileInitialState, action) {
  let newState;
  switch (action.type) {
    case fileConstants.FILE_DOWNLOAD_REQUEST:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case fileConstants.FILE_DOWNLOAD_SUCCESS:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_DOWNLOAD_SUCCESS,
        failure: false,
      };
      return newState;
    case fileConstants.FILE_DOWNLOAD_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_DOWNLOAD_FAILURE,
        failure: true,
      };
      return newState;
    case fileConstants.FILE_ADD:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case fileConstants.FILE_ADD_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_ADD_FAILURE,
        failure: true,
      };
      return newState;
    case fileConstants.FILE_EDIT:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case fileConstants.FILE_EDIT_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_EDIT_FAILURE,
        failure: true,
      };
      return newState;
    case fileConstants.FILE_SHARE:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case fileConstants.FILE_SHARE_SUCCESS:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_SHARE_SUCCESS,
        failure: false,
      };
      return newState;
    case fileConstants.FILE_SHARE_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_SHARE_FAILURE,
        failure: true,
      };
      return newState;
    case fileConstants.FILE_UNSHARE:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case fileConstants.FILE_UNSHARE_SUCCESS:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_UNSHARE_SUCCESS,
        failure: false,
      };
      return newState;
    case fileConstants.FILE_UNSHARE_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_UNSHARE_FAILURE,
        failure: true,
      };
      return newState;
    case fileConstants.FILE_LINKING:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case fileConstants.FILE_LINKING_SUCCESS:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_LINKING_SUCCESS,
        failure: false,
      };
      return newState;
    case fileConstants.FILE_LINKING_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_LINKING_FAILURE,
        failure: true,
      };
      return newState;
    case fileConstants.FILE_SEARCH:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case fileConstants.FILE_SEARCH_SUCCESS:
      const { results } = action.payload;
      newState = {
        ...state,
        results,
        loading: false,
        status: fileConstants.FILE_SEARCH_SUCCESS,
        failure: false,
      };
      return newState;
    case fileConstants.FILE_SEARCH_FAILURE:
      newState = {
        ...state,
        results: [],
        loading: false,
        status: fileConstants.FILE_SEARCH_FAILURE,
        failure: true,
      };
      return newState;

    case fileConstants.FILE_PREVIEW_REQUEST:
      newState = {
        ...state,
        loading: true,
        status: "",
        failure: false,
        previewUrl: "",
        relatedPreviewUrl: ""
      };
      return newState;
    case fileConstants.FILE_PREVIEW_SUCCESS:
      newState = {
        ...state,
        ...action.payload,
        loading: false,
        status: fileConstants.FILE_PREVIEW_SUCCESS,
        failure: false,
      };
      return newState;
    case fileConstants.FILE_PREVIEW_FAILURE:
      newState = {
        ...state,
        loading: false,
        previewUrl: "",
        status: fileConstants.FILE_PREVIEW_FAILURE,
        failure: true,
      };
      return newState;
    case fileConstants.FILE_RELATED_PREVIEW_REQUEST:
      newState = {
        ...state,
        loading: true,
        status: "",
        failure: false,
        relatedPreviewUrl: ""
      };
      return newState;
    case fileConstants.FILE_RELATED_PREVIEW_SUCCESS:
      newState = {
        ...state,
        ...action.payload,
        loading: false,
        status: fileConstants.FILE_RELATED_PREVIEW_SUCCESS,
        failure: false,
      };
      return newState;
    case fileConstants.FILE_RELATED_PREVIEW_FAILURE:
      newState = {
        ...state,
        loading: false,
        relatedPreviewUrl: "",
        status: fileConstants.FILE_RELATED_PREVIEW_FAILURE,
        failure: true,
      };
      return newState;
    case fileConstants.FILE_DELETE:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case fileConstants.FILE_DELETE_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_DELETE_FAILURE,
        failure: true,
      };
      return newState;
    case fileConstants.FILE_MOVE_REQUEST:
      newState = {
        ...state,
        loading: true,
        status: "",
        failure: false
      };
      return newState;
    case fileConstants.FILE_MOVE_SUCCESS:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_MOVE_SUCCESS,
        failure: false,
      };
      return newState;
    case fileConstants.FILE_MOVE_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: fileConstants.FILE_MOVE_FAILURE,
        failure: true,
      };
      return newState;
    case commonConstants.RESET:
      return fileInitialState;
    default:
      return state;
  }
}
