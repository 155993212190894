import React, {useState} from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import {Box, Container, IconButton, SvgIcon, Tooltip} from "@material-ui/core";
import {Link} from "react-router-dom";
import {ReactComponent as OptionsSVG} from "../../assets/icons/icon_options.svg";
import PropTypes from "prop-types";
import {ReactComponent as EditSVG} from "../../assets/icons/icon_edit.svg";
import {ReactComponent as MoveSVG} from "../../assets/icons/icon_move.svg";
import {ReactComponent as TrashSVG} from "../../assets/icons/icon_trash.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    rootFolder: {
      display: "flex",
      marginTop: '0px',
      marginBottom: '16px',
      background: "#FFFFFF",
      border: "4px solid #C9C9C9",
      boxSizing: "border-box",
      borderRadius: "4px",
      width: "300px",
      marginLeft: '20px',
      marginRight: '20px'
    },
    rootFolderLink: {
      padding: "12px 16px 16px",
      display: "flex",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: '24px',
      lineHeight: "28px",
      textDecoration: "none",
      color: "#000",
      width: "100%",
      alignItems: 'center'
    },
    rootFolderLinkText: {
      width: "100%",
      textAlign: "center",
      margin: 0,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      letterSpacing: '-0.03em'
    },
    rootFolderLinkIcon: {
      cursor: 'pointer'
    },
    gridActionsContainer: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      height: 28
    },
    gridActions: {
      display: 'grid',
      gridTemplateColumns: '25px 25px 25px',
      gridRow: 'auto auto',
      gridColumnGap: '8px',
      gridRowGap: '0px'
    },
    folderSubIcon: {
      width: '24px !important',
      height: '24px !important'
    },
  })
);

const FirstLevelFolder = (props) => {
  const classes = useStyles();
  const { inSharedItems, subFolder, handleEditFolder, handleDeleteFolder, handleMoveFolder } = props;
  const [showOptions, setShowOptions] = useState(false);

  const openOptions = (event) => {
    setShowOptions(!showOptions);
    event.preventDefault();
  };

  return (
    <div className={classes.rootFolder} key={subFolder.id}>
      <Link disabled={!!inSharedItems} to={`${subFolder.id}`} className={classes.rootFolderLink}>
        {!showOptions ?
          <Tooltip title={subFolder.name}>
            <p className={classes.rootFolderLinkText}>{subFolder.name}</p>
          </Tooltip>:
          <div className={classes.gridActionsContainer}>
            <Box className={classes.gridActions}>
              <Tooltip title="Edit">
                <IconButton
                  edge="end"
                  aria-label="share"
                  size="small"
                  onClick={(event) => {
                    event.preventDefault();
                    handleEditFolder(subFolder);
                  }}
                >
                  <SvgIcon
                    component={EditSVG}
                    viewBox={"0 0 24 24"}
                    className={classes.folderSubIcon}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  edge="end"
                  aria-label="delete"
                  size="small"
                  onClick={(event) => {
                    event.preventDefault();
                    handleDeleteFolder(subFolder);
                  }}
                >
                  <SvgIcon
                    component={TrashSVG}
                    viewBox={"0 0 50 50"}
                    className={classes.folderSubIcon}
                  />
                </IconButton>
              </Tooltip>
              <Tooltip title="Move">
                <IconButton
                  edge="end"
                  aria-label="Move"
                  size="small"
                  onClick={(event) => {
                    event.preventDefault();
                    handleMoveFolder(subFolder);
                  }}
                >
                  <SvgIcon
                    component={MoveSVG}
                    viewBox={"0 0 24 24"}
                    className={classes.folderSubIcon}
                  />
                </IconButton>
              </Tooltip>
            </Box>
          </div>
        }
        <SvgIcon
          className={classes.rootFolderLinkIcon}
          component={OptionsSVG}
          viewBox={"0 0 24 24"}
          onClick={openOptions}
        />
      </Link>
    </div>
  );
};

FirstLevelFolder.propTypes = {
  inSharedItems: PropTypes.bool,
  subFolder: PropTypes.object,
  handleEditFolder: PropTypes.func,
  handleDeleteFolder: PropTypes.func,
  handleMoveFolder: PropTypes.func,
};

export default FirstLevelFolder;
