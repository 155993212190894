/**
 *
 * File saga file
 * Any action made for a file should go here
 *
 */
import { call, put, takeEvery, all, select } from "redux-saga/effects";
import { fileConstants } from "../constants/file-constants";
import { folderConstants } from "../constants/folder-constants";
import { FileService } from "../../lib/file.service";

/**
 * Workers
 */
export function* downloadFile(action) {
  try {
    const folderId = action.payload.file.folder_id;
    const id = action.payload.file.id;
    const result = yield call(FileService.downloadFile, { folderId, id });
    yield put({ type: fileConstants.FILE_DOWNLOAD_SUCCESS, payload: {} });
    // Add download action
    const win = window.open(result.responseURL, "_blank");
    win.focus();
  } catch (error) {
    yield put({ type: fileConstants.FILE_DOWNLOAD_FAILURE });
  }
}

export function* addFile(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const folderId = yield select((state) => state.folder.currentFolder.id);
    const { file } = action.payload;

    yield call(FileService.addFile, { folderId, file, userId });
    yield put({
      type: folderConstants.FOLDER_REQUEST,
      payload: { id: folderId },
    });
  } catch (error) {
    yield put({ type: fileConstants.FILE_ADD_FAILURE });
  }
}

export function* editFile(action) {
  try {
    const currentFolderId = yield select((state) => state.folder.currentFolder.id);
    const { id, fileName } = action.payload;

    yield call(FileService.editFile, { folderId: currentFolderId, id, fileName });
    yield put({
      type: folderConstants.FOLDER_REQUEST,
      payload: { id: currentFolderId },
    });
  } catch (error) {
    yield put({ type: fileConstants.FILE_EDIT_FAILURE });
  }
}

export function* deleteFile(action) {
  try {
    const currentFolderId = yield select((state) => state.folder.currentFolder.id);
    const { id } = action.payload.file;

    yield call(FileService.deleteFile, { folderId: currentFolderId, id });
    yield put({
      type: folderConstants.FOLDER_REQUEST,
      payload: { id: currentFolderId },
    });
  } catch (error) {
    yield put({ type: fileConstants.FILE_DELETE_FAILURE });
  }
}

export function* shareFile(action) {
  try {
    const folderId = yield select((state) => state.folder.currentFolder.id);
    const { id, emails, comment } = action.payload;

    yield call(FileService.shareFile, { folderId, id, emails, comment });
    yield put({ type: fileConstants.FILE_SHARE_SUCCESS });
  } catch (error) {
    yield put({ type: fileConstants.FILE_SHARE_FAILURE });
  }
}

export function* unshareFile(action) {
  try {
    const folderId = yield select((state) => state.folder.currentFolder.id);
    const { id, user_id } = action.payload;

    yield call(FileService.unshareFile, { folderId, id, user_id });
    yield put({ type: fileConstants.FILE_UNSHARE_SUCCESS });
    yield put({ type: folderConstants.FOLDER_REQUEST_SHARED_ITEMS });
  } catch (error) {
    yield put({ type: fileConstants.FILE_UNSHARE_FAILURE });
  }
}

export function* linkFiles(action) {
  try {
    const { file, files } = action.payload;
    const folderId = yield select((state) => state.folder.currentFolder.id);
    const result = yield call(FileService.linkFiles, { file, files });
    if (!!result && !result.status && !result.error) {
      yield put({ type: fileConstants.FILE_LINKING_SUCCESS });
      yield put({
        type: folderConstants.FOLDER_REQUEST,
        payload: { id: folderId },
      });
    } else {
      yield put({ type: fileConstants.FILE_LINKING_FAILURE });
    }
  } catch (error) {
    yield put({ type: fileConstants.FILE_LINKING_FAILURE });
  }
}

export function* searchFile(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const { criteria, showFolder } = action.payload;
    const result = yield call(FileService.searchFile, { criteria, userId });
    if (!result) { // no status when success
      yield put({ type: fileConstants.FILE_SEARCH_FAILURE });
    } else {
      yield put({
        type: fileConstants.FILE_SEARCH_SUCCESS,
        payload: { results: result },
      });
      
      // Set the current folder into the file explorer based on this flag,
      // or it could be a nested search that only needs the results
      if (!!showFolder) {
        // Create a presentation folder to wrap up search results
        const resultsFolder = {
          id: "search",
          name: "Search",
          description: "Search",
          path: [],
          subfolders: [],
          isLeave: true,
          owner_id: userId,
          parent_id: 0,
          aquila_files: result,
        };
        yield put({
          type: folderConstants.FOLDER_SUCCESS,
          payload: { currentFolder: resultsFolder },
        });
      }
    }
  } catch (error) {
    yield put({ type: fileConstants.FILE_SEARCH_FAILURE });
  }
}

export function* previewFile(action) {
  try {
    const folderId = action.payload.file.folder_id;
    const id = action.payload.file.id;
    const result = yield call(FileService.downloadFile, { folderId, id });
    yield put({
      type: fileConstants.FILE_PREVIEW_SUCCESS,
      payload: { previewUrl: result.responseURL },
    });
  } catch (error) {
    yield put({ type: fileConstants.FILE_PREVIEW_FAILURE });
  }
}

export function* previewRelatedFile(action) {
  try {
    const folderId = action.payload.file.folder_id;
    const id = action.payload.file.id;
    const result = yield call(FileService.downloadFile, { folderId, id });
    yield put({
      type: fileConstants.FILE_RELATED_PREVIEW_SUCCESS,
      payload: { relatedPreviewUrl: result.responseURL },
    });
  } catch (error) {
    yield put({ type: fileConstants.FILE_RELATED_PREVIEW_FAILURE });
  }
}

export function* moveFile(action) {
  try {
    const currentFolderId = yield select((state) => state.folder.currentFolder.id);
    const { file, moveToFolder } = action.payload;
    const { id } = file;

    yield call(FileService.moveFile, { folderId: currentFolderId, id, moveToFolder });
    yield put({
      type: fileConstants.FILE_MOVE_SUCCESS,
    });
    yield put({
      type: folderConstants.FOLDER_REQUEST,
      payload: { id: currentFolderId },
    });
  } catch (error) {
    yield put({ type: fileConstants.FILE_MOVE_FAILURE });
  }
}

/**
 * Watchers
 */
export function* downloadRequest() {
  yield takeEvery(fileConstants.FILE_DOWNLOAD_REQUEST, downloadFile);
}
export function* addFileRequest() {
  yield takeEvery(fileConstants.FILE_ADD, addFile);
}
export function* editFileRequest() {
  yield takeEvery(fileConstants.FILE_EDIT, editFile);
}
export function* deleteFileRequest() {
  yield takeEvery(fileConstants.FILE_DELETE, deleteFile);
}
export function* shareFileRequest() {
  yield takeEvery(fileConstants.FILE_SHARE, shareFile);
}
export function* unshareFileRequest() {
  yield takeEvery(fileConstants.FILE_UNSHARE, unshareFile);
}
export function* linkFilesRequest() {
  yield takeEvery(fileConstants.FILE_LINKING, linkFiles);
}
export function* searchFileRequest() {
  yield takeEvery(fileConstants.FILE_SEARCH, searchFile);
}
export function* previewRequest() {
  yield takeEvery(fileConstants.FILE_PREVIEW_REQUEST, previewFile);
}
export function* previewRelatedRequest() {
  yield takeEvery(fileConstants.FILE_RELATED_PREVIEW_REQUEST, previewRelatedFile);
}
export function* moveFileRequest() {
  yield takeEvery(fileConstants.FILE_MOVE_REQUEST, moveFile);
}
/**
 * Export function
 */
function* fileSaga() {
  // Watchers request
  yield all([
    downloadRequest(),
    addFileRequest(),
    editFileRequest(),
    deleteFileRequest(),
    shareFileRequest(),
    unshareFileRequest(),
    linkFilesRequest(),
    searchFileRequest(),
    previewRequest(),
    previewRelatedRequest(),
    moveFileRequest(),
  ]);
}
export default fileSaga;
