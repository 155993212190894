import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import {
	Button,
	TextField,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	Container, Box, SvgIcon
} from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from '@material-ui/core/styles';

import { folderActions } from '../../redux/actions/folder-actions';
import styles from './styles';
import {ReactComponent as CreateSVG} from "../../assets/icons/icon_create.svg";

const NewFolderDialog = (props) => {
	const dispatch = useDispatch();
	const { isOpen, onClose, classes } = props;
	const { currentFolder } = useSelector(state => state.folder);

	const [folderName, setFolderName] = useState('');
	const [open, setOpen] = useState(isOpen);

	const handleNameChange = (event) => {
    setFolderName(event.target.value);
  };

	const handleCreate = () => {
		dispatch(folderActions.addFolder(currentFolder.id, folderName));
		onClose();
	}

	useEffect(() => {
		if(isOpen !== open) {
			setFolderName('');
			setOpen(isOpen);
		}
	}, [isOpen, open])

  return (
		<Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.root}>
	    <DialogTitle id="form-dialog-title" disableTypography >
				<div className="dialog-title">
					Create New Folder
				</div>
	    </DialogTitle>
	    <DialogContent>
				<div className="title__container">
					<label className="title__textfield">FOLDER NAME</label>
				</div>
				<Box>
					<TextField
						autoFocus
						id="name"
						fullWidth
						variant="outlined"
						value={folderName}
						onChange={handleNameChange}
						className="last__textfield"
					/>
				</Box>
	    </DialogContent>
	    <DialogActions>
	      <Button className="button__cancel" onClick={onClose}>
	        Cancel
	      </Button>
	      <Button className="default__accept" onClick={handleCreate} disabled={folderName.trim() === ''}>
	        CREATE
					<SvgIcon
						className="share"
						component={CreateSVG}
						viewBox={"0 0 48 49"}
					/>
	      </Button>
	    </DialogActions>
	  </Dialog>
	)
}

NewFolderDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

NewFolderDialog.defaultProps = {
  isOpen: false,
};

export default withStyles(styles)(NewFolderDialog);
