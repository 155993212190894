import { createMuiTheme } from "@material-ui/core/styles";

import MontserratRegularOtf from "../../assets/fonts/Montserrat-Regular.ttf";

const montserrat = {
  fontFamily: "Montserrat",
  fontStyle: "normal",
  fontDisplay: "swap",
  fontWeight: 400,
  src: `
    local('Montserrat'),
    local('Montserrat-Regular'),
    url(${MontserratRegularOtf}) format('ttf')
  `,
  unicodeRange:
    "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
};

/**
 * Colors palette definition
 * https://material-ui.com/customization/themes/
 */
const palette = {
  primary: {
    light: "#72ff85", // Green
    main: "#31d055",
    dark: "#009d25",
    contrastText: "#000",
  },
  secondary: {
    light: "#3f3f3f", // Gray
    main: "#191919",
    dark: "#000",
    contrastText: "#fff",
  },
  default: {
    color: "#6A696A",
    background: "#E5F2FB",
    green: "#48D552",
    red: "#A23D48",
    gray: "#8B8B8B",
  },
  background: {
    paper: "#fff",
    default: "#fff",
  },
  shape: {
    borderRadius: 40,
  },
};

/**
 * Typography definition
 * https://material-ui.com/style/typography/
 */
const typography = {
  // Use the system font instead of the default Roboto font.
  fontFamily: "Karla",
  fontSize: 12,
};

/**
 * Overrides UI elements
 * For documentation to get all UI component's name go to:
 * https://material-ui.com/
 * Props: https://material-ui.com/customization/default-theme/
 */
const overrides = {
  MuiCssBaseline: {
    "@global": {
      "@font-face": [montserrat],
      ".MuiIconButton-root:hover": {
        backgroundColor: 'transparent !important'
      }
    }
  },
  MuiTooltip: {
    tooltip: {
      fontSize: '12px',
      lineHeight: '14px',
      letterSpacing: '0.05em',
      color: '#000000',
      backgroundColor: '#FFF',
      border: '1px solid #000'
    }
  },
  MuiPaper: {
    root: {
      transition: '0s !important',
      transitionDuration: '0s !important'
    },
  },
  MuiMenuPaper: {
    transitionDuration: '0s !important'
  }
};

const theme = createMuiTheme({
  palette,
  typography,
  overrides,
  tooltip: {
    backgroundColor: 'red'
  },
  shadows: ["none"]
});

export default theme;
