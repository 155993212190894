/**
 *
 * Reducer for Folder
 *
 */
import { folderConstants } from "../constants/folder-constants";
import { commonConstants } from '../constants/common-constants';

// The initial state for folder
const folderInitialState = {
  currentFolder: null,
  failure: false,
  status: "",
  loading: false,
};

export default function folder(state = folderInitialState, action) {
  let newState;
  switch (action.type) {
    case folderConstants.FOLDER_REQUEST_HOME:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case folderConstants.FOLDER_REQUEST:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case folderConstants.FOLDER_SUCCESS:
      const { currentFolder } = action.payload;
      newState = {
        ...state,
        currentFolder,
        loading: false,
        status: folderConstants.FOLDER_SUCCESS,
        failure: false,
      };
      return newState;
    case folderConstants.FOLDER_FAILURE:
      newState = {
        ...state,
        currentFolder: null,
        loading: false,
        status: folderConstants.FOLDER_FAILURE,
        failure: true,
      };
      return newState;
    case folderConstants.FOLDER_ADD:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case folderConstants.FOLDER_ADD_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: folderConstants.FOLDER_ADD_FAILURE,
        failure: true,
      };
      return newState;
    case folderConstants.FOLDER_EDIT:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case folderConstants.FOLDER_EDIT_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: folderConstants.FOLDER_EDIT_FAILURE,
        failure: true,
      };
      return newState;
    case folderConstants.FOLDER_DELETE:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case folderConstants.FOLDER_DELETE_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: folderConstants.FOLDER_DELETE_FAILURE,
        failure: true,
      };
      return newState;
    case folderConstants.FOLDER_SHARE:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case folderConstants.FOLDER_SHARE_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: folderConstants.FOLDER_SHARE_FAILURE,
        failure: true,
      };
      return newState;
    case folderConstants.FOLDER_SHARE_SUCCESS:
      newState = {
        ...state,
        loading: false,
        status: folderConstants.FOLDER_SHARE_SUCCESS,
        failure: false,
      };
      return newState;
    case folderConstants.FOLDER_UNSHARE:
      newState = { ...state, loading: true, status: "", failure: false };
      return newState;
    case folderConstants.FOLDER_UNSHARE_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: folderConstants.FOLDER_UNSHARE_FAILURE,
        failure: true,
      };
      return newState;
    case folderConstants.FOLDER_UNSHARE_SUCCESS:
      newState = {
        ...state,
        loading: false,
        status: folderConstants.FOLDER_UNSHARE_SUCCESS,
        failure: false,
      };
      return newState;
    case folderConstants.FOLDER_REQUEST_SHARED_ITEMS:
      newState = {
        ...state,
        loading: true,
        status: "",
        failure: false,
      };
      return newState;
    case folderConstants.FOLDER_MOVE_REQUEST:
      newState = {
        ...state,
        loading: true,
        status: "",
        failure: false
      };
      return newState;
    case folderConstants.FOLDER_MOVE_SUCCESS:
      newState = {
        ...state,
        loading: false,
        status: folderConstants.FOLDER_MOVE_SUCCESS,
        failure: false,
      };
      return newState;
    case folderConstants.FOLDER_MOVE_FAILURE:
      newState = {
        ...state,
        loading: false,
        status: folderConstants.FOLDER_MOVE_FAILURE,
        failure: true,
      };
      return newState;
    case commonConstants.RESET:
      return folderInitialState;
    default:
      return state;
  }
}
