import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch } from 'react-redux';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from '@material-ui/core/styles';

import { folderActions } from '../../redux/actions/folder-actions';
import styles from './styles';

const EditFolderDialog = (props) => {
	const dispatch = useDispatch();
	const { isOpen, onClose, classes, currentFolder } = props;

	const [folderName, setFolderName] = useState('');
	const [open, setOpen] = useState(isOpen);

	const handleNameChange = (event) => {
    setFolderName(event.target.value);
  };

	const handleCreate = () => {
		dispatch(folderActions.editFolder(currentFolder.id, folderName));
		onClose();
	}

	useEffect(() => {
		if(isOpen !== open) {
			setOpen(isOpen);
		}
		if(!!currentFolder) {
			setFolderName(currentFolder.name);
		}
	}, [isOpen, open, currentFolder])

  return (
		<Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.root}>
	    <DialogTitle id="form-dialog-title" disableTypography >
				<div className="dialog-title">
					Rename <span className="highlight__name">{!!currentFolder && currentFolder.name}</span>
				</div>
	    </DialogTitle>
	    <DialogContent>
	      <TextField
	        autoFocus
	        margin="dense"
	        id="name"
					variant="outlined"
	        fullWidth
	        value={folderName}
	        onChange={handleNameChange}
	      />
	    </DialogContent>
	    <DialogActions>
	      <Button onClick={onClose} className="button__cancel">
	        Cancel
	      </Button>
	      <Button onClick={handleCreate} className="default__accept" disabled={!currentFolder || folderName.trim() === '' || folderName.trim() === currentFolder.name}>
	        Edit
	      </Button>
	    </DialogActions>
	  </Dialog>
	)
}

EditFolderDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

EditFolderDialog.defaultProps = {
  isOpen: false,
};

export default withStyles(styles)(EditFolderDialog);
