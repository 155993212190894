import React from "react";
import { ReactComponent as ClipPathSVG } from "../assets/Foto_Frame.svg";

import { makeStyles, createStyles } from "@material-ui/core/styles";

import { Container } from "@material-ui/core";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "block",
      position: "relative",
    },
    frame: {
      width: "120px",
      margin: "0 auto",
    },
    poster: {
      backgroundImage: "url(../assets/Profile_FotoMenu.svg)",
    },
    pic: {
      clipPath: "url(#clipping)",
      width: "auto",
    },
    hidden: {
      display: "none",
      visibility: "false",
    },
  })
);

const UserPicture = (props) => {
  const classes = useStyles();
  return (
    <Container className={classes.container}>
      <div className={classes.poster}>
        <img className={classes.pic} src={props.picture} alt="" />
      </div>
      <ClipPathSVG />
    </Container>
  );
};

export default UserPicture;
