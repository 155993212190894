import API from "./api.service";
import { saveAuth, setAuthHeaders, clearAuth } from "./auth.helper";

export class AuthService {
  static async login({ email, password }) {
    try {
      const response = await API(false, false).post(
        "/auth/sign_in",
        { user: { email, password } },
        ""
      );
      if (response.status < 300) {
        saveAuth(response.data);
        setAuthHeaders(response.headers);
        return response.data;
      } else {
        return response;
      }
    } catch (e) {
      console.err("e", e);
      return { status: e.status };
    }
  }
  static async logout() {
    clearAuth();
  }

  static async resetPassword(email) {
    try {
      const response = await API(false, false).post(
        'auth/password',
        { email: email },
        ""
      );
      return response;
    } catch (e) {
      console.err("Reset password error:", e);
      return { status: e.status };
    }
  }

  static async setNewPassword({password, passwordConfirmation}) {
    try {
      const response = await API(false, false).put(
        'auth/password',
        { password, password_confirmation: passwordConfirmation },
        ""
      );
      return response;
    } catch (e) {
      console.err("Reset password error:", e);
      return { status: e.status };
    }
  }
}
export default AuthService;
