import './common-constants';
export const calendarConstants = {
  REMINDERS_REQUEST: "REMINDERS_REQUEST",
  REMINDERS_REQUEST_SUCCESS: "REMINDERS_REQUEST_SUCCESS",
  REMINDERS_REQUEST_FAILURE: "REMINDERS_REQUEST_FAILURE",
  REMINDER_DELETE: "REMINDER_DELETE",
  REMINDER_DELETE_SUCCESS: "REMINDER_DELETE_SUCCESS",
  REMINDER_DELETE_FAILURE: "REMINDER_DELETE_FAILURE",
  REMINDER_ADD: "REMINDER_ADD",
  REMINDER_ADD_SUCCESS: "REMINDER_ADD_SUCCESS",
  REMINDER_ADD_FAILURE: "REMINDER_ADD_FAILURE",
  REMINDER_UPDATE: "REMINDER_UPDATE",
  REMINDER_UPDATE_SUCCESS: "REMINDER_UPDATE_SUCCESS",
  REMINDER_UPDATE_FAILURE: "REMINDER_UPDATE_FAILURE",
};
