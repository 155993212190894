/**
 *
 * Calendar saga
 * Any action made for a calendar should go here
 *
 */
import { call, put, takeEvery, all, select } from "redux-saga/effects";
import { calendarConstants } from "../constants/calendar-constants";
import { CalendarService } from "../../lib/calendar.service";

/**
 * Workers
 */
export function* getReminders(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const month = action.payload.month; // this is a Date object
    const result = yield call(CalendarService.getMonthReminders, {
      month,
      userId,
    });
    yield put({
      type: calendarConstants.REMINDERS_REQUEST_SUCCESS,
      payload: { reminders: result },
    });
  } catch (error) {
    yield put({ type: calendarConstants.REMINDERS_REQUEST_FAILURE });
  }
}

export function* deleteReminder(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const id = action.payload.id;
    yield call(CalendarService.deleteReminder, { id, userId });
    yield put({ type: calendarConstants.REMINDER_DELETE_SUCCESS, payload: {} });
    yield put({
      type: calendarConstants.REMINDERS_REQUEST,
      payload: {},
    });
  } catch (error) {
    yield put({ type: calendarConstants.REMINDER_DELETE_FAILURE });
  }
}

export function* addReminder(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const { reminder } = action.payload;
    const result = yield call(CalendarService.addReminder, {
      reminder,
      userId,
    });
    yield put({ type: calendarConstants.REMINDER_ADD_SUCCESS });
    yield put({
      type: calendarConstants.REMINDERS_REQUEST,
      payload: {},
    });
  } catch (error) {
    yield put({ type: calendarConstants.REMINDER_ADD_FAILURE });
  }
}

export function* updateReminder(action) {
  try {
    const userId = yield select((state) => state.authentication.user.id);
    const { reminder } = action.payload;
    yield call(CalendarService.updateReminder, {
      reminder,
      userId,
    });
    yield put({ type: calendarConstants.REMINDER_UPDATE_SUCCESS });
    yield put({
      type: calendarConstants.REMINDERS_REQUEST,
      payload: {},
    });
  } catch (error) {
    yield put({ type: calendarConstants.REMINDER_UPDATE_FAILURE });
  }
}

/**
 * Watchers
 */
export function* getRemindersRequest() {
  yield takeEvery(calendarConstants.REMINDERS_REQUEST, getReminders);
}
export function* deleteReminderRequest() {
  yield takeEvery(calendarConstants.REMINDER_DELETE, deleteReminder);
}
export function* addReminderRequest() {
  yield takeEvery(calendarConstants.REMINDER_ADD, addReminder);
}
export function* updateReminderRequest() {
  yield takeEvery(calendarConstants.REMINDER_UPDATE, updateReminder);
}

/**
 * Export function
 */
function* calendarSaga() {
  // Watchers request
  yield all([
    getRemindersRequest(),
    deleteReminderRequest(),
    addReminderRequest(),
    updateReminderRequest(),
  ]);
}
export default calendarSaga;
