/**
 *
 * Reducer for Users
 *
 */
import { userConstants } from '../constants/user-constants';
import { commonConstants } from '../constants/common-constants';
// The initial state for user
const userInitialState = {
  users: [],
  status: '',
  failure: false,
  loading: false
};
export default function user(state = userInitialState, action) {
  let newState;
  switch (action.type) {
    case userConstants.USERS_REQUEST:
      newState = {...state, status: '', loading: true};
      return newState;
    case userConstants.USERS_SUCCESS:
      const { users } = action.payload;
      newState = {...state, users, status: userConstants.USERS_SUCCESS, loading: false, failure: false};
      return newState;
    case userConstants.USERS_FAILURE:
      newState = {...state, status: userConstants.USERS_FAILURE, loading: false, failure: true};
      return newState;

    // User create actions
    case userConstants.USER_CREATE_REQUEST:
      newState = {...state, status: '', loading: true};
      return newState;
    case userConstants.USER_CREATE_SUCCESS:
      newState = {...state, status: userConstants.USER_CREATE_SUCCESS, loading: false};
      return newState;
    case userConstants.USER_CREATE_FAILED:
      newState = {...state, status: userConstants.USER_CREATE_FAILED, loading: false};
      return newState;

    // User edit actions
    case userConstants.USER_EDIT_REQUEST:
      newState = {...state, status: '', loading: true};
      return newState;
    case userConstants.USER_EDIT_SUCCESS:
      newState = {...state, status: userConstants.USER_EDIT_SUCCESS, loading: false};
      return newState;
    case userConstants.USER_EDIT_FAILED:
      newState = {...state, status: userConstants.USER_EDIT_FAILED, loading: false};
      return newState;

    // User delete actions
    case userConstants.USER_DELETE_REQUEST:
      newState = {...state, status: '', loading: true};
      return newState;
    case userConstants.USER_DELETE_SUCCESS:
      newState = {...state, status: userConstants.USER_DELETE_SUCCESS, loading: false};
      return newState;
    case userConstants.USER_DELETE_FAILED:
      newState = {...state, status: userConstants.USER_DELETE_FAILED, loading: false};
      return newState;
    case commonConstants.RESET:
      return userInitialState;
    default:
      return state;
  }
}
