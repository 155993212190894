import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  DialogActions,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  FormControl,
  Select,
  MenuItem,
  Container,
  Typography, SvgIcon,
} from "@material-ui/core";
import { userActions } from "../../redux/actions/user-actions";
import { authActions } from "../../redux/actions/auth-actions";
import {userConstants} from "../../redux/constants/user-constants";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import styles from "./styles";
import UserPicture from "../UserPicture";
import { notify } from "../toastMessage";
import Storage from "../../lib/storage";
import {ReactComponent as ProfileSVG} from "../../assets/icons/icon_profile.svg";
import {ToggleButtonGroup, ToggleButton} from "@material-ui/lab";
import ViewListIcon from "@material-ui/icons/ViewList";
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import clsx from "clsx";

const insideStyles = makeStyles((theme) => ({
  input: {
    color: 'black !important'
  },
  error: {
    color: 'red',
    marginTop: -13,
    marginBottom: 10
  },
  toggleContainerBox: {
    width: '100%'
  },
  toggleContainer: {
    width: '100%',
    paddingTop: '6px',
    "& .Mui-selected, & .Mui-selected:hover": {
      backgroundColor: '#000',
      "& .MuiToggleButton-label": {
        color: '#FFF'
      }
    },
    "& .MuiToggleButton-label": {
      textTransform: 'capitalize',
      color: '#000',
      fontSize: 14
    }
  },
  toggleButtonGroup: {
    width: '50%',
    borderColor: '#000',
    paddingTop: 6,
    paddingBottom: 6,
  }
}));

const UserForm = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, classes, userData } = props;
  const customClasses = insideStyles();
  const status = useSelector((state) => state.user.status);
  const [open, setOpen] = useState(isOpen);
  const [editingPassword, setEditingPassword] = useState(false);

  const [picture, setPicture] = useState(
    !!userData && !!userData.picture
      ? userData.picture
      : "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=80"
  );

  const [user, setUser] = useState({
    first_name: userData?.first_name || '',
    last_name: userData?.last_name || '',
    password: '',
    password_confirmation: ''
  });

  const [errors, setErrors] = useState({
    first_name: null,
    last_name: null,
    password: null
  });

  const handleFirstNameChange = (event) => {
    setUser({...user, first_name: event.target.value});
    if (!event.target.value) {
      setErrors({...errors, first_name: {message: 'First name is required!'}});
    } else {
      setErrors({...errors, first_name: null});
    }
  };

  const handleLastNameChange = (event) => {
    setUser({...user, last_name: event.target.value});
    if (!event.target.value ) {
      setErrors({...errors, last_name: {message: 'Last name is required!'}});
    } else {
      setErrors({...errors, last_name: null});
    }
  };

  const handlePasswordChange = (event) => {
    setUser({...user, password: event.target.value});
    if (!event.target.value && !user.password_confirmation ) {
      setErrors({...errors, password: null});
    } else if (event.target.value !== user.password_confirmation) {
      setErrors({...errors, password: {message: 'The password do not match with the confirmation'}});
    } else {
      setErrors({...errors, password: null});
    }
  };

  const handlePasswordConfirmationChange = (event) => {
    setUser({...user, password_confirmation: event.target.value});
    if (!event.target.value && !user.password ) {
      setErrors({...errors, password: null});
    } else if (event.target.value !== user.password) {
      setErrors({...errors, password: {message: 'The password do not match with the confirmation'}});
    } else {
      setErrors({...errors, password: null});
    }
  };

  const onSubmitEvent = () => {
    if (!!userData && userData.id !== -1) {
      const valid = !errors.last_name && !errors.first_name && !errors.password;
      if (valid){
        dispatch(userActions.editUser({
          id: userData.id,
          first_name: user.first_name,
          last_name: user.last_name,
          password: !user.password ? undefined : user.password,
          password_confirmation: !user.password_confirmation ? undefined : user.password_confirmation
        }));
      }
    }
  };

  const onChangePassword = () => {
    setEditingPassword(!editingPassword);
  };

  const beforeClose = () => {
    onClose();
  };

  useEffect(() => {
    if (isOpen){
      switch (status) {
        case userConstants.USER_EDIT_SUCCESS:
          const storedUser = Storage.getData("currentUser");
          Storage.setData("currentUser", {...storedUser, first_name: user.first_name, last_name: user.last_name});
          notify('Edit user completed.', 'success');
          dispatch(authActions.updateLoggedUser({first_name: user.first_name, last_name: user.last_name}))
          beforeClose();
          break;
        case userConstants.USER_EDIT_FAILED:
          notify('Edit user error', 'error');
          break;
        default:
          break;
      }
    }
  }, [status, isOpen]);

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
    if (!!userData && userData.id !== -1) {
      setUser({
        first_name: userData?.first_name || '',
        last_name: userData?.last_name || '',
        password: '',
        password_confirmation: ''
      });
      //setFirstName(userData.first_name);
    }
  }, [isOpen, open, userData]);

  useEffect(() => {
    if (isOpen) {
      setEditingPassword(false);
    }
  }, [isOpen]);

  const [view, setView] = React.useState('list');

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  return (
    <Dialog
      open={open}
      onClose={beforeClose}
      aria-labelledby="form-dialog-title"
      className={clsx(classes.root, 'userDialog')}
      maxWidth="md"
    >
      <DialogTitle id="form-dialog-title">
        <Container>
          <div className="dialog-title">
            {editingPassword ? 'Change Password' : 'Update Profile'}
          </div>
          {/*<Typography>
            {!!userData && `${userData.first_name} ${userData.last_name}`}
          </Typography>*/}
        </Container>
      </DialogTitle>
      <DialogContent>
        <form noValidate>
          {!editingPassword &&
          <>
            <div className="title__container">
              <label className="title__textfield">FIRST NAME</label>
            </div>
            <Box>
              <TextField
                id="user-first-name"
                variant="outlined"
                value={user.first_name}
                onChange={handleFirstNameChange}
              />
              {errors.first_name && <div className={customClasses.error}>{errors.first_name.message}</div>}
            </Box>
            <div className="title__container">
              <label className="title__textfield">LAST NAME</label>
            </div>
            <Box>
              <TextField
                id="user-last-name"
                variant="outlined"
                value={user.last_name}
                onChange={handleLastNameChange}
              />
              {errors.last_name && <div className={customClasses.error}>{errors.last_name.message}</div>}
            </Box>
            <div className="title__container">
              <label className="title__textfield">COLOR SCHEME</label>
            </div>
            <Box className={customClasses.toggleContainerBox}>
              <ToggleButtonGroup className={customClasses.toggleContainer}
                                 value={view} exclusive onChange={handleChange}>
                <ToggleButton className={customClasses.toggleButtonGroup}
                              value="list" aria-label="list">
                  Light Mode
                </ToggleButton>
                <ToggleButton className={customClasses.toggleButtonGroup}
                              value="module" aria-label="module" >
                  Dark Mode
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </>}
          {editingPassword && <>
              <div className="title__container">
                <label className="title__textfield">NEW PASSWORD</label>
              </div>
              <Box>
              <TextField
                id="user-password"
                type="password"
                variant="outlined"
                value={user.password}
                onChange={handlePasswordChange}
              />
              {errors.password && <div className={customClasses.error}>{errors.password.message}</div>}
              </Box>
              <div className="title__container">
                <label className="title__textfield">PASSWORD CONFIRMATION</label>
              </div>
              <Box>
              <TextField
              id="user-password-confirmation"
              type="password"
              variant="outlined"
              value={user.password_confirmation}
              onChange={handlePasswordConfirmationChange}
              />
              </Box>
          </>
          }
        </form>
      </DialogContent>

        <DialogActions>
          <Button onClick={beforeClose} className="button__cancel">
            Cancel
          </Button>
          {
            editingPassword ?
              <>
                <Button className="default__accept" onClick={onSubmitEvent}>
                  CHANGE
                </Button>
              </>
            : <>
            <div className="extended__actions">
              <Button className="button__change" onClick={onChangePassword}>
                CHANGE PASSWORD
              </Button>
              <Button className="default__accept" onClick={onSubmitEvent}>
                UPDATE
                <SvgIcon
                  className="share"
                  component={ProfileSVG}
                  viewBox={"0 0 48 49"}
                />
              </Button>
            </div>
            </>
          }
        </DialogActions>
    </Dialog>
  );
};

UserForm.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  userData: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    email: PropTypes.string,
  }),
};

UserForm.defaultProps = {
  isOpen: false,
  userData: {
    id: -1,
    title: "",
    email: "",
  },
};

export default withStyles(styles)(UserForm);
