import React, { useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';

import styles from "./styles";

const ShareUsersSelector = (props) => {
  const { users, emails, onSelect, classes } = props;
  const currentUser = useSelector((state) => state.authentication.user);
  const [value, setValue] = useState(props.emails);
  const filter = createFilterOptions();
  const fixedOptions = [];

  const renderOption = (option) => option.email;

  const onChange = (event, newValue) => {
    if (typeof newValue === "string") {
      setValue({
        email: newValue,
      });
      /*setValue([
        ...newValue.filter(option => fixedOptions.indexOf(option) === -1)
      ]);*/
    } else if (newValue && newValue.inputValue) {
      onSelect(newValue.inputValue);
      /*setValue([
        ...newValue.filter(option => fixedOptions.indexOf(option) === -1)
      ]);*/
      setValue({
        email: "",
      });
    } else {
      if (newValue && newValue.email) {
        onSelect(newValue.email);
      }
      setValue({
        email: "",
      });
      /*setValue([
        ...newValue.filter(option => fixedOptions.indexOf(option) === -1)
      ]);*/
    }
  };

  const onFiltering = (options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (params.inputValue !== "") {
      filtered.push({
        inputValue: params.inputValue,
        email: `Add "${params.inputValue}"`,
      });
    }

    return filtered;
  };

  const getOptionLabel = (option) => {
    // Value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }
    // Add "xxx" option created dynamically
    if (option.inputValue) {
      return option.inputValue;
    }
    // Regular option
    return option.email;
  };

  const getOptionDisabled = (option) => {
    return (
      !!emails.find((e) => e === option.email) ||
      option.email === currentUser.email
    );
  };

  const renderInput = (params) => (
    <TextField
      {...params}
      label="Search People by name or email"
      variant="outlined"
    />
  );

  return (
    <>
      <div>asdsadas</div>
    <Autocomplete
      className={classes.userShare}
      value={value}
      onChange={onChange}
      filterOptions={onFiltering}
      getOptionDisabled={getOptionDisabled}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      options={users}
      getOptionLabel={getOptionLabel}
      renderOption={renderOption}
      freeSolo
      renderInput={renderInput}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <div>
          <Chip
            label={option}
            {...getTagProps({ index })}
          />
          mmmmm
          </div>
        ))
      }
    />
    </>
  );
};

ShareUsersSelector.propTypes = {
  users: PropTypes.array,
  emails: PropTypes.array,
  onSelect: PropTypes.func,
};

ShareUsersSelector.defaultProps = {
  emails: [],
  users: [],
};

export default withStyles(styles)(ShareUsersSelector);
