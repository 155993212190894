import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useParams, useHistory, useLocation
} from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";

import {
  Box,
  Breadcrumbs,
  Container,
  Link,
  IconButton, SvgIcon, Tooltip, Paper, Tabs, Tab,
} from "@material-ui/core";
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ClearAllIcon from '@material-ui/icons/ClearTwoTone';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { folderActions } from "../../redux/actions/folder-actions";
import Folder from "./Folder";
import FolderGrid from "./FolderGrid";
import Search from "../../components/Search";
import {HomeIcons, SetIcon} from "../../components/Icons";
import clsx from "clsx";
import { ReactComponent as MaterialGridIcon } from "../../assets/icons/material_grid.svg";
import { ReactComponent as MaterialListIcon } from "../../assets/icons/material_list.svg";
import { ReactComponent as ShareSVG } from "../../assets/icons/icon_share_small.svg";
import PropTypes from "prop-types";
import Navigation from "./Navigation";
import {fileConstants} from "../../redux/constants/file-constants";
import FilePreview from "./FilePreview";
import {fileActions} from "../../redux/actions";
import {ReactComponent as BackSVG} from "../../assets/icons/back_icon.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    file: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: "100%",
      backgroundColor: "white",
      height: "35px",
    },
    mainContainer: {
    },
    mainFolderNameContainer: {
      marginBottom: '24px',
      fontSize: 18,
      fontWeight: 'bold',
      display: 'flex',
      "& > div": {
        width: '100%'
      }
    },
    explorerSearchBar: {
      display: 'flex',
      justifyContent: 'center',
      "& form": {
        border: '1px solid #000000',
        borderRadius: '16px'
      },
      "& input": {
        backgroundColor: '#FFF',
        textAlign: 'center',
        paddingLeft: 7,
        minWidth: 400,
        border: 'none',
        borderRadius: '16px'
      }
    },
    gridIcon: {
      marginRight: theme.spacing(1)
    },
    selectedColor: {
      filter: 'invert(56%) sepia(6%) saturate(14%) hue-rotate(39deg) brightness(89%) contrast(82%)'
    },
    unselectedColor: {
      filter: 'invert(0%) sepia(99%) saturate(0%) hue-rotate(264deg) brightness(110%) contrast(101%)'
    },
    explorerOptions: {
      marginTop: theme.spacing(3),
      display: 'flex',
      justifyContent: 'center',
      '& svg': {
        '&:hover': {
          cursor: 'pointer'
        }
      },
    },
    breadcrumbsContainer: {
      marginLeft: theme.spacing(3),
      display: 'flex',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '21px',
      letterSpacing: '-0.02em',
      color: '#808080',
      alignItems: 'center',
      '& .MuiBreadcrumbs-ol': {
        display: 'flex',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '21px',
        letterSpacing: '-0.02em',
        color: '#808080',
        '& a': {
          color: '#000'
        },
        '& li': {
          color: '#000',
          '&:last-child': {
            '& a': {
              fontWeight: 'bold',
              color: '#808080'
            }
          }
        }
      }
    },
    firstSlash: {
      margin: '0',
      display: 'flex',
      padding: '0',
      flexWrap: 'wrap',
      listStyle: 'none',
      alignItems: 'center',
      marginRight: 9,
      color: '#000'
    },
    actionsContainer: {
      display: 'flex',
      marginLeft: theme.spacing(3)
    },
    sharedText: {
      marginTop: 24,
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px'
    },
    sharedContainer: {
      marginTop: theme.spacing(3),
      "& .MuiTabs-flexContainer": {
        justifyContent: 'space-between',
        backgroundColor: '#EEE'
      },
      "& .MuiTab-root": {
        width: 'calc(50% - 10px)',
        maxWidth: '50%',
        backgroundColor: '#EEE',
        border: '1px solid black',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 24,
        lineHeight: '28px',
        letterSpacing: '-0.03em',
        color: '#000000',
        textTransform: 'none',
        height: '64px',
        "&.Mui-selected": {
          backgroundColor: 'white'
        }
      },
    },
    sharedMeTab: {
      marginRight: 10
    },
    sharedOthersTab: {
      marginLeft: 10
    }
  })
);

const Explorer = (props) => {
  const classes = useStyles();
  const { clickShare, inTrash, inShared, setLeftSideOpen, setDisabledMenuItems } = props;
  const { breadcrumbs } = useSelector((state) => state.navigation);
  const { currentFolder } = useSelector((state) => state.folder);
  const dispatch = useDispatch();
  const { previewUrl, relatedPreviewUrl, status } = useSelector((state) => state.file);
  const [isPDFOpen, setIsPDFOpen] = useState(false);
  const { user } = useSelector((state) => state.authentication);
  let { id } = useParams();

  const [tabValue, setTabValue] = React.useState(0);
  let history = useHistory();

  const handleBack = (folder) => {
    dispatch(folderActions.requestFolder(folder.parent_id));
  };

  const handleChangeTab = (event, newValue) => {
    setTabValue(newValue);
    if (newValue === 0){
      //handleOpen(null,'shared');
      dispatch(folderActions.requestFolder('shared'));
    } else {
      dispatch(folderActions.requestSharedItems());
    }
  };

  const [resultMessage, setResultMessage] = useState({
    isVisible: false,
    tittle: "",
    message: "",
  });
  const [currentBreadcrumbs, setCurrentBreadcrumbs] = React.useState(
    breadcrumbs
  );
  const [displayGrid, setDisplayGrid] = useState(true);

  const renderBreadcrumbs = () => {
    return breadcrumbs.map((breadcrumb, index) => {
      const { id, name } = breadcrumb;
      // to needs a proper route calc function
      return (
        <Link href={`${id}`} key={`folder-${id}-${index}`}>
          {name}
        </Link>
      );
    });
  };

  /*
  File Management functions
  */
  const [currentFile, setCurrentFile] = useState(null);
  const [pdfRelatedFiles, setPdfRelatedFiles] = useState([]);
  const [shareIsOpen, setShareIsOpen] = useState(false);
  const [fileLinkingIsOpen, setFileLinkingIsOpen] = useState(false);

  const fileClassName = (file) => {
    return file.content_type.toLowerCase() === "application/pdf" ? classes.handCursor : '';
  }

  let location = useLocation();

  const requestPreview = (file) => {
    if (file.content_type.toLowerCase() === "application/pdf") {
      dispatch(fileActions.requestPreview(file));
    }
    history.push('/'+currentFolder.id);
    setCurrentFile(file);
    if (!!file && file.related_aquila_files) {
      setPdfRelatedFiles(file.related_aquila_files.filter(f => f.content_type.toLowerCase() === "application/pdf"));
    }
  };

  const onCloseFilePreview = () => {
    setIsPDFOpen(false);
    setCurrentFile(null);
    setPdfRelatedFiles([]);
    setDisabledMenuItems([]);
  };

  const requestFileShare = (file) => {
    setCurrentFile(file);
    setShareIsOpen(true);
  };

  const requestFileLinking = (file) => {
    setCurrentFile(file);
    setFileLinkingIsOpen(true);
  };

  const onCloseFileShare = () => {
    setShareIsOpen(false);
    setCurrentFile(null);
  };

  const onCloseFileLinking = () => {
    setFileLinkingIsOpen(false);
    setCurrentFile(null);
  };

  const handleSelectPreviewRelated = (file) => {
    dispatch(fileActions.requestRelatedPreview(file));
  };

  /* 
    NOTE Do not use handle empty search from Search component 
         because it causes problems with dialog searches
  */
  const handleClearSearch = () => {
    if (!!id && id.toString().toLowerCase() === "home") {
      dispatch(folderActions.requestHome());
    } else {
      dispatch(folderActions.requestFolder(id));
    }
  };

  useEffect(() => {
    if (!!breadcrumbs.length) {
      if (!currentBreadcrumbs.length) {
        setCurrentBreadcrumbs(breadcrumbs);
      } else {
        // If the last breadcrumb in each array is not the same
        if (
          currentBreadcrumbs[currentBreadcrumbs.length - 1].id !==
          breadcrumbs[breadcrumbs.length - 1].id
        ) {
          setCurrentBreadcrumbs(breadcrumbs);
        }
      }
    }
  }, [breadcrumbs, currentBreadcrumbs, setCurrentBreadcrumbs]);

  useEffect(() => {
    if (!!currentFolder && currentFolder.parent_id === null) {
      setDisabledMenuItems([2]);
    } else {
      setDisabledMenuItems([]);
    }
    if (inShared) {
      if (!!currentFolder && currentFolder.folder_type === 'shared_items') {
        setDisabledMenuItems([]);
      } else {
        setDisabledMenuItems([2, 3]);
      }
    }
  }, [currentFolder, inShared]);

  useEffect(() => {
    if (inTrash) {
      setDisabledMenuItems([2,3]);
    }
  }, [inTrash]);

  useEffect(() => {
    if (previewUrl !== "" && status === fileConstants.FILE_PREVIEW_SUCCESS) {
      setIsPDFOpen(true);
      setDisabledMenuItems([2,3]);
      setLeftSideOpen(false);
    }
  }, [previewUrl, status]);

  React.useEffect(() => {
    window.onpopstate = e => {
      if (isPDFOpen) {
        setIsPDFOpen(false);
      }
    };
  });

  return (
    <Container className={classes.mainContainer}>
      {
        isPDFOpen &&
        <FilePreview isOpen={isPDFOpen}
                     onClose={onCloseFilePreview}
                     previewUrl={previewUrl}
                     previewRelatedUrl={relatedPreviewUrl}
                     fileName={!!currentFile && currentFile.name}
                     relatedFiles={pdfRelatedFiles}
                     onSelectRelated={handleSelectPreviewRelated}
        />
      }
      { !isPDFOpen &&
        <>
          {
            !!currentFolder && currentFolder.parent_id &&
            currentFolder.id !== "search" && currentFolder.id !== "shared_items"
            && !inTrash && !inShared ?
            <Box className={classes.mainFolderNameContainer}>
              <Link href={`${currentFolder.parent_id}`}>
                <Tooltip title="Back">
                  <IconButton
                    edge="end"
                    aria-label="Share"
                    size="small"
                    onClick={() => handleBack(currentFolder)}
                    disableRipple={true}
                  >
                    <SvgIcon
                      component={BackSVG}
                    />
                  </IconButton>
                </Tooltip>
              </Link>
              <div>{currentFolder.name}</div>
            </Box> : null
          }
          <Box display="flex" className={classes.explorerSearchBar}>
            <Search
              style={{width: "100%"}}
              onEmptySearch={handleClearSearch}
              showFolder
            />
            {/*!!currentFolder && (currentFolder.parent_id || currentFolder.id === "search" || currentFolder.id === "shared_items") &&
            <IconButton aria-label="list" className={classes.margin} onClick={() => setDisplayGrid(!displayGrid)}>
            { displayGrid ? (<ViewListIcon fontSize="small" />) : (<ViewModuleIcon fontSize="small" />) }
            </IconButton>*/
            }
          </Box>
          {
            inShared &&
            <Paper square className={classes.sharedContainer}>
              <Tabs
                value={tabValue}
                onChange={handleChangeTab}
                TabIndicatorProps={{
                  style: {
                    display: "none",
                  }
                }}
              >
                <Tab label="Shared with me" className={classes.sharedMeTab}/>
                <Tab label="Shared with others" className={classes.sharedOthersTab}/>
              </Tabs>
            </Paper>
          }
          {!!currentFolder &&
          (currentFolder.parent_id || currentFolder.id === "search" || currentFolder.id === "shared_items"
            || inTrash || inShared) &&
          <div className={classes.explorerOptions}>
            {
              !!currentFolder && (currentFolder.parent_id || currentFolder.id === "search"
                || currentFolder.id === "shared_items" || inTrash || inShared) &&
              <>
                <Tooltip title="Grid View">
                  <IconButton
                    edge="end"
                    aria-label="Grid View"
                    size="small"
                    onClick={() => setDisplayGrid(true)}
                    className={clsx(classes.gridIcon, {
                      [classes.selectedColor]: displayGrid,
                      [classes.unselectedColor]: !displayGrid
                    })}
                  >
                    <SetIcon
                      component={MaterialGridIcon}
                    />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Table View'>
                  <IconButton
                    edge="end"
                    aria-label="Table View"
                    size="small"
                    onClick={() => setDisplayGrid(false)}
                    className={clsx({
                      [classes.selectedColor]: !displayGrid,
                      [classes.unselectedColor]: displayGrid
                    })}
                  >
                    <SetIcon
                      component={MaterialListIcon}
                    />
                  </IconButton>
                </Tooltip>
              </>
            }
            {(!!currentFolder &&
              (currentFolder.id === "search" || currentFolder.id === "shared_items" || inShared)) ?
              currentFolder.id === "search" ? (<></>) :
                (<></>) :
              (
                <div className={classes.breadcrumbsContainer}>

                  <ol className={classes.firstSlash}>
                    <li>{'/'}</li>
                  </ol>
                  <Breadcrumbs aria-label="breadcrumb">{renderBreadcrumbs()}</Breadcrumbs>
                </div>
              )}
            {
              (!!currentFolder && (currentFolder.id !== "search" && currentFolder.id !== "shared_items"
                && !inTrash && !inShared)) &&
              <div className={classes.actionsContainer}>
                <Tooltip title='Share Folder'>
                  <IconButton
                    edge="end"
                    aria-label="Share"
                    size="small"
                    onClick={clickShare}
                  >
                    <SvgIcon
                      component={ShareSVG}
                    />
                  </IconButton>
                </Tooltip>
              </div>
            }
          </div>
          }
          <Router>
            <Switch>
              <Route path="/:id">{displayGrid ?
                <FolderGrid onRequestPreview={requestPreview}/> :
                <Folder/>}</Route>
              <Route path="/">
                <Redirect to="/home"/>
              </Route>
            </Switch>
          </Router>
          {resultMessage.isVisible ? (
            <p className={classes.validationLabel}>{resultMessage.message}</p>
          ) : null}
        </>
      }
    </Container>
  );
};

Explorer.propTypes = {
  inTrash: PropTypes.bool,
  inShared: PropTypes.bool,
  clickShare: PropTypes.func,
  setLeftSideOpen: PropTypes.func,
  setDisabledMenuItems: PropTypes.func
};

export default Explorer;
