/**
 *
 * Reducer for Authentication
 *
 */
import { authConstants } from '../constants/auth-constants';
import { commonConstants } from '../constants/common-constants';
// The initial state for login
const loginInitialState = {
  user: null,
  status: authConstants.LOGOUT,
  token: "",
  isLoadingApp: true,
  loading: false,
};
export default function authentication(state = loginInitialState, action) {
  let newState;
  switch (action.type) {
    case authConstants.RESET_PASSWORD_REQUEST:
      newState = {
        ...state,
        status: authConstants.RESET_PASSWORD_REQUEST,
        loading: true,
      };
      return newState;
    case authConstants.LOGIN_REQUEST:
      newState = {
        ...state,
        status: authConstants.LOGIN_REQUEST,
        loading: true,
      };
      return newState;
    case authConstants.LOGIN_SUCCESS:
      const { user } = action.payload;
      newState = {
        ...state,
        user,
        status: authConstants.LOGIN_SUCCESS,
        loading: false,
        token: action.payload.token,
      };
      return newState;
    case authConstants.LOGIN_USER_UPDATE:
      const updatedUser = { ...state.user, ...action.payload };
      newState = {...state, user: updatedUser};
      return newState;
    case authConstants.LOGIN_FAILURE:
      newState = {
        ...state,
        status: authConstants.LOGIN_FAILURE,
        loading: false,
      };
      return newState;
    case authConstants.LOGOUT:
      newState = {...state, user: null, status: authConstants.LOGOUT, token: ''};
      return newState;
    case commonConstants.RESET:
      return loginInitialState;
    default:
      return state;
  }
}
