import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import {
  format,
  isSameDay,
  isSameMonth,
  startOfWeek,
  startOfMonth,
  endOfMonth,
  endOfWeek,
  subMonths,
  addMonths,
  addDays,
} from "date-fns";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  List,
  ListItem,
  Typography,
  IconButton, SvgIcon,
  Grid, Tooltip
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import PinIcon from "@material-ui/icons/TimerOutlined";
import CloseIcon from "@material-ui/icons/Close";

import { calendarActions } from "../../redux/actions/calendar-actions";
import styles from "./styles";
import ReminderForm from "./ReminderForm";
import {ReactComponent as PreviousSVG} from "../../assets/icons/icon_previous.svg";
import {ReactComponent as NextSVG} from "../../assets/icons/icon_next.svg";
import {ReactComponent as EditSVG} from "../../assets/icons/icon_edit.svg";
import {ReactComponent as DeleteSVG} from "../../assets/icons/icon_trash.svg";
import ConfirmationPopUp from "./ConfirmationPopUp";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import clsx from "clsx";

const daysOfWeek = [
  "SUN",
  "MON",
  "TUE",
  "WED",
  "THU",
  "FRI",
  "SAT",
];

const Calendar = (props) => {
  const dispatch = useDispatch();

  const today = new Date();
  let emptyReminder = {
    title: "",
    notes: "",
    startDate: new Date(),
    repeatInterval: "",
    fileRefs: [],
    emails: [],
  };
  const { isOpen, onClose, classes } = props;
  const reminders = useSelector((state) => state.calendar.reminders);

  const [open, setOpen] = useState(isOpen);
  const [isReminderFormOpen, setIsReminderFormOpen] = useState(false);
  const [currentMonth, setCurrentMonth] = useState(today);
  const [selectedDate, setSelectedDate] = useState(today);
  const [selectedEvent, setSelectedEvent] = useState(emptyReminder);
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const [selectedRemainder, setSelectedRemainder] = useState(null);

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen, open]);

  useEffect(() => {
    if (isOpen) {
      // sends the whole date 'currentMonth' to get the year as well
      dispatch(calendarActions.requestMonthEvents(currentMonth));
    }
  }, [isOpen, currentMonth, dispatch]);

  const onCloseReminderForm = () => {
    setIsReminderFormOpen(false);
    // clean up the selected event state to the current selected date
    emptyReminder.startDate = selectedDate;
    setSelectedEvent(emptyReminder);
  };

  const onDateClick = (day) => {
    // use selected date when add new reminder
    emptyReminder.startDate = day;
    setSelectedDate(day);
    setSelectedEvent(emptyReminder);
  };

  const nextMonth = () => {
    setCurrentMonth(addMonths(currentMonth, 1));
  };

  const prevMonth = () => {
    setCurrentMonth(subMonths(currentMonth, 1));
  };

  const handleReminderClick = (reminder) => {
    setSelectedEvent(reminder);
    setIsReminderFormOpen(true);
  };

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";

    return (
      <div className="header row flex-middle">
        <div className="col col-start">
          <div className="icon" onClick={prevMonth}>
            <SvgIcon
              className="share"
              component={PreviousSVG}
              viewBox={"0 0 24 24"}
            />
          </div>
        </div>
        <div className="col col-center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>
        <div className="col col-end" onClick={nextMonth}>
          <div className="icon">
            <SvgIcon
            className="share"
            component={NextSVG}
            viewBox={"0 0 24 24"}
          />
          </div>
        </div>
      </div>
    );
  };

  const renderDays = () => {
    const days = [];

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {daysOfWeek[i]}
        </div>
      );
    }

    return <div className="days row">{days}</div>;
  };

  const handleDeleteConfirmation = (answer = false) => {
    if (answer) {
      deleteCurrentItem();
      //onClose();
    }
    setIsConfirmationOpen(false);
  };

  const deleteCurrentItem = () => {
    if (!!selectedRemainder.id && selectedRemainder.id !== -1) {
      // delete reminder
      dispatch(calendarActions.deleteReminder(selectedRemainder.id));
    }
  };

  const renderCells = (cellProps) => {
    const monthStart = startOfMonth(currentMonth);
    const monthEnd = endOfMonth(monthStart);
    const startDate = startOfWeek(monthStart);
    const endDate = endOfWeek(monthEnd);

    const dateFormat = "d";
    const rows = [];

    let days = [];
    let day = startDate;
    let formattedDate = "";

    const currentReminders = !!cellProps.reminders ? cellProps.reminders : [];
    const getTodayEvents = (day) =>
      Array.isArray(currentReminders)
        ? currentReminders.filter(
            (ev) => !!ev.startDate && isSameDay(day, ev.startDate)
          )
        : [];

    while (day <= endDate) {
      for (let i = 0; i < 7; i++) {
        formattedDate = format(day, dateFormat);
        const cloneDay = day;
        const todayEvents = getTodayEvents(day);
        days.push(
          <div
            className={`col cell ${
              !isSameMonth(day, monthStart)
                ? "disabled"
                : isSameDay(day, selectedDate)
                ? "selected"
                : ""
            }`}
            onDoubleClick={(e) => setIsReminderFormOpen(true)}
            key={day}
            onClick={() => onDateClick(cloneDay)}
          >
            <span className={`number ${isSameDay(day, today) ? "today" : ""}`}>
              {formattedDate}
            </span>
            {todayEvents.map((reminder) => (
              <div
                key={reminder.id}
                className="event"
                onClick={(event) => {
                  event.stopPropagation();
                  handleReminderClick(reminder);
                }}
              >
                {reminder.title || ""}
              </div>
            ))}
          </div>
        );
        day = addDays(day, 1);
      }
      rows.push(
        <div className="row" key={day}>
          {days}
        </div>
      );
      days = [];
    }
    return <div className="body">{rows}</div>;
  };

  const renderReminders = (cellProps) => {
    const day = !!cellProps.selectedDate ? cellProps.selectedDate : new Date();
    const currentReminders = !!cellProps.reminders ? cellProps.reminders : [];
    const todayEvents = Array.isArray(currentReminders)
      ? currentReminders.filter(
          (ev) => !!ev.startDate && isSameDay(day, ev.startDate)
        )
      : [];

    return (
      <React.Fragment>
        <Typography className="header">{format(day, "MMMM dd, yyyy")}</Typography>
        {todayEvents.length > 0 ? (
          <List>
            {todayEvents.map((reminder) => (
              <ListItem
                key={reminder.id}
                className="list-item"
                onClick={(event) => {
                  //event.stopPropagation();
                  //handleReminderClick(reminder);
                }}
              >
                <Grid xs={12} className="list-container">
                <Grid xs={6} className="title">
                  {reminder.title || ""}
                </Grid>
                <Grid xs={6} className="actions">
                  <Tooltip title="Delete">
                    <IconButton
                      edge="end"
                      aria-label="Delete"
                      size="small"
                      onClick={(event) => {
                        setSelectedRemainder(reminder);
                        setIsConfirmationOpen(true);
                      }}
                    >
                      <SvgIcon
                        component={DeleteSVG}
                        viewBox={"0 0 50 50"}
                      />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Edit">
                    <IconButton
                      edge="end"
                      aria-label="Edit"
                      size="small"
                      onClick={(event) => {
                        event.stopPropagation();
                        handleReminderClick(reminder);
                      }}
                    >
                      <SvgIcon
                        component={EditSVG}
                      />
                    </IconButton>
                  </Tooltip>
                </Grid>
                </Grid>
              </ListItem>
            ))}
          </List>
        ) : (
          <Typography>No reminders today</Typography>
        )}
      </React.Fragment>
    );
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      className={clsx(classes.root, 'calendarDialog')}
      maxWidth="md"
    >
      {/*<DialogTitle id="form-dialog-title" disableTypography >
        <span className="dialog-title">Calendar</span>
        <IconButton onClick={onClose}>
            <CloseIcon />
        </IconButton>
      </DialogTitle>*/}
      <DialogContent>
        <div className="calendar">
          {renderHeader()}
          {renderDays()}
          {renderCells({ reminders })}
        </div>
        <div className="reminders">
          {renderReminders({ selectedDate, reminders })}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} className="button__cancel remove_left__margin" >
          Close
        </Button>
        <Button className="button__cancel calendar_right__margin"  onClick={() => setIsReminderFormOpen(true)}>
          Add Reminder
        </Button>
      </DialogActions>
      <ReminderForm
        isOpen={isReminderFormOpen}
        onClose={onCloseReminderForm}
        classes={classes}
        eventData={selectedEvent}
      />

      <ConfirmationPopUp
        isOpen={isConfirmationOpen}
        icon={<DeleteForeverIcon />}
        title="Delete confirmation"
        message={`Are you sure you want to delete this reminder: ${selectedRemainder && selectedRemainder.title}`}
        onClose={handleDeleteConfirmation}
      />
    </Dialog>
  );
};

Calendar.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

Calendar.defaultProps = {
  isOpen: false,
};

export default withStyles(styles)(Calendar);
