import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  InputBase,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Typography, Paper,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import ClearAllIcon from '@material-ui/icons/ClearTwoTone';
import {makeStyles, withStyles} from "@material-ui/core/styles";

import { fileActions } from "../redux/actions/file-actions";
import styles from "./dialogs/styles";

const useStyles = makeStyles((theme) => ({
  baseIcon: {
    color: '#000'
  }
}));

const SearchDialog = (props) => {
  const dispatch = useDispatch();
  const componentClasses = useStyles();
  const { onSelect, classes, showFolder } = props;
  const results = useSelector((state) => state.file.results);

  const [criteria, setCriteria] = useState("");

  const handleCriteria = (event) => {
    setCriteria(event.target.value);
  };

  const handleSearch = () => {
    if (!!criteria) {
      dispatch(fileActions.search(criteria, showFolder));
    }
  };

  const handleClear = () => {
    setCriteria("");
    props.onEmptySearch();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      handleSearch();
    }
  };

  const onSelectResult = (event, item) => {
    event.stopPropagation();
    onSelect(item);
  };

  return (
    <Box className={classes.root}>
      <Box className="searchBar">
        <Paper component="form" onSubmit={handleSubmit}>
        <InputBase
          className={classes.input}
          placeholder="Search My Aquila"
          inputProps={{ "aria-label": "search" }}
          value={criteria}
          onChange={handleCriteria}
          onKeyPress={handleKeyPress}
          endAdornment={
            <IconButton
              className={componentClasses.baseIcon}
              size='small' onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          }
        />
        </Paper>
        {/*<IconButton
          type="button"
          className={classes.iconButton}
          aria-label="search"
          onClick={handleSearch}
        >
          <SearchIcon />
        </IconButton>
        <IconButton
            type="button"
            className={classes.iconButton}
            aria-label="clear-search"
            onClick={handleClear}
          >
          <ClearAllIcon />
        </IconButton>*/}
      </Box>
      {props.displayResults ? (
        <>
          <Typography>
            {!!results.length ? (
              <span>
                Results for <span className="highlight__name">{criteria}</span>
                {` found ${results.length}`}:
              </span>
            ) : (
              "Results:"
            )}
          </Typography>
          <List className="searchResults" dense={true}>
            {results.map((item, index) => {
              return (
                <ListItem
                  button
                  key={`result-${item.id}-${index}`}
                  onClick={(event) => onSelectResult(event, item)}
                >
                  <ListItemText primary={item.name} />
                  <ListItemText secondary={item.path} />
                </ListItem>
              );
            })}
          </List>
        </>
      ) : null}
    </Box>
  );
};

SearchDialog.propTypes = {
  displayResults: PropTypes.bool,
  showFolder: PropTypes.bool,
  onSelect: PropTypes.func,
  onEmptySearch: PropTypes.func,
};

SearchDialog.defaultProps = {
  displayResults: false,
  showFolder: false,
  onSelect: () => {},
  onEmptySearch: () => {},
};

export default withStyles(styles)(SearchDialog);
