import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  SvgIcon, TextField, Chip
} from '@material-ui/core';
import NoteAddOutlinedIcon from '@material-ui/icons/NoteAddOutlined';
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from '@material-ui/core/styles';

import { fileActions } from '../../redux/actions/file-actions';
import styles from './styles';
import {ReactComponent as UploadSVG} from "../../assets/icons/icon_upload.svg";
import {ReactComponent as ProfileSVG} from "../../assets/icons/icon_profile.svg";

const NewFileDialog = (props) => {
  const dispatch = useDispatch();
  const { isOpen, onClose, classes } = props;
  const { currentFolder } = useSelector(state => state.folder);

  const [file, setFile] = useState(null);
  const [open, setOpen] = useState(isOpen);

  const handleNewFiles = (event) => {
    const file = event.target.files[0];
    setFile(file);
  };

  const handleUpload = () => {
    dispatch(fileActions.addFile(file));
    onClose();
  }

  useEffect(() => {
    if(isOpen !== open) {
      setFile(null);
      setOpen(isOpen);
    }
  }, [isOpen, open])

  const [chipData, setChipData] = React.useState([
    { key: 0, label: 'Angular' },
    { key: 1, label: 'jQuery' },
    { key: 2, label: 'Polymer' },
    { key: 3, label: 'React' },
    { key: 4, label: 'Vue.js' },
  ]);

  const inputFile = useRef(null);
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile.current.click();
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.root} >
      <DialogTitle id="form-dialog-title" disableTypography >
        <span className="dialog-title">
					Upload To <span className="highlight__name">{!!currentFolder && currentFolder.name}</span>
        </span>
      </DialogTitle>
      <DialogContent>
        <div className="title__container">
          <label className="title__textfield">SELECT A FILE</label>
        </div>
        <TextField
          autoFocus
          id="name"
          label="Browse your device"
          fullWidth
          value={!!file ? file.name : ''}
          variant="outlined"
          className="last__textfield single__line"
          InputLabelProps={{shrink: false}}
          InputProps={{
            endAdornment: (
              <IconButton
                edge="end"
                size="small"
                className="icon__normal"
                onClick={onButtonClick}
              >
              <SvgIcon
                className="share"
                component={ProfileSVG}
                viewBox={"0 0 48 49"}
              />
              </IconButton>
            ),
          }}
        />
        {/*<Button
          className="action-button"
          variant="contained"
          color="secondary"
          startIcon={<NoteAddOutlinedIcon />}
          component="label"
        >
          Choose File
          <input type="file"  style={{ display: 'none' }} multiple onChange={handleNewFiles} />
        </Button>*/}
        <input type="file" ref={inputFile} style={{ display: 'none' }}  multiple onChange={handleNewFiles} />
      </DialogContent>
      <DialogActions>
        <Button className="button__cancel" onClick={onClose}>
          Cancel
        </Button>
        <Button className="default__accept" onClick={handleUpload} disabled={!file}>
          Upload
          <SvgIcon
            className="share"
            component={UploadSVG}
            viewBox={"0 0 48 49"}
          />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

NewFileDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

NewFileDialog.defaultProps = {
  isOpen: false,
};

export default withStyles(styles)(NewFileDialog);
