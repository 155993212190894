import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import PropTypes from "prop-types";
import TextField from "@material-ui/core/TextField";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
import { withStyles } from "@material-ui/core/styles";
import Chip from '@material-ui/core/Chip';

import styles from "./styles";
import clsx from "clsx";
import {fileActions} from "../../redux/actions";

const ShareUsersSelector1 = (props) => {
  const dispatch = useDispatch();
  const { users, emails, onSelect, classes, isSelectUsers, placeholderLabel, selectedEmails } = props;
  const fixedOptions = [];
  const [value, setValue] = React.useState(selectedEmails || []);
  const [options, setOptions] = React.useState([]);
  const results = useSelector((state) => state.file.results);

  const onChangeValue = (event) => {
    if (!isSelectUsers) {
      dispatch(fileActions.search(event.target.value, false));
    }
  }

  useEffect(() => {
    if (!!users && isSelectUsers) {
      setOptions(users.map(x=>x.email));
    }
  }, [users]);

  useEffect(() => {
    if (!!results && !isSelectUsers) {
      setOptions(results);
    }
  }, [results]);

  useEffect(() => {
    if (!!value) {
      onSelect(value)
    }
  }, [value]);

  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(event, newValue) => {
        if (isSelectUsers) {
          setValue([
            ...newValue.filter(option => fixedOptions.indexOf(option) === -1)
          ]);
        } else {
          setValue([
            ...newValue.filter((v,i,a)=>a.findIndex(t=>(t.id === v.id))===i)
          ]);
        }
      }}
      className= {clsx({
        [classes.hasTags]: value.length > 0
      })}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      freeSolo
      options={[]}
      getOptionLabel={option => isSelectUsers ? option : option.name}
      renderTags={(tagValue, getTagProps) =>
        tagValue.map((option, index) => (
          <div key={'key-share'}>
            <Chip
              label={isSelectUsers ? option : option.name}
              {...getTagProps({ index })}
              disabled={fixedOptions.indexOf(option) !== -1}
            />
          </div>
        ))
      }
      style={{ width: 500 }}
      renderInput={params => (
        <TextField
          {...params}
          onChange={ev => {
              onChangeValue(ev);
            }
          }
          label={placeholderLabel}
          variant="outlined"
          placeholder="Favorites"
          InputLabelProps={{shrink: false}}
        />
      )}
    />
  );
};

ShareUsersSelector1.propTypes = {
  selectedEmails: PropTypes.array,
  users: PropTypes.array,
  emails: PropTypes.array,
  onSelect: PropTypes.func,
  placeholderLabel: PropTypes.string,
  isSelectUsers: PropTypes.bool,
};

ShareUsersSelector1.defaultProps = {
  selectedEmails: [],
  emails: [],
  users: [],
  placeholderLabel: 'Search People by email',
  isSelectUsers: true
};

export default withStyles(styles)(ShareUsersSelector1);
