/**
 * 
 * Reducer for Navigation
 * 
 */
import { navigationConstants } from '../constants/navigation-constants';
import { commonConstants } from '../constants/common-constants';
// The initial state for navigation
const navInitialState = {
  breadcrumbs: [],
  tree: [],
  loading: false
};
export default function navigation(state = navInitialState, action) {
  let newState;
  switch (action.type) {
    case navigationConstants.BREADCRUMB_ADD_REQUEST:
      newState = {...state, loading: true};
      return newState;
    case navigationConstants.BREADCRUMB_ADD:
      const { breadcrumbs } = action.payload;
      newState = {...state, breadcrumbs, loading: false};
      return newState;
    case navigationConstants.TREE_REQUEST:
      newState = {...state, loading: true};
      return newState;
    case navigationConstants.TREE_ADD:
      const { tree } = action.payload;
      newState = {...state, tree, loading: false};
      return newState;
    case commonConstants.RESET:
      return navInitialState;
    default:
      return state;
  }
}
