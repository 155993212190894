export const fileConstants = {
  FILE_DOWNLOAD_REQUEST: "FILE_DOWNLOAD_REQUEST",
  FILE_DOWNLOAD_SUCCESS: "FILE_DOWNLOAD_SUCCESS",
  FILE_DOWNLOAD_FAILURE: "FILE_DOWNLOAD_FAILURE",
  FILE_ADD: "FILE_ADD",
  FILE_ADD_FAILURE: "FILE_ADD_FAILURE",
  FILE_EDIT: "FILE_EDIT",
  FILE_EDIT_FAILURE: "FILE_EDIT_FAILURE",
  FILE_SHARE: "FILE_SHARE",
  FILE_SHARE_SUCCESS: "FILE_SHARE_SUCCESS",
  FILE_SHARE_FAILURE: "FILE_SHARE_FAILURE",
  FILE_UNSHARE: "FILE_UNSHARE",
  FILE_UNSHARE_SUCCESS: "FILE_UNSHARE_SUCCESS",
  FILE_UNSHARE_FAILURE: "FILE_UNSHARE_FAILURE",
  FILE_LINKING: "FILE_LINKING",
  FILE_LINKING_SUCCESS: "FILE_LINKING_SUCCESS",
  FILE_LINKING_FAILURE: "FILE_LINKING_FAILURE",
  FILE_SEARCH: "FILE_SEARCH",
  FILE_SEARCH_SUCCESS: "FILE_SEARCH_SUCCESS",
  FILE_SEARCH_FAILURE: "FILE_SEARCH_FAILURE",

  FILE_PREVIEW_REQUEST: "FILE_PREVIEW_REQUEST",
  FILE_PREVIEW_SUCCESS: "FILE_PREVIEW_SUCCESS",
  FILE_PREVIEW_FAILURE: "FILE_PREVIEW_FAILURE",
  FILE_RELATED_PREVIEW_REQUEST: "FILE_RELATED_PREVIEW_REQUEST",
  FILE_RELATED_PREVIEW_SUCCESS: "FILE_RELATED_PREVIEW_SUCCESS",
  FILE_RELATED_PREVIEW_FAILURE: "FILE_RELATED_PREVIEW_FAILURE",

  FILE_DELETE: "FILE_DELETE",
  FILE_DELETE_FAILURE: "FILE_EDIT_DELETE",
  FILE_MOVE_REQUEST: "FILE_MOVE_REQUEST",
  FILE_MOVE_SUCCESS: "FILE_MOVE_SUCCESS",
  FILE_MOVE_FAILURE: "FILE_MOVE_FAILURE",
};
