import React, {useEffect, useState} from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {withStyles} from "@material-ui/core/styles";
import styles from "./styles";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";

const PdfDialog = (props) => {
  const { isOpen, onClose, classes, previewUrl, previewRelatedUrl, fileName, relatedFiles, onSelectRelated } = props;
  const [open, setOpen] = useState(isOpen);

  const [numPages, setNumPages] = useState(null);

  const [relatedNumPages, setRelatedNumPages] = useState(null);

  const [selectedRelated, setSelectedRelated] = useState('');

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onRelatedDocumentLoadSuccess({ numPages }) {
    setRelatedNumPages(numPages);
  }

  const handleRelatedChange = (e) => {
    const file = relatedFiles.find(f => f.id === e.target.value);
    setSelectedRelated(e.target.value);
    onSelectRelated(file);
  }

  const renderRelatedFiles = () => {

    return (
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel id="related-files-label">Related files</InputLabel>
        <Select
          labelId="related-files-label"
          value={selectedRelated}
          onChange={handleRelatedChange}
          label="Age"
        >
          { relatedFiles.map(file => {
            return (
              <MenuItem value={file.id} key={`${file.id}-${file.folder_id}`} >
                {file.name}
              </MenuItem>
            );
          })

          }
        </Select>
      </FormControl>
    );
  }

  useEffect(() => {
    if(isOpen !== open) {
      setOpen(isOpen);
      setSelectedRelated('');
    }
  }, [isOpen, open])

  return (
  <Dialog open={isOpen} onClose={onClose} aria-labelledby="form-dialog-title" className={classes.root} fullScreen={true}>
    <DialogTitle id="form-dialog-title"disableTypography >
      <span className="dialog-title">Preview</span>
      <IconButton onClick={onClose}>
        <CloseIcon />
      </IconButton>
    </DialogTitle>
    <DialogContent>
      <Grid container item xs={12} spacing={2} className={classes.filePreview}>
        <Grid container item xs={!!relatedFiles && !!relatedFiles.length ? 6 : 12 }>
          <Grid item xs={12} className="file-title">
            {fileName}
          </Grid>
          <Grid item xs={12} className="file-viewer">
            <Document
              className={[classes.pdfRoot]}
              file={previewUrl}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              {numPages && Array.apply(null, Array(numPages))
                .map((x, i) => i+1)
                .map(page => <Page pageNumber={page} key={page}/>)}
            </Document>
          </Grid>
        </Grid>
        {!!relatedFiles && !!relatedFiles.length && (
          <Grid container item xs={!!relatedFiles && !!relatedFiles.length ? 6 : 12 }>
            <Grid item xs={12} className="related-selector">
              { renderRelatedFiles()}
            </Grid>
            <Grid item xs={12} className="file-viewer">
              <Document
                className={[classes.pdfRoot]}
                file={previewRelatedUrl}
                onLoadSuccess={onRelatedDocumentLoadSuccess}
              >
                {relatedNumPages && Array.apply(null, Array(relatedNumPages))
                  .map((x, i) => i+1)
                  .map(page => <Page pageNumber={page} key={page}/>)}
              </Document>
            </Grid>
          </Grid>
        )}
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={onClose}>
        Close
      </Button>
    </DialogActions>
  </Dialog>
  );
}

PdfDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  previewUrl: PropTypes.string,
  previewRelatedUrl: PropTypes.string,
  fileName: PropTypes.string,
  relatedFiles: PropTypes.array,
  onSelectRelated: PropTypes.func,
};

PdfDialog.defaultProps = {
  isOpen: false,
};

export default withStyles(styles)(PdfDialog);
