/**
 *
 * Index file for sagas files
 * Add new saga file here
 *
 */
import { all } from "redux-saga/effects";
import authSaga from "./auth-saga";
import navigationSaga from "./navigation-saga";
import folderSaga from "./folder-saga";
import fileSaga from "./file-saga";
import userSaga from "./user-saga";
import calendarSaga from "./calendar-saga";

function* sagaFiles() {
  yield all([
    authSaga(),
    navigationSaga(),
    folderSaga(),
    fileSaga(),
    userSaga(),
    calendarSaga(),
  ]);
}
export default sagaFiles;
