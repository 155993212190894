export const userConstants = {
  USERS_REQUEST: 'USERS_REQUEST',
  USERS_SUCCESS: 'USERS_SUCCESS',
  USERS_FAILURE: 'USERS_FAILURE',

  USER_CREATE_REQUEST: 'USERS_CREATE_REQUEST',
  USER_CREATE_SUCCESS: 'USER_CREATE_SUCCESS',
  USER_CREATE_FAILED: 'USER_CREATE_FAILED',

  USER_EDIT_REQUEST: 'USER_EDIT_REQUEST',
  USER_EDIT_SUCCESS: 'USER_EDIT_SUCCESS',
  USER_EDIT_FAILED: 'USER_EDIT_FAILED',

  USER_DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  USER_DELETE_SUCCESS: 'USER_DELETE_SUCCESS',
  USER_DELETE_FAILED: 'USER_DELETE_FAILED',
};
