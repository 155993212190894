import React, {useEffect, useState} from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import {createStyles, makeStyles, withStyles} from "@material-ui/core/styles";
import styles from "../../components/dialogs/styles";
import {
  Button,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Container, Tooltip, SvgIcon
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import {ReactComponent as BackSVG} from "../../assets/icons/back_icon.svg";

const useStyles = makeStyles((theme) =>
  createStyles({
    actionsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: 24,
      marginLeft: 10,
      marginRight: 14
    },
    actionBtn: {
      fontWeight: "bold",
      fontSize: "12px",
      letterSpacing: "0.06em",
      color: "#000000",
      border: '4px solid #C9C9C9',
      boxSizing: 'border-box',
      borderRadius: '4px',
      '&:hover': {
        background: 'none',
      },
      "&:first-child": {
        marginLeft: '-10px'
      }
    },
    fileMainContainer: {
    },
    formControl: {
      width: '100%',
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#000"
      },
      "& .MuiSelect-select:focus": {
        backgroundColor: 'transparent'
      },
      "& .MuiFormLabel-root.Mui-focused": {
        color: "#000"
      },
      "& .MuiOutlinedInput-root": {
        backgroundColor: "#FFF",
        borderRadius: 16,
        height: 32,
        "& .MuiSelect-outlined": {
          textAlign: 'left'
        },
      }
    }
  })
);

const FilePreview = (props) => {
  const { isOpen, onClose, classes, previewUrl, previewRelatedUrl, fileName, relatedFiles, onSelectRelated } = props;
  const [open, setOpen] = useState(isOpen);
  const [openCompare, setOpenCompare] = useState(false);

  const [numPages, setNumPages] = useState(null);

  const [relatedNumPages, setRelatedNumPages] = useState(null);

  const [selectedRelated, setSelectedRelated] = useState('');
  const componentClasses = useStyles();
  const customStyles = {
    root: {
      backgroundColor: "#FFF"
    }
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function onRelatedDocumentLoadSuccess({ numPages }) {
    setRelatedNumPages(numPages);
  }

  const clickCompare = () => {
    setOpenCompare(!openCompare);
  };

  const handleRelatedChange = (e) => {
    const file = relatedFiles.find(f => f.id === e.target.value);
    setSelectedRelated(e.target.value);
    onSelectRelated(file);
  }

  const renderRelatedFiles = () => {

    return (<>
        <label>COMPARE WITH</label>
        <FormControl variant="outlined" className={componentClasses.formControl}>
          <InputLabel id="related-files-label" shrink={false}></InputLabel>
          <Select
            labelId="related-files-label"
            value={selectedRelated}
            onChange={handleRelatedChange}
          >
            { relatedFiles.map(file => {
              return (
                <MenuItem value={file.id} key={`${file.id}-${file.folder_id}`} >
                  {file.name}
                </MenuItem>
              );
            })

            }
          </Select>
        </FormControl>
      </>
    );
  }

  useEffect(() => {
    if(isOpen !== open) {
      setOpen(isOpen);
      setSelectedRelated('');
    }
  }, [isOpen, open])

  return (
    <Container className={classes.fileMainContainer}>
      <Grid container item xs={12} spacing={2} className={classes.filePreview}>
        <Grid container item xs={openCompare ? 6 : 12 }>
          <Grid container item xs={12} className="file-title">
            <Tooltip title="Back">
              <IconButton
                edge="end"
                aria-label="Share"
                size="small"
                onClick={onClose}
                disableRipple={true}
              >
                <SvgIcon
                  component={BackSVG}
                />
              </IconButton>
            </Tooltip>
            <p>
              {fileName}
            </p>
          </Grid>
          <Grid item xs={12} className="file-viewer">
            <Document
              className={[classes.pdfRoot]}
              file={previewUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              onLoadError={console.error}
            >
              {numPages && Array.apply(null, Array(numPages))
                .map((x, i) => i+1)
                .map(page => <Page pageNumber={page} key={page}/>)}
            </Document>
          </Grid>
        </Grid>
        {openCompare && (
          <Grid container item xs={openCompare ? 6 : 12 }>
            <Grid item xs={12} className="related-selector">
              { renderRelatedFiles()}
            </Grid>
            <Grid item xs={12} className="file-viewer">
              <Document
                className={[classes.pdfRoot]}
                file={previewRelatedUrl}
                onLoadSuccess={onRelatedDocumentLoadSuccess}
              >
                {relatedNumPages && Array.apply(null, Array(relatedNumPages))
                  .map((x, i) => i+1)
                  .map(page => <Page pageNumber={page} key={page}/>)}
              </Document>
            </Grid>
          </Grid>
        )}
      </Grid>
      <div className={componentClasses.actionsContainer}>
        { !openCompare &&
          <>
          <Button className={componentClasses.actionBtn} onClick={onClose} disableRipple={true}>
            CLOSE
          </Button>
          {!!relatedFiles && !!relatedFiles.length &&
            <Button className={componentClasses.actionBtn} onClick={clickCompare} disableRipple={true}>
              COMPARE
            </Button>
          }
          </>
        }
        { openCompare &&
        <>
          <Button className={componentClasses.actionBtn} onClick={clickCompare} disableRipple={true}>
            GO BACK
          </Button>
        </>
        }
      </div>
    </Container>
  );
}

FilePreview.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  previewUrl: PropTypes.string,
  previewRelatedUrl: PropTypes.string,
  fileName: PropTypes.string,
  relatedFiles: PropTypes.array,
  onSelectRelated: PropTypes.func,
};

FilePreview.defaultProps = {
  isOpen: false,
};

export default withStyles(styles)(FilePreview);
